import Moment from 'moment';

// IMPORTANT: Check if subtract -1 hour has to be remove at winter-time. It works now for summertime.

export const dateFormatter = (date) => {
    let dateRes = Moment(date);
    return Moment(dateRes).format("DD/MM/YYYY - HH:mm");
}

export const dateNow = () => {
    let dateRes = Moment();
    return dateRes;
}

export const dateNowFormatted = () => {
    let dateRaw = Moment();
    const dateRes = dateRaw.format("YYYY-MM-DD HH:mm:00");
    return dateRes;
}

/* Gets the first day in the current month */
export const dateFirstDayInMonthFormatted = (date) => {
    let dateRaw = Moment(date);
    const dateRes = dateRaw.format("YYYY-MM-01 00:00:00");
    return dateRes;
}

export const dateRes = (date) => {
    let dateRes = Moment(date);
    return dateRes;
}

export const dateTimestamp = (date) => {
    let timestamp = Moment(date).unix();
    return timestamp;
}

/**
 *
 * @param {date} date
 * @returns date formatted in minutes, minutes/hours or days
 */
export const timeFormatter = (date) => {
    let dateRes = Moment(date)
    let todoTextStatus = "";
    const dateCalc = (new Date(dateRes) - new Date()) / (1000 * 3600 * 24);
    const lessThanADay = dateCalc < 1 && dateCalc > 0;
    const lessThanADayHasPassed = dateCalc > -1 && dateCalc < 0;
    const missedDeadline = new Date(dateRes) < new Date();
    if (missedDeadline) {
        if (lessThanADayHasPassed) {
            const dateDifference = new Date(dateRes) - new Date();
            const hourDifference = (Math.abs(Math.floor((dateDifference % 86400000) / 3600000)) - 1); // hours
            const minuteDifference = Math.abs(Math.round(((dateDifference % 86400000) % 3600000) / 60000)); // minutes
            if (hourDifference < 1) {
                let minuteText = "minutter";
                if (minuteDifference === 1) minuteText = "minut";
                return todoTextStatus = `${minuteDifference} ${minuteText} siden`;
            } else {
                let hourText = "timer";
                let minuteText = "minutter";
                if (hourDifference === 1) hourText = "time";
                if (minuteDifference === 1) minuteText = "minut";
                return todoTextStatus = `${hourDifference} ${hourText} og ${minuteDifference} ${minuteText} siden`;
            }
        } else {
            const dateDifInDays = Math.abs((new Date(dateRes) - new Date()) / (1000 * 3600 * 24)).toString().split('.')[0];
            if (dateDifInDays === "1") {
                return todoTextStatus = dateDifInDays + ' dag siden';
            } else {
                return todoTextStatus = dateDifInDays + ' dage siden';
            }
        }
    } else {
        if (lessThanADay) {
            const dateDifference = new Date(dateRes) - new Date();
            const hourDifference = Math.floor((dateDifference % 86400000) / 3600000); // hours
            const minuteDifference = Math.round(((dateDifference % 86400000) % 3600000) / 60000); // minutes
            if (hourDifference < 1.0) {
                let minuteText = "minutter";
                if (minuteDifference === 1) minuteText = "minut";
                return todoTextStatus = `${minuteDifference} ${minuteText}`;
            } else {
                let hourText = "timer";
                let minuteText = "minutter";
                if (hourDifference === 1) hourText = "time";
                if (minuteDifference === 1) hourText = "minut";
                return todoTextStatus = `${hourDifference} ${hourText} og ${minuteDifference} ${minuteText}`;
            }
        } else {
            const dateDifInDays = ((new Date(dateRes) - new Date()) / (1000 * 3600 * 24)).toString().split('.')[0];
            if (dateDifInDays === "1") {
                return todoTextStatus = dateDifInDays + ' dag';
            } else {
                return todoTextStatus = dateDifInDays + ' dage';
            }
        }
    }
}
