import { createSlice } from "@reduxjs/toolkit";

// Types
import { IUser } from "../../types";

// Define a TypeScript type for your state
interface UserState {
  user: IUser;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
  } as UserState,
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getUser } = userSlice.actions;

export default userSlice.reducer;
