import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Services

// Components - Material UI
import Fade from '@mui/material/Fade';

// Components
import DashboardTodoList from '../../components/dashboard/dashboardTodoList';
import DashboardAddFriend from '../../components/dashboard/dashboardAddFriend';
import DashboardFeedback from '../../components/dashboard/dashboardFeedback';
import DashboardDate from '../../components/dashboard/dashboardDate';
import DashboardNotelist from '../../components/dashboard/DashboardNotelist';
import Button from '../../components/button/button';
import AddTodoModal from '../../components/todo/modal/todoAdd/TodoAdd';

// Icons
import AddIcon from '@mui/icons-material/Add';

// Style
import './Dashboard.scss';

const Dashboard = (props) => {
  /* Store */
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  const todoitemsStore = useSelector((state) => state.todoitems);
  const todaysTodos = todoitemsStore.todayslist;
  /* props */
  const { stateHandlerSetAllTodos } = props;
  /* Initialize states */
  const [modalAddTodo, setModalAddTodo] = useState(false);

  /* Returns a list with todoitems user has accepted only */
  let todoItems = [];
  todaysTodos.forEach((item) => {
    const acceptedCheck = item.users.find(userObject => userObject.isAccepted === "accepted" && userObject.userId === user.id); // check if user has accepted the todo
    if (acceptedCheck) {
      todoItems.push(item);
    }
  });

  return (
    <Fade in timeout={1000}>
      <div className="dashboard-container">
        <div className="row padding-m--b color--text">
          <div className="flex flex-justify--center flex-align--center full-width">
            <div className="dashboard-todo-title flex flex-align--center flex--column padding-m">
              <h1 className="color--text no-margin font-30 margin-s--b">Dagens overblik</h1>
              <div>
                <Button className="structio" icon={<AddIcon className="todo-svg" />} text="Opret opgave" onClick={() => setModalAddTodo(modalAddTodo ? false : true)} />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-items">
          <DashboardDate />
          <DashboardAddFriend />
          <DashboardFeedback />
          <DashboardTodoList />
          <DashboardNotelist />
        </div>
        {modalAddTodo && <AddTodoModal
          TODO_TYPE={"todo"}
          state={modalAddTodo}
          setState={() => setModalAddTodo(modalAddTodo ? false : true)}
          stateHandlerSetAllTodos={stateHandlerSetAllTodos}
          selectedDate={new Date().setHours(0, 0, 0)}
        />}
      </div>
    </Fade>
  );
}

export default Dashboard;
