import React, { useState } from 'react';
import { useSelector } from 'react-redux'

// Components - Material UI
import Fade from '@mui/material/Fade';

// Components
import Button from '../../../../components/button/button';

import Friendlist from './content/Friendlist.tsx';
import PendingList from './content/Pendinglist';
import RequestList from './content/Requestlist';

// Icons

const UserFriendPanel = (props) => {
  /* Store */
  const friendlistStore = useSelector((state) => state.friendlist);
  const pendinglist = friendlistStore.pendinglist;
  const requestlist = friendlistStore.requestlist;

  /* data props */
  const { onClickContentShow } = props;
  /* Initialize states */
  const [selectMenu, setSelectMenu] = useState([true, false, false]);

  const badgePending = pendinglist.length;
  const badgeRequest = requestlist.length;

  const selectMenuItem = (arrayIndex) => {
    const copyArray = selectMenu.slice();
    selectMenu.forEach((menu, index) => {
      if (index === arrayIndex) {
        copyArray[index] = true;
      } else {
        copyArray[index] = false;
      }
    })
    setSelectMenu(copyArray);
  }

  let content;
  if (selectMenu[0] === true) {
    content = <Friendlist />
  }
  if (selectMenu[1] === true) {
    content = <PendingList />
  }
  if (selectMenu[2] === true) {
    content = <RequestList />
  }

  return (
    <Fade in>
      <div className="fade user-content">
        <div className="row user-content-box padding-xl-rl">
          <div className="flex flex--column full-width align-items-center">
            <Button
              className="icon-btn margin-m--b"
              onClick={() => onClickContentShow("read")}
              text={'Tilbage til profil'}
            />
            <Button
              className={`margin-m--b ${selectMenu[0] ? "button-active" : ""}`}
              selected={selectMenu[0]}
              text="Kontaktliste"
              onClick={() => selectMenuItem(0)}
            />
            <Button
              className={`margin-m--b ${selectMenu[1] ? "button-active" : ""}`}
              badge={badgePending}
              badgeColor="red"
              selected={selectMenu[1]}
              text="Anmodninger"
              onClick={() => selectMenuItem(1)}
            />
            <Button
              className={`margin-m--b ${selectMenu[2] ? "button-active" : ""}`}
              badge={badgeRequest}
              badgeColor="green"
              selected={selectMenu[2]}
              text="Sendte anmodninger"
              onClick={() => selectMenuItem(2)}
            />
          </div>
          {content}
        </div>
      </div>
    </Fade >
  );
}

export default UserFriendPanel;
