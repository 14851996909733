import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Components

// Icons
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// Style
import './Timepicker.scss';

class Timepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
        };
    }

    onChangeHourUp = (index, date, add) => () => {
        if(add === true) {
            date.setHours(new Date(date).getHours() + 1);
            this.props.onChangeDateAdd(date);
        } else {
            date.setHours(new Date(date).getHours() + 1);
            const copyArrayObject = cloneDeep(this.props.specificTodoItem); //copy the object
            copyArrayObject.date = new Date(date);
            var updatedTodoObject = copyArrayObject;
            this.props.stateHandlerUpdateSpecificTodoItem(updatedTodoObject);
        }
    }

    onChangeMinuteUp = (index, date, add) => () => {
        if(add === true) {
            date.setMinutes(new Date(date).getMinutes() + 5);
            this.props.onChangeDateAdd(date);
        } else {
            date.setMinutes(new Date(date).getMinutes() + 5);
            const copyArrayObject = cloneDeep(this.props.specificTodoItem); //copy the object
            copyArrayObject.date = new Date(date);
            var updatedTodoObject = copyArrayObject;
            this.props.stateHandlerUpdateSpecificTodoItem(updatedTodoObject);
        }
    }

    onChangeHourDown = (index, date, add) => () => {
        if(add === true) {
            date.setHours(new Date(date).getHours() - 1);
            this.props.onChangeDateAdd(date);
        } else {
            date.setHours(new Date(date).getHours() - 1);
            const copyArrayObject = cloneDeep(this.props.specificTodoItem); //copy the object
            copyArrayObject.date = new Date(date);
            var updatedTodoObject = copyArrayObject;
            this.props.stateHandlerUpdateSpecificTodoItem(updatedTodoObject);
        }
    }

    onChangeMinuteDown = (index, date, add) => () => {
        if(add === true) {
            date.setMinutes(new Date(date).getMinutes() - 5);
            this.props.onChangeDateAdd(date);
        } else {
            date.setMinutes(new Date(date).getMinutes() - 5);
            const copyArrayObject = cloneDeep(this.props.specificTodoItem); //copy the object
            copyArrayObject.date = new Date(date);
            var updatedTodoObject = copyArrayObject;
            this.props.stateHandlerUpdateSpecificTodoItem(updatedTodoObject);
        }
    }

    render() {
        const { className, startTimeHours, startTimeMinutes, date, index, add } = this.props;
        return (
            <div className={`${className} timepicker-content-box flex flex--column`}>
                <div className="content-container-row time-picker">
                    <div className="selected-time-arrows">
                        <button className="icon-btn time-hour-btn time-seperator" onClick={this.onChangeHourUp(index, date, add)}><ExpandLess /></button>
                    </div>
                    <div className="selected-time-arrows">
                        <button className="icon-btn time-minute-btn time-seperator" onClick={this.onChangeMinuteUp(index, date, add)}><ExpandLess /></button>
                    </div>
                </div>
                <div className="content-container-row time-picker">
                    <div className="selected-time-hour">
                        <span className="startTime">{startTimeHours}</span>
                    </div>
                    <div className="selected-time-seperator">
                        <span className="time-seperator">:</span>
                    </div>
                    <div className="selected-time-minute">
                        <span className="endTime">{startTimeMinutes}</span>
                    </div>
                </div>
                <div className="content-container-row time-picker">
                    <div className="selected-time-arrows">
                        <button className="icon-btn time-hour-btn time-seperator" onClick={this.onChangeHourDown(index, date, add)}><ExpandMore /></button>
                    </div>
                    <div className="selected-time-arrows">
                        <button className="icon-btn time-minute-btn time-seperator" onClick={this.onChangeMinuteDown(index, date, add)}><ExpandMore /></button>
                    </div>
                </div>
            </div>
        );
    }
}
export default Timepicker;
