import React from 'react';

// Components

// Icons

// Style
import './searchField.scss';

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const SearchField = (props) => {
    /* data */
    const { className, type, placeholder, value } = props
    /* functions */
    const { onChange } = props

    return (
        <input className={`search-input ${className}`} type={type} placeholder={placeholder} value={value} onChange={onChange} />
    )
}

export default SearchField;
