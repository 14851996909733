import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// Services
import AuthService from '../../../../services/Auth';

// Store
import { setIsLoading } from '../../../../store/features/spinner';
import { setNotes } from '../../../../store/features/notes';

// Components
import { NotificationManager } from 'react-notifications';
import Textarea from "../../../textarea";
import InputField from '../../../inputField/';
import Button from '../../../button/button';
import Switch from '../../../button/switchButton/';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import DashboardIcon from '@mui/icons-material/Dashboard';

// Style
import './ModalEdit.scss';

const ModalEdit = (props) => {
    /* Store */
    const dispatch = useDispatch();
    const noteStore = useSelector((state) => state.notes);
    const noteList = noteStore.notes;
    /* props */
    const { noteObject, state, toggleModal } = props

    /* Initialize states */
    const descriptionLimit = 1000;
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [showOnDashboard, setShowOnDashboard] = useState(false);

    useEffect(() => {
        setTitle(noteObject.title)
        setDescription(noteObject.description);
        setDescriptionLength(noteObject.description ? noteObject.description.length : 0);
        setShowOnDashboard(noteObject.showOnDashboard);
    }, []);

    const setTitleInputValue = (value) => {
        setTitle(value);
        document.getElementById("title-input-error").classList.add("hidden");
    }

    const setDescriptionValue = async (value) => {
        setDescription(value);
        setDescriptionLength(value.length);
    }

    const onClickEditNote = () => {
        dispatch(setIsLoading(true));
        const data = {
            'id': noteObject.id,
            'title': title,
            'description': description,
            'showOnDashboard': showOnDashboard,
            'subitems': noteObject.subitems,
        };
        if (title.length === 0) {
            document.getElementById("note-title").scrollIntoView({ behavior: 'smooth' });
            document.getElementById("title-input-error").classList.remove("hidden");
            dispatch(setIsLoading(false));
        } else {
            axios.put(`${process.env.REACT_APP_API_URL}/note/edit`, data, AuthService.config())
                .then((response) => {
                    const res = response.data;
                    if (res) {
                        const updatedNote = data;
                        /* Find the note that have been edited and swap it with the new updated object in the noteList
                         * Then update the Store with the new noteList-object
                         */
                        let cloneNotes = [];
                        noteList.forEach(note => {
                            if (note.id === updatedNote.id) {
                                note = updatedNote
                            }
                            cloneNotes.push(note);
                        })
                        dispatch(setNotes(cloneNotes));
                        toggleModal();
                        NotificationManager.success('Ændringer er gemt');
                        dispatch(setIsLoading(false));
                    } else {
                        NotificationManager.warning('Kunne ikke opdatere noten på nuværende tidspunkt.', '');
                        dispatch(setIsLoading(false));
                    }
                })
                .catch((error) => {
                    NotificationManager.warning('Kunne ikke opdatere noten', 'Fejl!');
                    console.error(error);
                    dispatch(setIsLoading(false));
                });
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={'structio-modal-container'}
            open={state}
            onClose={toggleModal}
            closeAfterTransition
        >
            <Fade in={state}>
                <div className="structio-modal-box">
                    <button className="icon-btn close-modal-icon" type="button" onClick={toggleModal}><ClearIcon /></button>
                    <h1 className="modal-title no-margin">Rediger note</h1>
                    <div className="flex flex--column margin-m--t">
                        <span id="note-title" className="title-text font-14">Note titel</span>
                        <InputField className="font-16 line" type="text" placeholder="Indtast notens titel" maxLength="50" value={title} onChange={(value) => setTitleInputValue(value.target.value)} />
                        <span id="title-input-error" className="hidden font-12 color--warning">&nbsp;(Notens titel må ikke være tom)</span>
                        <span className="font-13 color--graa4">Tegn: {title.length} / 50</span>
                    </div>
                    <div className="flex flex--column margin-l--t padding-m--b line">
                        <span className="title-text font-14">Note beskrivelse</span>
                        <Textarea
                            value={description}
                            onChange={(event) => setDescriptionValue(event.target.value)}
                            maxLength={descriptionLimit}
                            placeholder="Note beskrivelse"
                        />
                        <div>
                            <span className="font-13 color--graa4">Tegn: {descriptionLength} / {descriptionLimit}</span>
                        </div>
                    </div>
                    <div className="flex flex--column flex-justify--center line standard-structio-switch">
                        <div className="show-task-ondashboard-container">
                            <span className="flex color--text font-14"><DashboardIcon className="margin-s--r color--structio" />Vis note på Din dag</span>
                            <div style={{ marginRight: "-15px" }}>
                                <Switch
                                    checked={showOnDashboard}
                                    onChange={() => setShowOnDashboard(!showOnDashboard)}
                                    value={showOnDashboard}
                                    size="switch-large"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex--column flex-justify--center flex-align--center margin-m--t">
                        <Button
                            text={'Gem ændringer'}
                            type="add"
                            onClick={() => onClickEditNote()}
                        />
                        <Button
                            className="margin-m--t"
                            type="cancel"
                            text={'Annuller'}
                            onClick={() => toggleModal()}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalEdit;