import React from 'react';

// Services

// Components - Material UI

// Components

// Icons

// Style
import './Textarea.scss';

const Textarea = (props) => {
  const { className, placeholder, value, maxLength, onChange } = props; // props
  return (
    <textarea className={`textarea-structio ${className}`} placeholder={placeholder} value={value ? value : ''} onChange={onChange} maxLength={maxLength} />
  );
};

export default Textarea;
