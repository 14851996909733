import React, { useState } from "react";

// Components
import RingLoaderfrom from "react-spinners/RingLoader";

// Style
import "./PullToRefresh.scss";

const PullToRefresh = (props) => {
  const { onRefresh, children } = props;

  const [refreshing, setRefreshing] = useState(false);
  const [startY, setStartY] = useState(0);
  const [dragPixels, setDragPixels] = useState(0);
  const [touchActive, setTouchActive] = useState(false);
  const [refreshTriggered, setRefreshTriggered] = useState(false);

  const DRAG_TO_REFRESH_PIXEL = 150;
  const TRIGGER_TO_REFRESH_PIXEL = 75;
  const isAtTopOfPage = document.body.scrollTop < 0;

  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
    setRefreshTriggered(false);
    setTouchActive(true); // Set touch as active when touch starts
  };

  const handleTouchMove = (event) => {
    const pullDownDistance = event.touches[0].clientY - startY;

    // Only set refreshTriggered if the touch is active and the pull-down distance exceeds DRAG_TO_REFRESH_PIXEL
    if (
      touchActive &&
      pullDownDistance >= DRAG_TO_REFRESH_PIXEL &&
      isAtTopOfPage
    ) {
      setRefreshTriggered(true);
      setDragPixels(pullDownDistance);
      // Vibrate mobile to signalize it's ready to fetch data
      if (navigator.vibrate) navigator.vibrate(200);
    } else {
      // Reset refreshTriggered if pull-down distance is less than DRAG_TO_REFRESH_PIXEL
      setRefreshTriggered(false);
      setDragPixels(pullDownDistance);
    }
  };

  const handleTouchEnd = () => {
    if (refreshTriggered) {
      handleRefresh();
    } else {
      setTouchActive(false);
      setDragPixels(0);
    }
  };

  const handleRefresh = () => {
    if (!refreshing) {
      setRefreshing(true);
      try {
        setTimeout(async () => {
          await onRefresh();
          resetAll();
        }, 2000); // Adjust the delay as needed
      } catch (error) {
        console.error("error pull-to-refresh fetch", error);
      }
    }
  };

  const resetAll = () => {
    setRefreshing(false);
    setRefreshTriggered(false);
    setStartY(0);
    setDragPixels(0);
  };

  return (
    <div
      className="pull-to-refresh-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {refreshing || refreshTriggered ? (
        <div
          className={`
            pull-to-refresh-spinner-container ${refreshing ? "active" : ""}
          `}
          role="status"
        >
          <RingLoaderfrom size={25} color={"#67b7bf"} loading={true} />
        </div>
      ) : (
        <div className="pull-to-refresh-spinner-container" role="status">
          {dragPixels > TRIGGER_TO_REFRESH_PIXEL && isAtTopOfPage && (
            <RingLoaderfrom
              size={25}
              color={"#67b7bf"}
              loading={true}
              speedMultiplier={0}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default PullToRefresh;
