import React from 'react';

// Components - Material UI

// Components
import IconButton from '../../../components//button/iconButton';

// Icons

// Style
import './ButtonField.scss';

/** @ViableTypes for props
 ** id - Adds a id to the input
 ** className - String: Custom classNames
 ** type - type of inputfield
 ** placeholder - inputfields placeholder
 ** value - value assorciated with inputfield
 ** maxLength - string: number of chars in inputfield
 ** icon - provides a icon to the button
 ** onChange - Func: changes value of inputfield
 ** onKeyPress - Func: Gets executed everytime a key is pressed - i.e check enter press.
 ** onClick - Func: Provide a function to the onClick button
 ** onBlur - Func: execute a function on Blur - i.e hide element on blur
 ** autoFocus - TRUE = autofocus element
 */

const ButtonField = (props) => {
    /* data */
    const { id, className, type, placeholder, value, maxLength, icon, autoFocus } = props
    /* functions */
    const { onChange, onClick, onKeyPress } = props

    return (
        <div className="flex full-width">
            <div className="flex flex-item--grow flex--column">
                <input id={id} className={`button-input ${className}`} type={type} placeholder={placeholder} value={value ? value : ''} onChange={onChange} maxLength={maxLength} onKeyPress={onKeyPress} autoFocus={autoFocus} autoComplete="off" />
            </div>
            <div className="flex button-input-btn padding-xs">
                <IconButton
                    className="flex-justify--start color--white"
                    icon={icon}
                    onClick={onClick}
                />
            </div>
        </div>
    )
}

export default ButtonField;
