import React, { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

// Types
import type { RootState } from "../../../../../store/store.ts";
import type { IFriend } from "../../../../../types";

// Services
import { debounce } from "../../../../../services/FunctionHelper.tsx";
import AuthService from "../../../../../services/Auth.jsx";

// Notifications
import { NotificationManager } from "react-notifications";

// Components - Material UI
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

// Components
import UserItem from "../../../../../components/listItem/userItem/index.js";
import Button from "../../../../../components/button/button/index.js";
import InputField from "../../../../../components/inputField/index.js";
import IconButton from "../../../../../components/button/iconButton/index.js";
import SearchField from "../../../../../components/inputField/searchField/index.js";

// Icons
import { Add, DeleteForever, Clear } from "@mui/icons-material";

// Store
import { setFriendlist } from "../../../../../store/features/friendlist.js";

const Friendlist = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const userStore = useSelector((state: RootState) => state.user);
  const user = userStore.user;
  const friendlistStore = useSelector((state: RootState) => state.friendlist);
  const friendlist = friendlistStore.friendlist;
  /* data */
  const {} = props;
  /* Initialize states */
  const [searchInput, setSearchInput] = useState("");
  const [userTag, setUserTag] = useState("");
  const [deleteFriendObject, setDeleteFriendObject] = useState<IFriend>();
  const [friendListLabel, setFriendListLabel] = useState(
    "Din kontaktliste er tom"
  );
  const [sortedfriendlistIds, setSortedfriendlistIds] = useState<number[]>([]);
  const [modalAddFriend, setModalAddFriend] = useState(false);
  const [modalDeleteFriend, setModalDeleteFriend] = useState(false);

  const onChangeSearchFriend = (event) => {
    setSearchInput(event.target.value);
    onChangeFilterFriendlist(event.target.value);
  };

  const onChangeFilterFriendlist = debounce((searchInput) => {
    let sortedFriendlistResIds: number[] = [];
    // Search on the users full name if theres a match it adds the user to the sortedFriendlistRes
    if (searchInput.length > 2) {
      friendlist.forEach((friend: IFriend) => {
        const fullname = `${friend.firstname.toLowerCase()} ${friend.lastname.toLowerCase()}`;
        const checkName = fullname.includes(searchInput.toLowerCase());
        if (checkName) {
          sortedFriendlistResIds.push(friend.id);
        }
      });
      setSortedfriendlistIds(sortedFriendlistResIds);
      setFriendListLabel(`Ingen resultater for: ${searchInput}`);
    } else if (searchInput.length === 0) {
      setSortedfriendlistIds([]);
      setFriendListLabel(`Din kontaktliste er tom`);
    }
  }, 600);

  const showAddFriendModal = () => {
    setModalAddFriend(!modalAddFriend);
    setUserTag("");
  };

  const showDeleteFriendModal = (friendObject) => {
    setDeleteFriendObject(friendObject);
    setModalDeleteFriend(!modalDeleteFriend);
  };

  const onClickAddFriend = () => {
    const splitUserTag = userTag.split("#");
    const firstname = splitUserTag[0];
    const friendId = parseInt(splitUserTag[1]);

    const data = {
      firstname: firstname,
      friendId: friendId,
      fullname: `${user.firstname} ${user.lastname}`,
    };

    if (friendId && friendId !== user.id) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/addfriend`,
          data,
          AuthService.config()
        )
        .then((response) => {
          if (response.data === false) {
            NotificationManager.warning("Ugyldigt profil-ID");
          } else if (response.data === "friendAddedByMe") {
            NotificationManager.warning(
              `Du har allerede tilføjet ${userTag} til din kontaktliste`
            );
          } else if (response.data === "friendAddedMe") {
            NotificationManager.warning(
              `${userTag} har allerede tilføjet dig som kontakt`
            );
          } else {
            NotificationManager.success(
              `${userTag} har modtaget din anmodning`
            );
            showAddFriendModal();
            dispatch(setFriendlist(response.data));
          }
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.warning(
            "Kan ikke slette denne kontakt på nuværende tidspunkt.",
            "Fejl!"
          );
        });
    } else {
      NotificationManager.warning("Ugyldigt profil-ID.");
    }
  };

  const onClickRemoveFriend = (friendId: number | undefined) => {
    const data = {
      friendId: friendId,
    };

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/user/removefriend`,
        AuthService.configWithData(data)
      )
      .then((response) => {
        dispatch(setFriendlist(response.data));
        setSortedfriendlistIds([]);
        setModalDeleteFriend(!modalDeleteFriend);
      })
      .catch((error) => {
        console.error(error);
        NotificationManager.warning(
          "Kan ikke slette denne kontakt på nuværende tidspunkt.",
          "Fejl!"
        );
      });
  };

  let friendlistRes: JSX.Element[] = [];
  if (friendlist) {
    friendlist.forEach((friend: IFriend) => {
      if (friend.isAccepted === 1) {
        if (sortedfriendlistIds.length > 0) {
          if (sortedfriendlistIds.includes(friend.id)) {
            friendlistRes.push(
              <div
                key={`connectedUser ${friend.id}`}
                className="flex flex-justify--between"
              >
                <UserItem
                  key={`friendlist-user-${friend.id}`}
                  name={`${friend.firstname} ${friend.lastname}`}
                  picture={friend.pictureUrl}
                />
                <div className="flex align-center-items padding-s--b">
                  <IconButton
                    className="color--delete-hover"
                    icon={<DeleteForever />}
                    onClick={() => showDeleteFriendModal(friend)}
                  />
                </div>
              </div>
            );
          }
        } else {
          friendlistRes.push(
            <div
              key={`connectedUser ${friend.id}`}
              className="flex flex-justify--between"
            >
              <UserItem
                key={`friendlist-user-${friend.id}`}
                name={`${friend.firstname} ${friend.lastname}`}
                picture={friend.pictureUrl}
              />
              <div className="flex align-center-items padding-s--b">
                <IconButton
                  className="color--delete-hover"
                  icon={<DeleteForever />}
                  onClick={() => showDeleteFriendModal(friend)}
                />
              </div>
            </div>
          );
        }
      }
    });
  }
  return (
    <div className="full-width">
      <div className="flex justify-space-between padding-m--t color--text line">
        <div>
          <div className="dashboard-date-info">Profil-ID</div>
          <div>
            {user.firstname}#{user.id}
          </div>
        </div>
        <div
          className="flex flex-align--center pointer"
          onClick={() => showAddFriendModal()}
        >
          <span className="font-14 margin-s--r">Tilføj kontakt</span>
          <Button
            type="circle"
            width={30}
            height={30}
            className="add"
            icon={<Add />}
          />
        </div>
      </div>
      <div className="row flex justify-space-between profile-title padding-m--t line">
        <span>Søg i kontaktliste</span>
        <SearchField
          placeholder="Indtast navn"
          onChange={onChangeSearchFriend}
          value={searchInput}
        />
      </div>
      <div className="row flex justify-space-between profile-title padding-m--t">
        <span>Kontaktliste</span>
      </div>
      <div className="row align-items-center line">
        <div className="col profile-text padding-m--t">
          {friendlist && friendlistRes.length > 0 ? (
            friendlistRes
          ) : (
            <span>{friendListLabel}</span>
          )}
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"structio-modal-container"}
        open={modalDeleteFriend}
        onClose={() => setModalDeleteFriend(!modalDeleteFriend)}
        closeAfterTransition
      >
        <Fade in={modalDeleteFriend}>
          <div className="structio-modal-box">
            <button
              className="icon-btn close-modal-icon"
              type="button"
              onClick={() => setModalDeleteFriend(!modalDeleteFriend)}
            >
              <Clear />
            </button>
            <h1 className="modal-title no-margin">Slet kontakt</h1>
            <div className="row align-items-center line padding-m--t margin-m--b">
              <div className="row align-items-center">
                <div className="col profile-title">
                  <span>Er du sikker på at du vil slette:</span>
                </div>
                <div className="col">
                  <h2 className="no-margin color--text">
                    {deleteFriendObject
                      ? `${deleteFriendObject.firstname} ${deleteFriendObject.lastname}`
                      : ""}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col flex align-items-center padding-s">
                <Button
                  className="icon-btn"
                  onClick={() => setModalDeleteFriend(!modalDeleteFriend)}
                  text={"Annuller"}
                />
              </div>
              <div className="col flex align-items-center padding-s">
                <Button
                  className="icon-btn delete"
                  onClick={() => onClickRemoveFriend(deleteFriendObject?.id)}
                  text={"Slet"}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"structio-modal-container"}
        open={modalAddFriend}
        onClose={showAddFriendModal}
        closeAfterTransition
      >
        <Fade in={modalAddFriend}>
          <div className="structio-modal-box">
            <button
              className="icon-btn close-modal-icon"
              type="button"
              onClick={showAddFriendModal}
            >
              <Clear />
            </button>
            <h1 className="modal-title no-margin">Tilføj ny kontakt</h1>
            <div className="row align-items-center line padding-m--t margin-m--b">
              <div className="row align-items-center">
                <div className="col font-14 margin-m--b">
                  <div className="header-title">
                    Dit profil-ID er:
                    <span className="color--text margin-xs--l">
                      {user.firstname}#{user.id}
                    </span>
                  </div>
                  <div>
                    <div className="header-title">
                      Kontakter kan invitere hinanden til opgaver.
                    </div>
                  </div>
                </div>
                <div className="col profile-title">
                  <span className="color--text">
                    Indtast personens Profil-ID
                  </span>
                </div>
                <div className="col">
                  <InputField
                    className="color--structio"
                    type="text"
                    placeholder="Navn#ID"
                    value={userTag}
                    onChange={(event) => setUserTag(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col flex align-items-center padding-s">
                <Button
                  className="icon-btn"
                  onClick={showAddFriendModal}
                  text={"Annuller"}
                />
              </div>
              <div className="col flex align-items-center padding-s">
                <Button
                  className="icon-btn add"
                  onClick={() => onClickAddFriend()}
                  text={"Tilføj"}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Friendlist;
