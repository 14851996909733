import React, { useEffect } from 'react';

// Components - Material UI

// Components
import Logo from '../../assets/default_images/structio-logo-with-text-alfa.png';

// Style
import './OpeningScene.scss';

const OpeningScene = (props) => {
  useEffect(() => {
    openingScene();
  }, []);

  const openingScene = () => {
    const element = document.getElementById("opening-scene");
    element.addEventListener("click", quickCloseScene);
    setTimeout(function () {
      element.classList.add("blur");
      element.removeEventListener("click", quickCloseScene);
    }, 2000);
  }

  const quickCloseScene = () => {
    const element = document.getElementById("opening-scene");
    element.classList.add("blur");
    element.removeEventListener("click", quickCloseScene);
  }

  return (
    <div>
      <div id="opening-scene" className="opening-scene">
        <div className="opening-scene-img">
          <img alt="Structio Logo" src={Logo} style={{ maxWidth: 220 + 'px' }} />
        </div>
        {/* Keep it simple and disable text for now
          <div className="opening-scene-text">
            <span>Din digitale huskeliste</span>
          </div>
        */}
      </div>
    </div>
  );
}

export default OpeningScene;
