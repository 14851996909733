import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components - Material UI
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

// Components
import HexagonPicture from '../../../components/picture/hexagon';
import DropdownModal from './dropdownModal';
import Logo from '../../../assets/default_images/structio-logo-with-text-alfa.png';

// Icons

// Style
import './Topbar.scss';

const Topbar = (props) => {
  // Store
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  // Initialize states
  const [modalState, setModalState] = useState(false);

  let fullname;
  let pictureComponent;
  if (user) {
    fullname = `${user.firstname} ${user.lastname}`
    pictureComponent = <HexagonPicture
      name={`${user.firstname} ${user.lastname}`}
      size="medium" />
    if (user.pictureUrl) {
      pictureComponent = <HexagonPicture
        picture={user.pictureUrl}
        size="medium"
      />
    }
  }

  return (
    <div className="topbar-container">
      <div className={'topbar'}>
        <div className="topbar-structio-logo">
          <Link className="link" to={`${process.env.PUBLIC_URL}/`}>
            <img alt="Structio Logo" src={Logo} />
          </Link>
        </div>
        <div className="topbar-profile">
          <Button className="icon-btn flex no-padding" aria-controls="simple-menu" aria-haspopup="true" onClick={() => setModalState(!modalState)}>
            <div className="name">
              <span className="flex top-bar-profile-name">{fullname}</span>
            </div>
            {pictureComponent}
          </Button>
        </div>
      </div>
      <DropdownModal
        state={modalState}
        closeModal={() => setModalState(!modalState)}
      />
    </div>
  );
}
export default Topbar;
