import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// Types
import type { RootState } from "../../store/store.ts";
import { INotification } from "../../types";
// Services
import ApiService from "../../services/ApiService";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import { dateTimestamp, timeFormatter } from "../../services/DateHandler";
// Components
import {
  Notifications as NotificationsIcon,
  ArrowForwardIos,
} from "@mui/icons-material";
import Fade from "@mui/material/Fade";
import Icon from "../../components/icon";
import HexagonPicture from "../../components/picture/hexagon";
import Button from "../../components/button/button";
import StructioLogo from "../../assets/default_images/structio-logo-128w.png";
import IphoneVideo from "../../components/notification/modalVideo/IphoneVideo";
import AndroidVideo from "../../components/notification/modalVideo/AndroidVideo";
// Stores
import { setCalendarDate } from "../../store/features/todoitems";
import {
  getNotifications,
  getNotificationPermission,
} from "../../store/features/notifications.ts";
// Style
import "./Notifications.scss";

const Notifications = (props) => {
  /* Intialize writing Store */
  const dispatch = useDispatch();
  /* Store */
  const userStore = useSelector((state: RootState) => state.user);
  const user = userStore.user;
  const notificationsStore = useSelector(
    (state: RootState) => state.notifications
  );
  const notifications = notificationsStore.notifications;
  const notificationPermission = notificationsStore.notificationPermission;
  /* props */
  const {} = props;
  /* Initialize states */
  const [modalIphone, setModalIphone] = useState(false);
  const [modalAndroid, setModalAndroid] = useState(false);

  useEffect(() => {
    dispatch(getNotificationPermission());
    setNotificationsRead();
    // Runs when unMounting component
    return () => {
      clearNotifications();
    };
  }, []);

  const setNotificationsRead = async () => {
    let unreadNotifications: boolean = false;
    notifications.forEach((notification) => {
      if (!notification.isRead) return (unreadNotifications = true);
    });
    if (unreadNotifications) {
      await ApiService.notificationsRead();
    }
    // Clear badge from Appscreen if AppBadge if exists on device
    if (navigator.clearAppBadge) {
      navigator.clearAppBadge();
    }
  };

  /* Sets all items to read and updates the store value */
  const clearNotifications = () => {
    const newList = cloneDeep(notifications);
    newList.forEach((item: INotification) => {
      item.isRead = true;
    });
    dispatch(getNotifications(newList));
  };

  const requestPushPermission = async () => {
    await ApiService.requestPushPermission();
    dispatch(getNotificationPermission());
  };

  /* When a notification is clicked redirect to the correct date on Calendar */
  const notificationClick = (todoDate: Date) => {
    dispatch(setCalendarDate(todoDate));
  };

  const getLink = (type, date) => {
    switch (type) {
      case "todoitem":
      case "todo":
      case "todopending":
        return `alltodos`;
      case "friendrequest":
        return "user?friendlist=true";
      default:
        return "";
    }
  };

  const notificationItems: React.JSX.Element[] = [];
  if (notifications) {
    const sortedNotifications = notifications.slice();
    sortedNotifications.sort((a, b) => {
      const dateA = dateTimestamp(
        a.date
      ); /* Convert to timestamp for secure calculation i.e work on IOS */
      const dateB = dateTimestamp(b.date);
      return dateB - dateA;
    });
    sortedNotifications.forEach((item, index) => {
      const urlLink = getLink(item.type, item.todoDate);
      notificationItems.push(
        <Link
          key={`notifications-item-${index}`}
          className="link anchor-no-line"
          to={`${process.env.PUBLIC_URL}/${urlLink}`}
          onClick={() => notificationClick(item.todoDate)}
        >
          <div className="notifications-item">
            {item.type === "todoitem" ? (
              <img
                className="notifications-item-logo"
                alt="Structio Logo notifications"
                src={StructioLogo}
              />
            ) : (
              <HexagonPicture name={item.name} size={"small"} />
            )}
            <div className="notifications-item-text-container">
              <span className="opacity--50 font-14">{item.name}</span>
              <span className="font-14">{item.text}</span>
              <span className="flex flex-justify--end flex-align--center opacity--50 font-12 text-right">
                {timeFormatter(item.date)}
                <Icon
                  className="padding-xs--l"
                  color="var(--text-standard-opacity)"
                  width="12px"
                  height="12px"
                >
                  <ArrowForwardIos />
                </Icon>
              </span>
            </div>
            <div
              className={`notifications-item-new ${
                !item.isRead ? "show" : "hidden"
              }`}
            ></div>
          </div>
        </Link>
      );
    });
  }

  return (
    <Fade in timeout={1000}>
      <div className="notifications-container">
        <div className="notifications-content">
          <div className="notifications-header">
            <div className="flex align--center margin-s--b">
              <span className="title font-30">Notifikationer</span>
            </div>
          </div>
          <div className="notifications-subtitle-container">
            <div className="header-title text-left">Dine notifikationer</div>
          </div>
          <div className="notifications-box-information">
            <span className="font-12">Notifikationer slettes efter 7 dage</span>
          </div>
          <div className="notifications-items-container">
            <div className={`notifications-box`}>
              <div className="notifications-box-content">
                {notificationPermission === "granted" ? (
                  notificationItems.length > 0 ? (
                    notificationItems
                  ) : (
                    <div className="flex flex--center margin-m--tb">
                      <span className="opacity--50 font-14">
                        Ingen notifikationer de seneste 7 dage
                      </span>
                    </div>
                  )
                ) : (
                  <div className="flex flex--center margin-m--t">
                    {notificationPermission !== "denied" ? (
                      <div className="flex flex--center text-center padding-m">
                        {notificationPermission === "none" ? (
                          <div>
                            <div className="margin-m--b text-center">
                              <b>
                                Notifikationer er ikke tilladt på mobil i
                                browser
                              </b>
                            </div>
                            <div className="text-center">
                              <span className="header-title">
                                <b className="color--structio">Men Structio</b>{" "}
                                er en Web App og kan downloades som App via din
                                browser.
                              </span>
                            </div>
                            <ul className="header-title margin-m--t">
                              <li
                                className="margin-m--tb"
                                onClick={() => setModalIphone(!modalIphone)}
                              >
                                Download til{" "}
                                <b className="color--structio link">iPhone</b>
                              </li>
                              <li
                                onClick={() => setModalAndroid(!modalAndroid)}
                              >
                                Download til{" "}
                                <b className="color--structio link">Android</b>
                              </li>
                            </ul>
                            <div className="margin-m--tb header-title text-center">
                              Glem aldrig mere en opgave, imens du er på farten!
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex--center flex--column">
                            <div className="enable-notifications-icon">
                              <NotificationsIcon />
                            </div>
                            <div className="flex flex--center flex--column">
                              <h2 className="no-margin margin-m--b">
                                Få mest ud af Structio!
                              </h2>
                              <div className="flex flex--center flex--column">
                                <Button
                                  className="structio"
                                  text="Tillad notifikationer"
                                  onClick={() => requestPushPermission()}
                                />
                                <p>
                                  Glem aldrig mere en opgave, imens du er på
                                  farten!
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="flex flex--center flex--column padding-m">
                        <div className="margin-m--b">
                          <b>Notifikationer er ikke tilladt 😞</b>
                        </div>
                        <div className="text-center">
                          Du kan aktivere{" "}
                          <b className="color--structio">notifikationer</b>{" "}
                          under <i>indstillinger ➜ apps</i> på din telefon.{" "}
                          <br />
                          Eller i din browser på din computer.
                        </div>
                        <div className="margin-m--t color--structio font-14 text-center">
                          Glem aldrig mere en opgave, imens du er på farten!
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <IphoneVideo
                state={modalIphone}
                onClickShow={() => setModalIphone(!modalIphone)}
              />
              <AndroidVideo
                state={modalAndroid}
                onClickShow={() => setModalAndroid(!modalAndroid)}
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Notifications;
