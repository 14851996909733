import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// Services

// Store

// Components
import Button from '../../../button/button/Button';

// Icons
import ClearIcon from '@mui/icons-material/Clear';

// Style
import './ModalDelete.scss';

const ModalDelete = (props) => {
    /* Store */

    /* props */
    const { title, state, toggleModal, onClick } = props

    /* Initialize states */

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={'structio-modal-container'}
            open={state}
            onClose={toggleModal}
            closeAfterTransition
        >
            <Fade in={state}>
                <div className="structio-modal-box">
                    <button className="icon-btn close-modal-icon" type="button" onClick={toggleModal}><ClearIcon /></button>
                    <h1 className="modal-title no-margin">Slet note</h1>
                    <div className="line" />
                    <span className="title-text flex-justify--center margin-m--t">Er du sikker på at du vil slette følgende note</span>
                    <div className="flex flex-justify--center">
                        <span className="text-color-standard margin-m--b font-24">{title}</span>
                    </div>
                    <div className="flex flex-justify--center">
                        <Button
                            className="margin-s"
                            type="delete"
                            text={'Slet note'}
                            onClick={onClick}
                        />
                        <Button
                            className="margin-s"
                            text={'Annuler'}
                            onClick={toggleModal}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalDelete;