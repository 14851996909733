import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Fade from '@mui/material/Fade';

// Services

// Store

// Components
import NoteItem from '../../components/note/noteItem';
import ModalCreate from '../../components/note/modal/create/ModalCreate.tsx';
import Button from '../../components/button/button';
import EmptyItem from '../../components/note/emptyItem';

// Icons
import AddIcon from '@mui/icons-material/Add';

// Content

// Style
import './Notes.scss';

const Notes = (props) => {
  /* Store */
  const noteStore = useSelector((state) => state.notes);
  const noteList = noteStore.notes;
  /* props */
  /* Initialize states */
  const [toggleModalCreate, setToggleModalCreate] = useState(false);

  let notes = [];
  if (noteList) {
    noteList.forEach((note, index) => {
      notes.push(<NoteItem key={'noteitem' + index}
        index={index}
        object={note}
      />)
    });
  }

  return (
    <Fade in timeout={1000}>
      <div className="notes-container">
        <div className="notes-content">
          <div className="notes-header">
            <div className="flex align--center margin-s--b">
              <span className="title font-30">Noter</span>
            </div>
            <Button className="structio" icon={<AddIcon className="todo-svg" />} text="Opret note" onClick={() => setToggleModalCreate(toggleModalCreate ? false : true)} />
          </div>
          <div className="notes-subtitle-container">
            <div className="header-title text-left">Dine noter</div>
          </div>
          <div className="notes-items-container">
            {notes.length ?
              notes :
              <EmptyItem
                text="Du har ingen noter!"
                component={<Button className="add margin-m--t" icon={<AddIcon className="todo-svg" />} text="Opret note" onClick={() => setToggleModalCreate(toggleModalCreate ? false : true)} />}
              />
            }
          </div>
        </div>
        {toggleModalCreate && <ModalCreate
          state={toggleModalCreate}
          toggleModal={() => setToggleModalCreate(toggleModalCreate ? false : true)}
        />}
      </div>
    </Fade>
  )
}

export default Notes;
