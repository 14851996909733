import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// Services
import AuthService from '../../../services/Auth';
// Notifications
import { NotificationManager } from 'react-notifications';
// Components
import Switch from '@mui/material/Switch';
import HexagonPicture from '../../../components/picture/hexagon';
import { WbSunny, Brightness2 } from '@mui/icons-material';
// Store
import { setTheme } from "../../../store/features/theme.ts";


const UserPanel = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  const themeStore = useSelector((state) => state.theme);
  const themeState = themeStore.theme;
  /* Initialize states */
  const [profileId, setProfileId] = useState(""); // Trims all white spaces

  useEffect(() => {
    if (user && user.firstname) {
      const trimmedUsername = user.firstname.replace(/ /g, '');
      setProfileId(trimmedUsername);
    }
  }, [user]);


  const onChangePicture = async (picture) => {
    if (picture.target.files[0]) {
      const file = picture.target.files[0];
      const fileType = file.type;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (validImageTypes.includes(fileType) && file.size < 10000000) {
        const formData = new FormData();
        formData.append('profilePicture', picture.target.files[0]);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            "Authorization": `${AuthService.getAccessToken()}`
          }
        };

        try {
          const res = axios.post(`${process.env.REACT_APP_API_URL}/user/picture`, formData, config);
          if (res) {
            NotificationManager.success('Billede er ændret', '');
          }
        } catch (err) {
          NotificationManager.warning('Billedet skal være jpg, jpeg, png eller gif og under 10mb', 'Fejl!');
        }
      }
    }
  }

  // Get picture
  let pictureComponent = <HexagonPicture
    name={`${user.firstname} ${user.lastname}`}
    size="large" />
  if (user.pictureUrl) {
    pictureComponent = <HexagonPicture
      picture={user.pictureUrl}
      size="large"
    />
  }

  return (
    <div className="flex flex--column justify-center transparent-background padding-xl--b">
      <div className="flex flex--center flex--column justify-center transparent-background">
        {pictureComponent}
        {/* Upload personal picture are disabled for now
        <label htmlFor="upload-picture" className="icon-btn change-picture-btn">
          <input id="upload-picture" type="file" onChange={() => onChangePicture} />
          <CameraAlt /> 
        </label>
        */}

        <div className="col text-center padding-m--t">
          <span className="box-title">{user.firstname} {user.lastname}</span>
        </div>
        <div className="profile-id col text-center line">
          <span className="profile-text">
            Profil-ID:
            <span className="fontweight-bold">
              &nbsp;
              {profileId}
              #
              {user.id}
            </span>
          </span>
        </div>
        <div className="margin-m--t">Tema</div>
        <div className="topbar-theme-changer">
          <span>Lys&nbsp;</span>
          <WbSunny className={`light-theme ${themeState === "dark" ? "" : "light-active"}`} />
          <div className="switch-content-container">
            <Switch
              checked={themeState === "dark"}
              onChange={() =>
                dispatch(
                  setTheme({
                    theme: themeState
                  })
                )
              }
              value={themeState}
            />
          </div>
          <Brightness2 className={`dark-theme ${themeState === "dark" ? "dark-active" : ""}`} />
          <span>&nbsp;Mørk</span>
        </div>
      </div>
    </div>
  );
}

export default UserPanel;
