import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';

// Services
import ApiService from '../../../services/ApiService';
import AuthService from '../../../services/Auth';
import { getCookie, genderFormatter } from '../../../services/FunctionHelper.tsx';

// Components - Material UI
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

// Components
import { NotificationManager } from 'react-notifications';
import Button from '../../../components/button/button';
import InputField from '../../../components/inputField';

// Icons
import Clear from '@mui/icons-material/Clear';

// Store
import { setIsLoading } from '../../../store/features/spinner';

const UserRead = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  const friendlistStore = useSelector((state) => state.friendlist);
  const pendinglist = friendlistStore.pendinglist;
  /* data props */
  const { onClickContentShow } = props;
  /* Initialize states */
  const [deleteVerifyInput, setDeleteVerifyInput] = useState("");
  const [modalDeleteUser, setModalDeleteUser] = useState(false);

  const badgePending = pendinglist.length;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const showFriendList = urlParams.get('friendlist');
    if (showFriendList) {
      onClickContentShow("friendlist")
    }
  });

  const toggleModal = () => {
    setModalDeleteUser(!modalDeleteUser);
    setDeleteVerifyInput("");
  }

  const deleteUser = () => {
    const UID = getCookie("auth-uid");
    try {
      axios.delete(`${process.env.REACT_APP_API_URL}/user/delete/${UID}`, AuthService.config())
        .then(async (response) => {
          const res = response.data; // return true on success
          if (res) {
            dispatch(setIsLoading(true));
            await ApiService.removePushDevice();
            AuthService.deleteCookies();
            window.location.reload();
          } else {
            NotificationManager.warning('Profil kan ikke slettes på nuværende tidspunkt.');
          }
        })
    } catch (err) {
      console.error(err);
      NotificationManager.warning('Profil kan ikke slettes på nuværende tidspunkt.');
    }
  }

  return (
    <Fade in>
      <div className="fade user-content">
        <div className="user-content-box">
          <div className="flex flex--center flex--column">
            <div className="user-btn justify-center margin-m--b">
              <Button
                onClick={() => onClickContentShow("friendlist")}
                text={'Kontaktliste'}
                badge={badgePending}
                badgeColor="red"
              />
            </div>
            <div className="user-btn justify-center">
              <Button
                onClick={() => onClickContentShow("update")}
                text={'Rediger oplysninger'}
              />
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center padding-m--t">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Fornavn</span>
              </div>
              <div className="profile-text">
                <span>{user.firstname}</span>
              </div>
            </div>
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Efternavn</span>
              </div>
              <div className="profile-text">
                <span>{user.lastname}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>By</span>
              </div>
              <div className="profile-text">
                <span>{user.city}</span>
              </div>
            </div>
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Post nr.</span>
              </div>
              <div className="profile-text">
                <span>{user.zip}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Fødselsår</span>
              </div>
              <div className="profile-text">
                <span>{user.birth}</span>
              </div>
            </div>
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Køn</span>
              </div>
              <div className="profile-text">
                <span>{genderFormatter(user.gender)}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Email</span>
              </div>
              <div className="profile-text">
                <span>{user.email}</span>
              </div>
            </div>
          </div>
          <div className="flex flex--center flex--column">
            <div className="user-btn justify-center margin-xl--t">
              <Button
                onClick={() => setModalDeleteUser(true)}
                text={'Slet profil permanent'}
                type="delete"
              />
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={'structio-modal-container'}
          open={modalDeleteUser}
          onClose={() => toggleModal()}
          closeAfterTransition
        >
          <Fade in={modalDeleteUser}>
            <div className="structio-modal-box">
              <button className="icon-btn close-modal-icon" type="button" onClick={() => toggleModal()}><Clear /></button>
              <h1 className="modal-title no-margin color--delete">Slet din profil</h1>
              <div className="row align-items-center line padding-m--t margin-m--b">
                <div className="row align-items-center">
                  <div className="col profile-title">
                    <span className="color--text">Ønsker du at slette din profil permanent? Alt data slettes og denne handling kan ikke fortrydes, efter du har trykket slet.</span>
                  </div>
                  <div className="col profile-title margin-m--t">
                    <span className="color--text">Skriv "<span className="color--red">SLET</span>" for at verificere, at du ønsker at slette din profil permanent.</span>
                  </div>
                  <div className="col margin-s--t">
                    <InputField className="color--red" type="text" placeholder="SLET" value={deleteVerifyInput} onChange={(event) => setDeleteVerifyInput(event.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col flex align-items-center padding-s">
                  <Button
                    className="icon-btn"
                    onClick={() => toggleModal()}
                    text={'Annuller'}
                  />
                </div>
                <div className="col flex align-items-center padding-s">
                  <Button
                    className="icon-btn delete"
                    onClick={() => deleteUser()}
                    text={'Slet'}
                    disabled={deleteVerifyInput === "SLET" ? "" : "disabled"}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </Fade>
  );
}

export default UserRead;
