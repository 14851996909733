import React from 'react';

// Components - Material UI

// Components
import IconButton from '../../button/iconButton';

// Icons
import { DeleteForever, AssignmentTurnedIn } from '@mui/icons-material';

// Style
import './SubItem.scss';

/** @ViableTypes for props
 ** className - Possible to add custom classNames to the component
 ** subitem - Object of an subitem
 */

const SubItemCreate = (props) => {
    /* data */
    const { className, subitem } = props;
    const { description } = subitem;
    /* functions */
    const { deleteSubitem } = props;

    return (
        <div className={`flex flex-justify--between full-width padding-s--b ${className}`}>
            <div className="flex flex-align--center">
                <div className="flex flex-align--center font-14">
                    <AssignmentTurnedIn style={{ color: 'var(--color--structio)'}} />
                    <span className="padding-xs--l">{description}</span>
                </div>
            </div>
            <div className="flex flex-align--center padding-s--l">
                <IconButton
                    className="color--delete-hover"
                    iconRight={<DeleteForever />}
                    onClick={deleteSubitem} />
            </div>
        </div>
    )
}
export default SubItemCreate;
