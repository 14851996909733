import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Helpers
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// Utilities
import { dateTimestamp } from '../services/DateHandler';
// Services
import { onMessageListener } from '../firebase';
import ApiService from '../services/ApiService';
import AuthService from '../services/Auth';
import { windowSizeListener } from '../services/FunctionHelper.tsx';
import ScrollToTop from '../services/ScrollToTop';

// Components
import { NotificationManager } from 'react-notifications';
import PullToRefresh from '../components/pullToRefresh';
import BottomMenu from '../components/menu/bottomMenu/BottomMenu';
import Topbar from '../components/menu/topbar';
import BackgroundPicture from '../components/backgrounds/Background';
import OpeningScene from '../components/openingscene/OpeningScene.jsx';
import FrontPushNotification from '../components/pushNotifications/FrontPushNotification';
//Store
import { getUser } from '../store/features/user.ts';
import { setTodoitems } from '../store/features/todoitems';
import { setIsLoading } from '../store/features/spinner';
import { updateTheme } from '../store/features/theme.ts';
// Component Screens
import Dashboard from './dashboard/Dashboard';
import Todo from './todo';
import User from './user/User';
import Welcome from './welcome/Welcome';
import Notes from './notes';
import Notifications from './notifications';

// STATIC LOGGED-IN VALUES
function Routes() {
  /* Store */
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  const todoitemsStore = useSelector((state) => state.todoitems);
  const todoitems = todoitemsStore.all;
  /* Initialize states */
  const [isMobileView, setIsMobileView] = useState(false);
  const [specificTodoItem, setSpecificTodoItem] = useState([]);
  const [welcomeScreen, setWelcomeScreen] = useState(false);

  useEffect(() => {
    dispatch(setIsLoading(true));
    ApiService.getUser().then(async res => {
      const user = res;
      dispatch(updateTheme({ theme: user.theme }));
      dispatch(getUser(user));

      /* Check if user information has been sat otherwise show welcome screen */
      let isWelcomeScreen = false;
      if (!user.firstname || !user.lastname) {
        isWelcomeScreen = true;
      }
      setWelcomeScreen(isWelcomeScreen);

      await ApiService.fetchAllData(user.id, dispatch, true);

      /* Get push permission */
      await ApiService.requestPushPermission();
      dispatch(setIsLoading(false));

      // SW listener - Handle foreground push message
      onMessageListener(async (payload) => {
        if (payload) {
          const title = payload.data.title;
          const body = payload.data.body
          NotificationManager.info(body, title, 7000);
          // Refetch data to make sure it's realtime when receiving a notification in foreground
          await ApiService.fetchAllData(user.id, dispatch, false);
        }
      })

      // SW listener - Handle background push message
      navigator.serviceWorker.addEventListener('message', async (event) => {
        const backgroundMsg = document.visibilityState === "hidden";
        if (backgroundMsg) {
          await ApiService.fetchAllData(user.id, dispatch, false);
        }
      });
    }).catch((error) => {
      console.error("INIT login error on routes: ", error);
      // If fetching users bugging on backend - then send user to the login screen
      AuthService.deleteCookies();
      window.location.href = "/?login=failed";
    });

    // Check Device based on width
    const deviceClass = windowSizeListener() ? 'smartphone full-width no-margin' : 'desktop full-width no-margin';
    const getDevice = windowSizeListener() ? 'smartphone' : 'desktop';
    document.body.classList.add(getDevice);
    setIsMobileView(deviceClass);
  }, [])

  /**
   * TODO: Add Specific todo - stateHandlers
   */
  const stateHandlerSetAllTodos = (todoItems) => {
    const allTodos = todoitems.slice();
    const listRes = [...allTodos, ...todoItems] // Merge the todolist with the new list-of-todos
    const sortListByDate = listRes.sort((a, b) => {
      const dateA = dateTimestamp(a.date); /* Convert to timestamp for secure calculation i.e work on IOS */
      const dateB = dateTimestamp(b.date);
      return dateA - dateB;
    });
    const sortByStatus = sortListByDate.sort((a, b) => a.isComplete - b.isComplete); // Sort by isComplete - completed todos at the end
    dispatch(setTodoitems(sortByStatus));
  }

  const stateHandlerUpdateSpecificTodoItem = (updatedObject) => {
    setSpecificTodoItem(updatedObject);
  }

  /**
   * Handles Complete, Uncomplete & Delete on a specific todo
   */
  const stateHandlerUpdateTodoList = (updatedArray, skipSort) => {
    let todoList = updatedArray;
    if (skipSort) {
      dispatch(setTodoitems(todoList));
    } else {
      const sortListByDate = updatedArray.sort((a, b) => {
        const dateA = dateTimestamp(a.date); /* Convert to timestamp for secure calculation i.e work on IOS */
        const dateB = dateTimestamp(b.date);
        return dateA - dateB;
      });
      const sortByStatus = sortListByDate.sort((a, b) => a.isComplete - b.isComplete); // Sort by isComplete - completed todos at the end
      todoList = sortByStatus;
      dispatch(setTodoitems(todoList));
    }
  }

  return (
    <BrowserRouter>
      <BackgroundPicture />
      {welcomeScreen ?
        <Welcome /> :
        <div>
          <OpeningScene />
          <div className="main-content-container">
            <Topbar />
            <PullToRefresh onRefresh={() => ApiService.fetchAllData(user.id, dispatch, true)}>
              <div className={`${isMobileView} routes-container`}>
                <ScrollToTop />
                {/* Test for foreground push msg <FrontPushNotification /> */}
                <Switch>
                  <Route
                    path={`${process.env.PUBLIC_URL}/`}
                    exact
                    render={() => <Dashboard
                      stateHandlerSetAllTodos={stateHandlerSetAllTodos}
                    />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/alltodos`}
                    exact
                    render={() => <Todo
                      TODO_TYPE={'all'}
                      specificTodoItem={specificTodoItem}
                      stateHandlerSetAllTodos={stateHandlerSetAllTodos}
                      stateHandlerUpdateSpecificTodoItem={stateHandlerUpdateSpecificTodoItem}
                      stateHandlerUpdateTodoList={stateHandlerUpdateTodoList}
                    />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/notes`}
                    exact
                    render={() => <Notes />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/notifications`}
                    exact
                    render={() => <Notifications />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/user`}
                    exact
                    render={() => <User />}
                  />
                  <Redirect to='/' />
                </Switch>
              </div>
            </PullToRefresh>
          </div>
          <div>
            <BottomMenu />
          </div>
        </div>
      }
    </BrowserRouter>
  );
}

export default Routes;
