import React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

// Components
import Button from '../../button/button';

// Icons
import Clear from '@mui/icons-material/Clear';
import AndroidVideoUrl from '../../../assets/videos/android_download_pwa.mp4';

// Style
import './style.scss';

/** @ViableTypes for props
 ** Title - Provides the modal a title
 ** Content - Provides the modals body with content (elements)
 ** onClick - True | False - Set the modals visible
 */

const AndroidVideo = (props) => {
    /* data */
    const { state } = props
    /* functions */
    const { onClickShow } = props

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={'structio-modal-container'}
            open={state}
            onClose={onClickShow}
            closeAfterTransition
        >
            <Fade in={state}>
                <div className="structio-modal-box">
                    <button className="icon-btn close-modal-icon" type="button" onClick={onClickShow}><Clear /></button>
                    <div className="flex flex-justify--center margin-m--t">
                        <video className="download-video" controls autoPlay muted playsInline>
                            <source src="./assets/videos/android_download_pwa.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="row align-items-center">
                        <div className="col flex align-items-center padding-s">
                            <Button
                                className="icon-btn"
                                onClick={onClickShow}
                                text={'Luk'}
                            />
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default AndroidVideo;
