import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/App.js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";

/* Store */
import { Provider } from "react-redux";
import store from "./store/store.ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster (PWA app), you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
