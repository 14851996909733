import React from 'react';

// Components

// Icons
import { ReactComponent as HexagonIcon } from '../../../assets/icons/hexagon.svg';

// Style
import './Default.scss';

/** @ViableTypes for props
 ** name - string: Provides a name/title to the figure
 ** size - className: small | large.
 */

const Default = (props) => {
    /* data */
    const { name, size } = props
    /* functions */
    // const { } = props
    let nameShort = name;
    let bgColor = "#67B7BF";
    let getLetters;

    if (name) {
        switch (true) {
            case (name.charAt(0).toLowerCase() === "a"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BF6767";
                break;
            case (name.charAt(0).toLowerCase() === "b"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BF7E67";
                break;
            case (name.charAt(0).toLowerCase() === "c"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BF9A67";
                break;
            case (name.charAt(0).toLowerCase() === "d"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BFAE67";
                break;
            case (name.charAt(0).toLowerCase() === "e"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BFBF67";
                break;
            case (name.charAt(0).toLowerCase() === "f"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#B3BF67";
                break;
            case (name.charAt(0).toLowerCase() === "g"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#A2BF67";
                break;
            case (name.charAt(0).toLowerCase() === "h"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#8CBF67";
                break;
            case (name.charAt(0).toLowerCase() === "i"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#7BBF67";
                break;
            case (name.charAt(0).toLowerCase() === "j"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67BF67";
                break;
            case (name.charAt(0).toLowerCase() === "k"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67BF80";
                break;
            case (name.charAt(0).toLowerCase() === "l"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67BF9B";
                break;
            case (name.charAt(0).toLowerCase() === "m"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67BFB2";
                break;
            case (name.charAt(0).toLowerCase() === "n"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67BEBF";
                break;
            case (name.charAt(0).toLowerCase() === "o"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67ACBF";
                break;
            case (name.charAt(0).toLowerCase() === "p"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#6790BF";
                break;
            case (name.charAt(0).toLowerCase() === "q"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#6778BF";
                break;
            case (name.charAt(0).toLowerCase() === "r"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#6B67BF";
                break;
            case (name.charAt(0).toLowerCase() === "s"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#8367BF";
                break;
            case (name.charAt(0).toLowerCase() === "t"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#A067BF";
                break;
            case (name.charAt(0).toLowerCase() === "u"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#909393";
                break;

            case (name.charAt(0).toLowerCase() === "v"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BA67BF";
                break;
            case (name.charAt(0).toLowerCase() === "w"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#AB64B0";
                break;
            case (name.charAt(0).toLowerCase() === "x"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BF67A7";
                break;
            case (name.charAt(0).toLowerCase() === "y"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BF6798";
                break;
            case (name.charAt(0).toLowerCase() === "z"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BFB067";
                break;
            case (name.charAt(0).toLowerCase() === "æ"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#BF6773";
                break;
            case (name.charAt(0).toLowerCase() === "ø"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#679BBF";
                break;
            case (name.charAt(0).toLowerCase() === "å"):
                getLetters = name.match(/\b(\w)/g); // Get first letter after each space
                if (size === "large") {
                    nameShort = getLetters.join('').substring(0, 3); // JSON
                } else {
                    nameShort = getLetters.join('').substring(0, 1); // JSON
                }
                bgColor = "#67BF6F";
                break;
            default:
                break;
        }
    }

    return (
        <div className={`hexa-default ${size}`}>
            <div className="hexa-content">
                <HexagonIcon style={{ color: bgColor }} />
                <span className="hexa-text">{nameShort}</span>
            </div>
        </div>
    )
}

export default Default;
