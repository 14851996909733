import React from 'react';

// Components
import Badge from '@mui/material/Badge';

// Icons

// Style
import './Button.scss';

/** @ViableTypes for props
 ** class - Possible to add custom classNames to the component
 ** height - ex 10px - sets the height of the button - default 25px;
 ** width - ex 10px - sets the width of the button - default 200px;
 ** text - String: Provides a text to the icon
 ** icon - Component: Provides a icon to the button !required
 ** onClick - Func: Adds a functions to the button
 ** disabled - Boolean: true disables the button
 ** type - Fixed styling types: delete | add | circle
 ** badge - Number: makes it possible to apply a bagde to the element
 ** badgeColor - String: 'green' | 'red' | 'orange' -> Possible to add more colors in global.css - _badge.scss
 */

const Button = (props) => {
    /* data */
    const { className, text, type, icon, disabled, height, width, badge, badgeColor } = props
    /* functions */
    const { onClick } = props

    const heightRes = height ? { height: `${height}px` } : { height: "auto" };
    const widthRes = width ? { width: `${width}px` } : { width: "200px" }

    return (
        <button
            className={`button ${className ? className : ""} ${type ? type : ""}`}
            style={{ ...heightRes, ...widthRes }}
            disabled={disabled ? "disabled" : null}
            onClick={onClick}>
            {badge ? <Badge overlap="rectangular" className={`flex ${badgeColor}`} badgeContent={badge} /> : null}
            <div className="col">
                <span className="btn-text">{icon} {text}</span>
            </div>
        </button>
    )
}
export default Button;
