import axios from 'axios';

// Services
import { getNotificationToken } from '../firebase';
import AuthService from './Auth';
import { setCookie } from './FunctionHelper.tsx';
import { dateFirstDayInMonthFormatted, dateNowFormatted, dateTimestamp } from '../services/DateHandler';


//Store
import { setFriendlist } from '../store/features/friendlist';
import { clearDateCache, setDateCache, setTodoitems } from '../store/features/todoitems';
import { setNotes } from '../store/features/notes';
import {
  getNotifications,
  setTodoNotifications,
} from '../store/features/notifications.ts';

class ApiService {

  /**
   * Get accessToken (JWT) from user
   */
  createJWT = async (email, authUid, authToken) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      withCredentials: true
    };

    const data = {
      "email": email,
      "authUid": authUid,
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/jwt/create`, data, config)
      const tokenJWT = res.data;
      /* Sets Cookie to 1 day lifetime 
       * Backend has 2 days then we're secure it will always expire in frontend before 
       * If they're exact same expireDate then we risk the user being unable to call the API if they log on exactly 1 sec before expirationDate on backend
       * By expire frontend 1 day before then we minimize the call to refreshToken but still prevent users from using an inactive token meanwhike in app
       */
      setCookie('tokenJWT', tokenJWT, 1);
      return true;
    } catch (error) {
      console.error(error);

    }
  }
  /**
   * Refresh users accessToken (JWT)
   */
  refreshJWT = async (email, authUid, authToken) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      withCredentials: true
    };

    const data = {
      "email": email,
      "authUid": authUid,
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/jwt/refresh`, data, config)
      const tokenJWT = res.data;
      /* Sets Cookie to 1 day lifetime 
        * Backend has 2 days then we're secure it will always expire in frontend before 
        * If they're exact same expireDate then we risk the user being unable to call the API if they log on exactly 1 sec before expirationDate on backend
        * By expire frontend 1 day before then we minimize the call to refreshToken but still prevent users from using an inactive token meanwhike in app
        */
      setCookie('tokenJWT', tokenJWT, 1);
      return tokenJWT;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Get the current logged-in user
   */
  getUser = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/getuser`, AuthService.config())
      const responseJson = res.data;
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  /**
    * Get the logged-in users friendlist.
    */
  getUserFriendlist = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/friendlist`, AuthService.config())
      const responseJson = res.data;
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }


  /**
  * Get the logged-in users friendlist.
  */
  getNotifications = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/notifications`, AuthService.config())
      const responseJson = res.data;
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Fetches the todos connected to the user
   * dateNow is to check if any todos has autoComplete = 1 and date has passed - backend is 2 hours behind on Prod so we send date from Frontend
   * dateFrom is always the 1st day of a month - used to fetch monthly todos
   */
  getTodoItems = async (dateNow, dateFrom) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/todoitems?dateNow=${dateNow}&dateFrom=${dateFrom}`, AuthService.config())
      const responseJson = res.data;
      if (!responseJson) return;
      const sortListByDate = responseJson.sort((a, b) => {
        const dateA = dateTimestamp(a.date); /* Convert to timestamp for secure calculation i.e work on IOS */
        const dateB = dateTimestamp(b.date);
        return dateA - dateB;
      });
      const sortByStatus = sortListByDate.sort((a, b) => a.isComplete - b.isComplete);
      return sortByStatus;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Fetch notes
   */
  getNotes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/notes`, AuthService.config())
      const responseJson = res.data;
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  // UPDATE - User theme
  changeTheme = async (selectedTheme) => {
    const data = {
      theme: selectedTheme,
    }

    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/user/change/theme`, data, AuthService.config());
      if (res) return true;
    } catch (error) {
      console.error(error);
    }
  }

  requestPushPermission = async () => {
    // Add PushDevice
    const requestToken = async () => {
      const token = await getNotificationToken();
      if (token) {
        const data = {
          token
        }

        axios.post(`${process.env.REACT_APP_API_URL}/pushdevice/add`, data, AuthService.config())
          .then((response) => {
            //console.log("device added");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    if (!("Notification" in window)) {
      // console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // console.log('Notification Permission granted - :)');
      requestToken();
    } else if (Notification.permission !== "denied") {
      // console.log("Notification Permission default - i just ask again LOL");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        requestToken();
      }
    } else {
      // console.log("Notification Permission denied - :(");
    }
  }

  removePushDevice = async () => {
    const token = await getNotificationToken();
    if (token) {
      axios.delete(`${process.env.REACT_APP_API_URL}/pushdevice/remove/${token}`, AuthService.config())
        .then((response) => {
          // console.log("Token deleted");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.log("Token not found");
    }
  }

  /* Set all notifications as read */
  notificationsRead = async () => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user/notifications/read`,
        {},
        AuthService.config()
      )
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
        console.error(error);
      });
  }

  /* Fetch all data
   * Friendlist
   * Notes
   * Todoitems
   * Notifications
   */
  fetchAllData = async (userId, dispatch, initFetch = true) => {
    // Fetch friendlist
    const friendlistRes = await this.getUserFriendlist();
    dispatch(setFriendlist(friendlistRes));
    // Fetch Notes
    if (initFetch) {
      const notesRes = await this.getNotes();
      dispatch(setNotes(notesRes));
    }
    // Fetch Todoitems
    const dateNowRes = dateNowFormatted();
    const fetchDateFromStorage = localStorage.getItem("currentSelectedDate");
    const dateCurrentMonth = dateFirstDayInMonthFormatted(dateNowRes);
    const fetchDate = fetchDateFromStorage ? fetchDateFromStorage : dateCurrentMonth;
    const dateFromRes = dateFirstDayInMonthFormatted(fetchDate);
    // Fetch todos from where the user last left the Calendar date i.e 01.09.23
    const todoitemRes = await this.getTodoItems(dateNowRes, dateFromRes);
    dispatch(clearDateCache());
    dispatch(setTodoitems(todoitemRes));
    dispatch(setDateCache(dateFromRes));
    dispatch(setTodoNotifications({ todoitems: todoitemRes, userId: userId }));
    // Fetch currentMonth todo here - first check if it's not already currentMonth that have been fetched
    // Fetching this to secure if the user left on another month than current on calendar then also fetch this month incase
    // the user are on the dashboard otherwise it would break the dashboard cause currentMonth not being fetched
    if (fetchDateFromStorage && dateFromRes !== dateCurrentMonth) {
      const todoitemRes2 = await this.getTodoItems(dateNowRes, dateCurrentMonth);
      let allTodos = [...todoitemRes, ...todoitemRes2];
      allTodos = allTodos.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )
      dispatch(setTodoitems(allTodos));
      dispatch(setDateCache(dateCurrentMonth));
      dispatch(setTodoNotifications({ todoitems: allTodos, userId: userId }));
    }
    /* Get notifications */
    const notificationsRes = await this.getNotifications();
    dispatch(getNotifications(notificationsRes));
    let unreadNotifications = 0;
    notificationsRes.forEach((item) => {
      if (item.isRead === 0) unreadNotifications++;
    });
    // Clear badge from Appscreen - we do this if notifications is read on another device and opening on i.e mobile
    // then we clear the app badge
    if (navigator.clearAppBadge && unreadNotifications === 0) {
      navigator.clearAppBadge();
    }
  }
}

export default new ApiService();
