import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Services
import { timeFormatter, dateRes, dateNow } from '../../../services/DateHandler';

// Components - Material UI
import { Link } from 'react-router-dom';

// Components
import Button from '../../button/button';
// Icons
import { ArrowForwardIos, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';

// Store
import { setCalendarDate } from '../../../store/features/todoitems';

// Style
import './dashboardTodoList.scss';

const dashboardTodoList = (props) => {
    /* Intialize writing Store */
    const dispatch = useDispatch();
    /* Store */
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    const todoitemsStore = useSelector((state) => state.todoitems);
    const todaysTodos = todoitemsStore.todayslist;
    /* props */
    const { } = props
    /* Initialize states */
    const today = new Date().setHours(0, 0, 0); // Used to navigate to allTodos and todays date will be selected

    let todoList = [];
    todaysTodos.forEach((todo, index) => {
        // check if user has accepted the todo
        const acceptedCheck = todo.users.find(userObject => userObject.isAccepted === "accepted" && userObject.userId === user.id);
        if (acceptedCheck) {
            const date = timeFormatter(todo.date);
            const todoActive = dateRes(todo.date) > dateNow();
            const isCompleted = todo.isComplete;
            todoList.push(<div key={'dashboardTodoListItem' + index} className="dashboardtodolist-item">
                <div className="flex flex-align--center">
                    <div className="dashboardtodolist-item-status">
                        <div className="dashboardtodolist-item-status-icon">
                            {isCompleted ?
                                <SentimentVerySatisfied style={{ color: 'var(--color--lightgreen)' }} />
                                : todoActive ? <SentimentSatisfiedAlt />
                                    : <SentimentVeryDissatisfied style={{ color: 'var(--color--orange)' }} />}
                        </div>
                        <span className={`font-12 ${isCompleted ? "color--lightgreen" : todoActive ? "" : "color--orange"}`}>{isCompleted ? "Fuldført" : todoActive ? "Kommende" : "Overskredet"}</span>
                    </div>
                    <div>
                        <div className="text-left font-14">{todo.title}</div>
                        <div className={`${todoActive || isCompleted ? isCompleted ? "opacity--50" : "color--lightgreen" : "color--orange"} text-left font-18`}>{date ? date : "Opgaven er overskredet!"}</div>
                    </div>
                </div>
                <div className="dashboardtodolist-link-icon"><ArrowForwardIos /></div>
            </div>);
        }
    })


    return (
        <div className="dashboard-item line">
            <div className="dashboardtodolist-container">
                <div className="header-title text-left">Dagens opgaver</div>
                {todoList.length > 0
                    ? <Link className="anchor-no-line" to={`${process.env.PUBLIC_URL}/alltodos`} onClick={() => dispatch(setCalendarDate(today))}>
                        {todoList}
                    </Link>
                    : <div className="flex flex-align--center flex--column">
                        <div className="flex dashboarditem-completed-icon"><SentimentVerySatisfied style={{ color: 'var(--color--lightgreen)' }} /></div>
                        <div className="flex">
                            <span className="text-left font-14 opacity--50">Du har ingen opgaver idag</span>
                        </div>
                        <Link className="flex anchor-no-line margin-s--tb" to={`${process.env.PUBLIC_URL}/alltodos`} onClick={() => dispatch(setCalendarDate(today))}>
                            <Button className="text-left font-14" text="Din kalender" type="add" />
                        </Link>
                    </div>
                }

            </div>
        </div>
    )
}

export default dashboardTodoList;