import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import & init() Firebase - Auth/Login/PushNotifications etc.
import "../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Services
import ApiService from "../../services/ApiService";
import { getCookie } from "../../services/FunctionHelper.tsx";

// Components
import Routes from "../../routes/Routes";
import LoginRoutes from "../../routes/RoutesLogin";
import { NotificationContainer } from "react-notifications";
import Spinner from "../spinner/Spinner";

// Store
import { setIsLoading } from "../../store/features/spinner";

// Global SCSS
import "react-notifications/lib/notifications.css"; // import global style for notifcations
import "../../globals/style/_globals.scss";

function App() {
  /* Store */
  const dispatch = useDispatch();
  const spinnerStore = useSelector((state) => state.spinner);
  const spinnerState = spinnerStore.isLoading;
  /* Initialize states */
  const [pageLoaded, setPageLoaded] = useState(false);
  const [accessPermitted, setAccessPermitted] = useState(false);
  /* Firebase */
  const auth = getAuth();

  useEffect(() => {
    dispatch(setIsLoading(true));
    try {
      const getAuthUid = getCookie("auth-uid");
      const getJWTtoken = getCookie("tokenJWT");
      const getUserEmail = getCookie("user-email");
      const isAccessPermitted = getJWTtoken !== null && getUserEmail !== null;

      // If JWT token && user-email is still valid then it's OK to enter
      if (isAccessPermitted) {
        document.documentElement.setAttribute("data-loggedin", `true`);
        setAccessPermitted(true);
        setPageLoaded(true);
      } else if (getUserEmail !== null && getAuthUid !== null) {
        // Else we try to refresh the JWTtoken and it's done by refreshing the login Auth-token from Firebase (it only life for 1 hour). When it's refreshed successfully then we try to refresh the JWT
        // We use the Auth-token as Authorization in Backend to validate if the User is eligible to fetch a new token.
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const refreshedAuthToken = await refreshAuthToken(user);
            if (refreshedAuthToken) {
              const token = await ApiService.refreshJWT(
                getUserEmail,
                getAuthUid,
                refreshedAuthToken
              );
              if (token) {
                document.documentElement.setAttribute("data-loggedin", `true`);
                setAccessPermitted(true);
              } else {
                setAccessPermitted(false);
              }
            }
          }
          dispatch(setIsLoading(false));
          setPageLoaded(true);
        });
      } else {
        // Show login screen
        setAccessPermitted(false);
        dispatch(setIsLoading(false));
        setPageLoaded(true);
      }
    } catch (err) {
      console.log("error fetching token", err);
      dispatch(setIsLoading(false));
    }
  }, []);

  const refreshAuthToken = async (user) => {
    if (user) {
      try {
        const authToken = await user.getIdToken(true); // Force refresh the token
        return authToken;
      } catch (error) {
        console.error("Error refreshing auth token", error);
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {pageLoaded && (accessPermitted ? <Routes /> : <LoginRoutes />)}
        {spinnerState && <Spinner />}
        <NotificationContainer />
      </header>
    </div>
  );
}

export default App;
