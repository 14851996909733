import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// API service - for TodoItem
import ApiService from './ApiService/ApiService';
// Services
import { TODO_STATUS } from '../../../services/Enums';
// Components
import UserItem from '../../../components/listItem/userItem';
import SubItem from '../../../components/listItem/subItem';
import IconButton from '../../../components/button/iconButton';
import ButtonField from '../../../components/inputField/buttonField';
import DeleteModal from '../modal/todoDelete/TodoDelete';
import EditModal from '../modal/todoEdit';
import Icon from '../../icon/';
// Icons
import { Add, Check, Circle, Create, Refresh, DeleteForever, ExpandMore, ExpandLess, Close } from '@mui/icons-material';
// Utilities
import { dateFormatter, timeFormatter, dateNow, dateRes } from '../../../services/DateHandler.jsx';
import { reminderOptionsText } from '../../../services/FunctionHelper.tsx';
import cloneDeep from 'lodash/cloneDeep';
// Store
import { setIsLoading } from '../../../store/features/spinner';
import {
    getTodolistPending,
    getWorklistPending,
    getBuylistPending,
    getEventlistPending,
    getActivitylistPending,
    getFoodplanlistPending,
} from '../../../store/features/notifications.ts';

// Style
import './TodoItem.scss';

const TodoItem = (props) => {
    /* Intialize writing Store */
    const dispatch = useDispatch();
    /* store */
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    const notificationsStore = useSelector((state) => state.notifications);
    const notifications = notificationsStore.todoNotifications;
    /* data */
    const { index, object, canEdit, isPending, userObject } = props
    const { id, title, date, pushReminderDate, description, isPrivate, isAutoComplete, isComplete, users, subitems } = object;
    /* functions */
    const { updateTodoList } = props

    const [userConnected, setUserConnected] = useState(false); // Checks if user is connected to the todo
    const [expandUsers, setExpandUsers] = useState(true);
    const [expandDisc, setExpandDisc] = useState(false);
    const [expandSubitems, setExpandSubitems] = useState(true);
    const [showCreateSubitem, setShowCreateSubitem] = useState(false);
    const [subitemInput, setSubitemInput] = useState("");
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [todoStatus, setTodoStatus] = useState("active");
    const [todoPending, setTodoPending] = useState(false);

    useEffect(() => {
        getTodoStatus();
        setTodoPending(userObject ? userObject.isAccepted === "pending" : false);
        setUserConnected(users.find(todoUser => todoUser.userId === user.id));
    });

    /**
     * Check the status of the todo and set the State based on isComplete + todays date.
     */
    const getTodoStatus = () => {
        let todoStatus = isComplete ? "complete" : "active";
        const now = dateNow();
        const curDate = dateRes(date);

        if (now > curDate && todoStatus !== "complete") {
            todoStatus = "exceeded";
        }
        setTodoStatus(todoStatus);
    }

    const spinnerActivator = () => {
        dispatch(setIsLoading(true));
    }

    const completeTodo = () => {
        const cloneObject = cloneDeep(object);
        cloneObject.isComplete = 1;
        updateTodoList(index, TODO_STATUS.update, cloneObject);
        dispatch(setIsLoading(false));
    }

    const uncompleteTodo = () => {
        const cloneObject = cloneDeep(object);
        cloneObject.isComplete = 0;
        updateTodoList(index, TODO_STATUS.update, cloneObject);
        dispatch(setIsLoading(false));
    }

    const deleteTodo = () => {
        updateTodoList(index, TODO_STATUS.delete);
        setDeleteModalState(!deleteModalState);
        dispatch(setIsLoading(false));
    }

    const acceptTodo = (resTodoItem, status) => {
        updateTodoList(index, TODO_STATUS.update, resTodoItem);
        let todoAccepted = false;
        if (status === "accepted") todoAccepted = true;
        setTodoPendingBadge(resTodoItem.type, todoAccepted);
        dispatch(setIsLoading(false));
    }

    const completeSubitem = (subitemIndex) => {
        const cloneObject = cloneDeep(object);
        cloneObject.subitems[subitemIndex].isComplete = 1;
        updateTodoList(index, TODO_STATUS.update, cloneObject, true);
        dispatch(setIsLoading(false));
    }

    const uncompleteSubitem = (subitemIndex) => {
        const cloneObject = cloneDeep(object);
        cloneObject.subitems[subitemIndex].isComplete = 0;
        updateTodoList(index, TODO_STATUS.update, cloneObject, true);
        dispatch(setIsLoading(false));
    }

    const deleteSubitem = (subitemIndex) => {
        const cloneObject = cloneDeep(object);
        cloneObject.subitems.splice(subitemIndex, 1);
        updateTodoList(index, TODO_STATUS.update, cloneObject, true);
        dispatch(setIsLoading(false));
    }

    const setTodoPendingBadge = (type, todoAccepted) => {
        const amount = todoAccepted === "accepted" ? 1 : -1; //Add 1 or extract 1 from current value
        switch (type) {
            case "todo":
                dispatch(getTodolistPending(notifications.todolistPending + amount));
                break;
            case "work":
                dispatch(getWorklistPending(notifications.worklistPending + amount));
                break;
            case "shop":
                dispatch(getBuylistPending(notifications.buylistPending + amount));
                break;
            case "event":
                dispatch(getEventlistPending(notifications.eventlistPending + amount));
                break;
            case "sport":
                dispatch(getActivitylistPending(notifications.activitylistPending + amount));
                break;
            case "food":
                dispatch(getFoodplanlistPending(notifications.foodplanlistPending + amount));
                break;
        }
    }

    /* Makes it possible to create an subitem by pressing enter */
    const handleOnEnter = (e) => {
        if (e.key === 'Enter') {
            createSubitem();
        }
    }

    const setSubitemInputValue = (value) => {
        setSubitemInput(value);
        document.getElementById(`subitem-input-error${id}`).classList.add("hidden");
    }

    const closeSubitem = () => {
        setShowCreateSubitem(false);
        setSubitemInput("");
    }

    const createSubitem = () => {
        if (subitemInput) {
            ApiService.createSubitem(id, subitemInput, (res) => {
                const subitem = res;
                const cloneObject = cloneDeep(object);
                cloneObject.subitems.push(subitem);
                updateTodoList(index, TODO_STATUS.update, cloneObject, true);
                setShowCreateSubitem(false);
                setSubitemInput("");
                dispatch(setIsLoading(false));
            })
            spinnerActivator();
        } else {
            document.getElementById(`subitem-input-error${id}`).classList.remove("hidden");
        }
    }

    const todoStatusClass = (todoStatus) => {
        switch (todoStatus) {
            case "complete":
                return "opacity--50";
            case "exceeded":
                return "color--warning";
            default:
                return "color--approve";
        }
    }

    const todoStatusText = (todoStatus) => {
        switch (todoStatus) {
            case "complete":
                return "Fuldført";
            case "exceeded":
                return "Overskredet";
            default:
                return "";
        }
    }

    const paintCompleteBtns = () => {
        let content = null;
        /* if autoComplete and date havent passed currentTime */
        if (isComplete && isAutoComplete && dateRes(date) > dateNow()) {
            content = <IconButton
                className="color--cyan-hover"
                icon={<Refresh />}
                text={"Genaktiver"}
                onClick={() => { ApiService.uncompleteTodo(id, uncompleteTodo); spinnerActivator() }}
            />
            /* if autoComplete and completed and currentTime has passed then no disabled btn */
        } else if (isComplete && isAutoComplete) {
            content = <IconButton
                className="color--cyan-hover"
                icon={<Check />}
                disabled={true}
                text={"Automatisk"}
            />
            /* If isComplete but no isAutoComplete */
        } else if (isComplete) {
            content = <IconButton
                className="color--cyan-hover"
                icon={<Refresh />}
                text={"Genaktiver"}
                onClick={() => { ApiService.uncompleteTodo(id, uncompleteTodo); spinnerActivator() }}
            />
        } else {
            content = <IconButton
                className="color--approve-hover"
                icon={<Check />}
                text={"Afsluttet"}
                onClick={() => { ApiService.completeTodo(id, completeTodo); spinnerActivator() }}
            />
        }
        return content;
    }

    let userList = [];
    if (users) {
        users.forEach(user => {
            userList.push(<div key={`connectedUser ${user.userId}`} className="flex">
                <UserItem
                    name={`${user.firstname} ${user.lastname}`}
                    picture={user.picture}
                    pictureSize="small"
                    itemStatus={user.isAccepted}
                />
            </div>)
        })
    }

    let subitemList = [];
    let completedSubitems = 0;
    if (subitems) {
        subitems.forEach((item, index) => {
            if (item.isComplete) completedSubitems++;
            subitemList.push(<div key={`subitem-${item.id}`} className="flex">
                <SubItem
                    canEdit={canEdit}
                    subitem={item}
                    completeSubitem={() => { ApiService.completeSubitem(index, id, item.id, completeSubitem); spinnerActivator() }}
                    uncompleteSubitem={() => { ApiService.uncompleteSubitem(index, id, item.id, uncompleteSubitem); spinnerActivator() }}
                    deleteSubitem={() => { ApiService.deleteSubitem(index, id, item.id, deleteSubitem); spinnerActivator() }}
                />
            </div>)
        });
    }

    return (
        <div className={`todo-item 
            ${isComplete ? "completed" : ""} 
            ${userConnected ? "" : "user-not-connected"} 
            ${isPending ? "pending" : ""}
        `}>
            <div className="todo-item-wrapper padding-s--t padding-s--rl text-left">
                {isPending && <div className='flex flex-align--center'>
                    <Icon className="padding-xs--r" color="var(--color--orange)" width="12px" height="22px"><Circle /></Icon>
                    <span>Afventer dit svar</span>
                </div>}

                <div className="todo-item-title flex flex-justify--between flex-align--stretch flex-align--center">
                    <div><p className="no-margin">{title}</p></div>
                    {canEdit && !todoPending && <div>
                        <IconButton
                            icon={<Create style={{ fontSize: 20 }} />}
                            onClick={() => setEditModalState(!editModalState)}
                        />
                    </div>}
                </div>
                <div className="todo-item-row flex flex-justify--between margin-m--b">
                    <div className="flex flex-justify--between flex-align--stretch flex-align--start text-left">
                        <div>{description}</div>
                    </div>
                </div>
                <div className="todo-item-row flex flex-justify--between">
                    <div className="todo-item-subtitle">Dato</div>
                    <div>
                        <div className="flex flex--column text-right">
                            <span>{dateFormatter(date)}</span>
                            <span className={todoStatusClass(todoStatus)}>{timeFormatter(date)}</span>
                            <span className={todoStatusClass(todoStatus)}>{todoStatusText(todoStatus)}</span>
                        </div>
                    </div>
                </div>
                <div className="todo-item-row flex flex-justify--between">
                    <div className="todo-item-subtitle">Påmindelse</div>
                    <div>
                        {reminderOptionsText(date, pushReminderDate)}
                    </div>
                </div>
                <div className="todo-item-row flex flex-justify--between">
                    <div className="todo-item-subtitle">Afsluttes automatisk</div>
                    <div>
                        {isAutoComplete ? "Ja" : "Nej"}
                    </div>
                </div>
                {/* isPrivate disabled until sharing Calendar gets made - every todo made will be private until then for privacy
                <div className="todo-item-row flex flex-justify--between">
                    <div className="todo-item-subtitle">Privat</div>
                    <div>
                        {isPrivate ? "Ja" : "Nej"}
                    </div>
                </div>
                */}
                <div className="todo-item-row flex flex-justify--between flex-align--center">
                    <div className="todo-item-subtitle">Tilknyttet</div>
                    <div>
                        <div className="row flex-align--center">
                            {userList.length}
                            <IconButton
                                className="color--selected-hover todo-item-svg"
                                icon={expandUsers ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => setExpandUsers(!expandUsers)}
                            />
                        </div>
                    </div>
                    <div className="todo-item-row">
                        {expandUsers && <div className="full-width">{userList}</div>}
                    </div>
                </div>
                <div className="todo-item-row flex flex-justify--between flex-align--center">
                    <div className="todo-item-subtitle">Underopgaver</div>
                    <div>
                        <div className="row flex-align--center">
                            {completedSubitems} / {subitemList.length}
                            <IconButton
                                className="color--yellow todo-item-svg"
                                icon={expandSubitems ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => setExpandSubitems(!expandSubitems)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {expandSubitems && <div className="full-width">
                            {canEdit && !todoPending &&
                                <div className="flex margin-m--b margin-xs--t">
                                    {showCreateSubitem ? <div className="flex flex--column full-width">
                                        <ButtonField
                                            id={`subitem-input${id}`}
                                            className="font-14"
                                            type="text"
                                            placeholder="Indtast beskrivelse"
                                            maxLength="50"
                                            value={subitemInput}
                                            autoFocus={true}
                                            onChange={(value) => setSubitemInputValue(value.target.value)}
                                            onKeyPress={(e) => handleOnEnter(e)}
                                            icon={subitemInput.length > 0 ? <Check /> : <Close />}
                                            onClick={() => subitemInput.length > 0 ? createSubitem() : closeSubitem()}
                                        />
                                        <span id={`subitem-input-error${id}`} className="hidden font-12 color--warning">&nbsp;(Opgavens titel må ikke være tom)</span>
                                        <span className="font-13 color--graa4">Tegn: {subitemInput.length} / 50</span>
                                    </div> : <IconButton
                                        className="color--approve-hover"
                                        icon={<Add style={{ color: 'var(--color--approve)' }} />}
                                        text={"Tilføj"}
                                        onClick={() => setShowCreateSubitem(true)}
                                    />}
                                </div>
                            }
                            <div>
                                {subitemList}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {canEdit && !todoPending &&
                <div className="row flex flex-justify--around margin-xs--t padding-xs--tb line-t">
                    {paintCompleteBtns()}
                    <IconButton
                        className="color--delete-hover"
                        iconRight={<DeleteForever />}
                        text={"Slet"}
                        onClick={() => setDeleteModalState(!deleteModalState)} />
                </div>
            }
            {todoPending &&
                <div className="row flex flex-justify--around margin-xs--t padding-xs--tb line-t">
                    <IconButton
                        className="color--approve-hover"
                        icon={<Check />}
                        text={"Accepter"}
                        onClick={() => { ApiService.acceptTodo(id, title, userObject, acceptTodo, "accepted"); spinnerActivator() }}
                    />
                    <IconButton
                        className="color--delete-hover"
                        iconRight={<Close />}
                        text={"Afvis"}
                        onClick={() => { ApiService.acceptTodo(id, title, userObject, acceptTodo, "declined"); spinnerActivator() }} />
                </div>
            }
            <DeleteModal
                title={title}
                state={deleteModalState}
                setState={() => setDeleteModalState(!deleteModalState)}
                onClick={() => { ApiService.deleteTodo(object, deleteTodo); spinnerActivator() }}
            />
            <EditModal
                todoIndex={index}
                state={editModalState}
                setState={() => setEditModalState(!editModalState)}
                todoObject={object}
                updateTodoList={updateTodoList}
            />
        </div>
    )
}

export default TodoItem;
