import { createSlice, current } from "@reduxjs/toolkit";

// Services
import { dateNow, dateRes } from "../../services/DateHandler";
import Moment from "moment";

const getTodaysTodo = (todolist) => {
  const todayslist = [];
  const now = dateNow();
  if (todolist) {
    todolist.forEach((item) => {
      let date = dateRes(item.date);
      const today =
        Moment(now).format("YYYY-MM-DD") === Moment(date).format("YYYY-MM-DD"); // If today then add data to the list
      if (today) {
        todayslist.push(item);
      }
    });
    return todayslist;
  }
  return;
};

export const todoitemsSlice = createSlice({
  name: "todoitems",
  initialState: {
    todoitemsLoaded: false,
    all: [],
    todolist: [],
    worklist: [],
    buylist: [],
    eventlist: [],
    activitylist: [],
    foodplanlist: [],
    todayslist: [],
    dateCache: [],
    calendarDate: new Date().setHours(0, 0, 0),
  },
  reducers: {
    setTodoitems: (state, action) => {
      if (!action.payload) return;
      const all = [];
      const todolist = [];
      const worklist = [];
      const buylist = [];
      const eventlist = [];
      const activitylist = [];
      const foodplanlist = [];
      const todayslist = getTodaysTodo(action.payload);
      action.payload.forEach((todo) => {
        all.push(todo);
        switch (todo.type) {
          case "todo":
            todolist.push(todo);
          case "work":
            worklist.push(todo);
          case "shop":
            buylist.push(todo);
          case "event":
            eventlist.push(todo);
          case "sport":
            activitylist.push(todo);
          case "food":
            foodplanlist.push(todo);
        }
      });
      state.todoitemsLoaded = true; // Makes sure first load is from Router.jsx to prevent overwritten data cause of async loading - in Todo.jsx in useEffect check by todoitems.firstInit
      state.all = all;
      state.todolist = todolist;
      state.worklist = worklist;
      state.buylist = buylist;
      state.eventlist = eventlist;
      state.activitylist = activitylist;
      state.foodplanlist = foodplanlist;
      state.todayslist = todayslist;
    },
    setDateCache: (state, action) => {
      state.dateCache.push(action.payload);
      // console.log("dateCache", current(state.dateCache));
    },
    setCalendarDate: (state, action) => {
      state.calendarDate = action.payload;
    },
    clearDateCache: (state, action) => {
      state.dateCache = [];
      // console.log("dateCache", current(state.dateCache));
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTodoitems, setDateCache, setCalendarDate, clearDateCache } =
  todoitemsSlice.actions;

export default todoitemsSlice.reducer;
