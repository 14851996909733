import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Services
import axios from 'axios';
import AuthService from '../../../services/Auth';

// Components - Material UI
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

// Components
import { NotificationManager } from 'react-notifications';
import Button from '../../button/button';
import InputField from '../../inputField';

// Icons
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

// Store
import { setFriendlist } from '../../../store/features/friendlist';

// Style
import './DashboardAddFriend.scss';

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const DashboardAddFriend = (props) => {
    /* Store */
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    /* props */
    const { } = props
    /* Initialize states */
    const [userTag, setUserTag] = useState('');
    const [modalAddFriend, setModalAddFriend] = useState(false);

    const showAddFriendModal = () => {
        setModalAddFriend(!modalAddFriend);
        setUserTag("");
    }

    const onClickAddFriend = () => {
        const splitUserTag = userTag.split("#");
        const firstname = splitUserTag[0];
        const friendId = parseInt(splitUserTag[1]);

        const data = {
            firstname: firstname,
            friendId: friendId,
            fullname: `${user.firstname} ${user.lastname}`
        }

        if (friendId && friendId !== user.id) {
            axios.post(`${process.env.REACT_APP_API_URL}/user/addfriend`, data, AuthService.config())
                .then((response) => {
                    if (response.data === false) {
                        NotificationManager.warning('Ugyldigt profil-ID');
                    } else if (response.data === "friendAddedByMe") {
                        NotificationManager.warning(`Du har allerede tilføjet ${userTag} til din kontaktliste`);
                    } else if (response.data === "friendAddedMe") {
                        NotificationManager.warning(`${userTag} har allerede tilføjet dig som kontakt`);
                    } else {
                        NotificationManager.success(`${userTag} har modtaget din anmodning`);
                        showAddFriendModal();
                        dispatch(setFriendlist(response.data));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    NotificationManager.warning('Kan ikke slette denne kontakt på nuværende tidspunkt.', 'Fejl!');
                });
        } else {
            NotificationManager.warning('Ugyldigt profil-ID.');
        }
    }

    return (
        <div className="dashboard-item-addfriend line">
            <div>
                <div className="header-title">Profil-ID</div>
                <div className="font-14">{user.firstname}#{user.id}</div>
            </div>
            <div className="flex flex-align--center pointer" onClick={() => showAddFriendModal()}>
                <span className="font-14 margin-s--r">Tilføj kontakt</span>
                <Button
                    type="circle"
                    width={30}
                    height={30}
                    className="add"
                    icon={<AddIcon />}
                />
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={'structio-modal-container'}
                open={modalAddFriend}
                onClose={showAddFriendModal}
                closeAfterTransition
            >
                <Fade in={modalAddFriend}>
                    <div className="structio-modal-box">
                        <button className="icon-btn close-modal-icon" type="button" onClick={showAddFriendModal}><ClearIcon /></button>
                        <h1 className="modal-title no-margin">Tilføj ny kontakt</h1>
                        <div className="row align-items-center line padding-m--t margin-m--b">
                            <div className="row align-items-center">
                                <div className="col font-14 margin-m--b">
                                    <div className="header-title">Dit profil-ID er:
                                        <span className="color--text margin-xs--l">{user.firstname}#{user.id}</span>
                                    </div>
                                    <div>
                                        <div className="header-title">Kontakter kan invitere hinanden til opgaver.</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <span className="color--text">Indtast personens Profil-ID</span>
                                </div>
                                <div className="col">
                                    <InputField className="color--structio" type="text" placeholder="Navn#ID" value={userTag} onChange={(event) => setUserTag(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col flex align-items-center padding-s">
                                <Button
                                    className="icon-btn"
                                    onClick={showAddFriendModal}
                                    text={'Annuller'}
                                />
                            </div>
                            <div className="col flex align-items-center padding-s">
                                <Button
                                    className="icon-btn add"
                                    onClick={() => onClickAddFriend()}
                                    text={'Tilføj'}
                                />
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default DashboardAddFriend;