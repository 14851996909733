import React from 'react';

import Button from '@mui/material/Button';

// Components

// Icons

// Style
import './MenuButton.scss';

/** @ViableTypes for props
 ** height - ex 10px - sets the height of the button - default 25px;
 ** text - String: Provides a text to the icon
 ** icon - Component: Provides a icon to the button !required
 ** onClick - Func: Adds a functions to the button
 ** disabled - Boolean: true disables the button
 */

const MenuButton = (props) => {
    /* data */
    const { text, icon, disabled, height } = props
    /* functions */
    const { onClick } = props

    return (
        <Button className={`menu-button`} style={height ? { height: `${height}` } : { height: "25px" }} disabled={disabled ? "disabled" : null} onClick={onClick}>
            <div className="col">
                <span>{icon}</span>
                <span className="standard-btn-text">{text}</span>
            </div>
        </Button>
    )
}

export default MenuButton;