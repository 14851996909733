import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Services
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { setCookie } from '../../../services/FunctionHelper.tsx';
import ApiService from '../../../services/ApiService';

// Store
import { setIsLoading } from '../../../store/features/spinner';

// Components
import InputField from '../../../components/inputField/';
import Button from '../../../components/button/button/';
import StructioLogo from '../../../assets/default_images/structio-logo-with-text-alfa.png';

// Icons


// Style

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const Login = (props) => {
  /* Store */
  const dispatch = useDispatch();
  /* Data */
  const { } = props
  /* Functions */
  const { redirect } = props
  /* Initialize states */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    // If backend fails the user will be prompted back to login screen with a fail-text message.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const loginStatus = urlParams.get('login')
    if (loginStatus === "failed") {
      document.documentElement.setAttribute('data-loggedin', `failed`);
    }
  }, []);

  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      login(e, email, password);
    }
  }

  const onChangeEmail = (value) => {
    setEmail(value);
    document.getElementById("email-input-field").classList.remove("input-error");
    document.getElementById("email-input-error").className = "hidden";
  }

  const onChangePassword = (value) => {
    setPassword(value);
    document.getElementById("password-input-field").classList.remove("input-error");
    document.getElementById("password-input-error").className = "hidden";
  }

  const login = (event, email, password) => {
    if (email !== "" && password !== "") {
      dispatch(setIsLoading(true));
      event.preventDefault();
      grecaptcha.ready(function () {
        grecaptcha.execute(`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, { action: 'submit' }).then(function (token) {
          const authentication = getAuth();
          signInWithEmailAndPassword(authentication, email, password).then(async (userCredential) => {
            const user = userCredential.user;
            const authToken = await user.getIdToken(); // Get Firebase Auth Token
            const authUid = user.uid; // Get User UID
            setCookie('auth-uid', authUid, 180); /* Sets to 180 day lifetime because of backend has the same value - Refresh token */
            setCookie('user-email', email, 180); /* Sets to 180 day lifetime because of backend has the same value - Refresh token */
            await ApiService.createJWT(email, authUid, authToken);
            window.location.reload();
          }).catch((error) => {
            if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
              setEmailError('Forkert email eller adgangskode');
              document.getElementById("email-input-field").classList.add("input-error");
              document.getElementById("email-input-error").className = "color--warning font-12 show";
            }
            if (error.code === 'auth/invalid-email') {
              setEmailError('Ugyldig email');
              document.getElementById("email-input-field").classList.add("input-error");
              document.getElementById("email-input-error").className = "color--warning font-12 show";
            }
            dispatch(setIsLoading(false));
            console.error(error);
            return
          });
        });
      });
    } else {
      if (email === "") {
        setEmailError('Ugyldig email');
        document.getElementById("email-input-field").classList.add("input-error");
        document.getElementById("email-input-error").className = "color--warning font-12 show";
      }
      if (password === "") {
        setPasswordError('Adgangskoden må ikke være tom');
        document.getElementById("password-input-field").classList.add("input-error");
        document.getElementById("password-input-error").className = "color--warning font-12 show";
      }
    }

  }

  return (
    <div className="login-form">
      <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--b">
        <img src={StructioLogo} alt="Structio Logo" style={{ maxWidth: 220 + 'px' }} />
      </div>
      <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--tb">
        <h1 className="color--text no-margin">Log ind</h1>
        <p className="login-failed color--red no-margin hidden">Der skete en teknisk fejl - vi beklager!</p>
      </div>
      <div className="flex flex--column">
        <span id="login-email" className="title-text font-14">Email</span>
        <InputField id="email-input-field" className="font-16 line" type="email" placeholder="Indtast email" autocomplete={true} maxLength="50" value={email} onChange={(value) => onChangeEmail(value.target.value)} onKeyPress={(e) => handleOnEnter(e)} />
        <span id="email-input-error" className="hidden font-12 color--warning">{emailError}</span>
      </div>
      <div className="flex flex--column margin-m--t">
        <span id="login-password" className="title-text font-14">Adgangskode</span>
        <InputField id="password-input-field" className="font-16 line" type="password" placeholder="Indtast adgangskode" maxLength="50" value={password} onChange={(value) => onChangePassword(value.target.value)} onKeyPress={(e) => handleOnEnter(e)} />
        <span id="password-input-error" className="hidden font-12 color--warning">{passwordError}</span>
        <span id="password-recover" className="font-12 color--graa7">Glemt din adgangskode - <span className="link" onClick={() => redirect("recover")}>Klik her</span></span>
      </div>
      <div className="col text-center padding-m--t">
        <Button
          text={'Log ind'}
          onClick={(e) => login(e, email, password)}
        />
        <Button
          className="login-btn margin-s--t"
          text={'Opret profil'}
          onClick={() => redirect("signup")}
          type="add"
        />
      </div>
    </div>
  )
}

export default Login;
