import React, { useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
// Services
import * as InputValidator from '../../services/InputValidator';
import AuthService from '../../services/Auth';
import { genderOptions } from '../../services/FunctionHelper.tsx';
// Components - Material UI
import Fade from '@mui/material/Fade';
import Switch from '@mui/material/Switch';
// Components
import InputField from '../../components/inputField/';
import Button from '../../components/button/button/';
import StructioLogo from '../../assets/default_images/structio-logo-with-text-alfa.png';
import Select from 'react-select';
// Stores
import { setTheme } from "../../store/features/theme.ts";
// Icons
import { WbSunny, Brightness2 } from '@mui/icons-material';
// Style
import './Welcome.scss';

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const Welcome = (props) => {
    /* Store */
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    const themeStore = useSelector((state) => state.theme);
    const themeState = themeStore.theme;
    /* Initialize states */
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [city, setCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [birth, setBirth] = useState(new Date().getFullYear());
    const [gender, setGender] = useState("anonymous");


    const onChangeFirstname = (value) => {
        setFirstname(value);
        document.getElementById("firstname-input-field").classList.remove("input-error");
        document.getElementById("firstname-input-error").className = "hidden";
    }

    const onChangeLastname = (value) => {
        setLastname(value);
        document.getElementById("lastname-input-field").classList.remove("input-error");
        document.getElementById("lastname-input-error").className = "hidden";
    }

    const onChangeCity = (value) => {
        setCity(value);
        document.getElementById("city-input-field").classList.remove("input-error");
        document.getElementById("city-input-error").className = "hidden";
    }

    const onChangeZipcode = (value) => {
        setZipcode(value);
        document.getElementById("zipcode-input-field").classList.remove("input-error");
        document.getElementById("zipcode-input-error").className = "hidden";
    }

    const validateForm = () => {
        let res = true;
        let resFirstname = InputValidator.name(firstname);
        let resLastname = InputValidator.name(lastname);
        let resCity = city.length > 0 ? InputValidator.name(city) : true;
        let resZipcode = zipcode.length > 0 ? InputValidator.zip(zipcode) : true;

        if (!resFirstname) {
            document.getElementById("firstname-input-field").classList.add("input-error");
            document.getElementById("firstname-input-error").className = "color--warning font-12 show";
            document.getElementById("firstname-input-field").scrollIntoView({ behavior: "smooth" });
            res = false;
        }
        if (!resLastname) {
            document.getElementById("lastname-input-field").classList.add("input-error");
            document.getElementById("lastname-input-error").className = "color--warning font-12 show";
            document.getElementById("lastname-input-field").scrollIntoView({ behavior: "smooth" });
            res = false;
        }
        if (!resCity) {
            document.getElementById("city-input-field").classList.add("input-error");
            document.getElementById("city-input-error").className = "color--warning font-12 show";
            document.getElementById("city-input-field").scrollIntoView({ behavior: "smooth" });
            res = false;
        }
        if (!resZipcode) {
            document.getElementById("zipcode-input-field").classList.add("input-error");
            document.getElementById("zipcode-input-error").className = "color--warning font-12 show";
            document.getElementById("zipcode-input-field").scrollIntoView({ behavior: "smooth" });
            res = false;
        }
        return res;
    }

    const saveProfile = () => {
        if (validateForm()) {
            const data = {
                firstname: firstname,
                lastname: lastname,
                email: user.email,
                zip: zipcode,
                city: city,
                birth: birth,
                gender: gender
            };

            axios.put(`${process.env.REACT_APP_API_URL}/user/update`, data, AuthService.config())
                .then((response) => {
                    // handle success
                    window.location.reload();
                })
                .catch(() => {
                    // Use error in .catch() for debugging
                    // console.error(error);
                    NotificationManager.warning('Kunne ikke gemme profil informationerne', 'Fejl!');
                });
        }
    }

    // Get todoTypeIndex from OptionsList
    const birthOptions = [];
    let age = new Date().getFullYear(); // Count 100 down this case 1920
    for (let i = 0; i < 101; i++) {
        const currentAge = age--;
        birthOptions.push({ value: currentAge, label: <span className="todo-type-select-item">{currentAge}</span> });
    }
    let birthOptionIndex = 0;
    birthOptions.forEach((item, index) => {
        if (birth.toString() === item.value.toString()) {
            birthOptionIndex = index;
        }
    });

    let genderOptionIndex = 0;
    genderOptions.forEach((item, index) => {
        if (gender === item.value) {
            genderOptionIndex = index;
        }
    });
    return (
        <Fade in timeout={1000}>
            <div className="welcome-container">
                <div className="welcome-background">
                    <div className="bg-effect"></div>
                    <div className="bg-picture"></div>
                </div>
                <div className="welcome-content">
                    <div className="welcome-form">
                        <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--b">
                            <img src={StructioLogo} alt="Structio Logo" style={{ maxWidth: 220 + 'px' }} />
                        </div>
                        <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--tb">
                            <h1 className="color--text no-margin">Velkommen!</h1>
                            <p className="color--text no-margin text-center color--graa6">Så mangler vi bare lidt profil informationer for at kunne komme igang.</p>
                        </div>
                        <div className="flex flex--column margin-m--t">
                            <span className="color--text font-14">Fornavn*</span>
                            <InputField id="firstname-input-field" className="font-16 line" type="text" placeholder="Indtast fornavn" maxLength="50" value={firstname} onChange={(value) => onChangeFirstname(value.target.value)} />
                            <span id="firstname-input-error" className="hidden font-12 color--warning">&nbsp;(Ugyldigt fornavn)</span>
                        </div>
                        <div className="flex flex--column margin-m--t">
                            <span className="color--text font-14">Efternavn*</span>
                            <InputField id="lastname-input-field" className="font-16 line" type="text" placeholder="Indtast efternavn" maxLength="50" value={lastname} onChange={(value) => onChangeLastname(value.target.value)} />
                            <span id="lastname-input-error" className="hidden font-12 color--warning">&nbsp;(Ugyldigt efternavn)</span>
                        </div>
                        <div className="flex flex--column margin-m--t">
                            <span className="color--text font-14">By <span className="color--graa5">(valgfri)</span></span>
                            <InputField id="city-input-field" className="font-16 line" type="text" placeholder="Angiv by" maxLength="50" value={city} onChange={(value) => onChangeCity(value.target.value)} />
                            <span id="city-input-error" className="hidden font-12 color--warning">&nbsp;(Ugyldigt bynavn)</span>
                        </div>
                        <div className="flex flex--column margin-m--t">
                            <span className="color--text font-14">Post nr. <span className="color--graa5">(valgfri)</span></span>
                            <InputField id="zipcode-input-field" className="font-16 line" type="text" placeholder="Angiv post nr." maxLength="50" value={zipcode} onChange={(value) => onChangeZipcode(value.target.value)} />
                            <span id="zipcode-input-error" className="hidden font-12 color--warning">&nbsp;(Ugyldigt post nr.)</span>
                        </div>
                        <div className="flex flex--column margin-m--t">
                            <span className="color--text font-14">Fødselsår <span className="color--graa5">(valgfri)</span></span>
                            <Select
                                className="select-structio-dropdown line"
                                classNamePrefix="select-structio-dropdown"
                                value={birthOptions[birthOptionIndex]}
                                onChange={(birthYear) => setBirth(birthYear.value)}
                                options={birthOptions}
                                isSearchable={false}
                            />
                        </div>
                        <div className="flex flex--column margin-m--t">
                            <span className="color--text font-14">Køn <span className="color--graa5">(valgfri)</span></span>
                            <Select
                                className="select-structio-dropdown line"
                                classNamePrefix="select-structio-dropdown"
                                value={genderOptions[genderOptionIndex]}
                                onChange={(gender) => setGender(gender.value)}
                                options={genderOptions}
                                isSearchable={false}
                            />
                        </div>
                        <div className="flex flex--column margin-m--tb">
                            <span className="color--text font-14">Tema</span>
                            <div className="topbar-theme-changer">
                                <span>Lys&nbsp;</span>
                                <WbSunny className={`light-theme ${themeState === "dark" ? "" : "light-active"}`} />
                                <div className="switch-content-container">
                                    <Switch
                                        checked={themeState === "dark"}
                                        onChange={() => dispatch(
                                            setTheme({
                                                theme: themeState,
                                            })
                                        )}
                                        value={themeState}
                                    />
                                </div>
                                <Brightness2 className={`dark-theme ${themeState === "dark" ? "dark-active" : ""}`} />
                                <span>&nbsp;Mørk</span>
                            </div>
                        </div>
                        <div className="col text-center padding-m--tb">
                            <Button
                                className="font-16"
                                height={40}
                                width={225}
                                text={'Gem oplysninger'}
                                type="add"
                                onClick={() => saveProfile()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default Welcome;
