import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'moment';
import { isEmpty } from "lodash";
import axios from 'axios';

// Services
import AuthService from '../../../../services/Auth';
import { todoTypeOptions, reminderOptions, reminderOptionsValue } from '../../../../services/FunctionHelper.tsx';
import { dateNowFormatted, dateRes } from '../../../../services/DateHandler';
import cloneDeep from 'lodash/cloneDeep';
import { TODO_STATUS } from '../../../../services/Enums';

// Components - Material UI
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// Components
import { NotificationManager } from 'react-notifications';
import { Check, Add, Clear, DeleteForever, LockOutlined, LockOpenOutlined, NotificationsActive } from '@mui/icons-material';
import Select from 'react-select';
import Calendar from 'react-calendar';
import IconButton from '../../../button/iconButton';
import InputField from '../../../inputField/';
import Timepicker from '../../../timepicker/Timepicker';
import Button from '../../../button/button';
import Switch from '../../../button/switchButton/';
import UserItem from '../../../listItem/userItem';
// Store
import { setIsLoading } from '../../../../store/features/spinner';
// Style
import './TodoEdit.scss';

const TodoEdit = (props) => {
    /* Store */
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    const friendlistStore = useSelector((state) => state.friendlist);
    const friendlist = friendlistStore.friendlist;
    /* Props */
    const { state, todoObject, todoIndex } = props; // props
    const { setState: closeModal, updateTodoList } = props; // func

    let cloneObject = cloneDeep(todoObject);
    /* Initialize states */
    const [title, setTitle] = useState(cloneObject.title);
    const [description, setDescription] = useState(cloneObject.description);
    const [date, setDate] = useState(dateRes(cloneObject.date));
    const [time, setTime] = useState(dateRes(cloneObject.date));
    const [todoType, setTodoType] = useState(cloneObject.type);
    const [reminderOption, setReminderOption] = useState(reminderOptionsValue(cloneObject.date, cloneObject.pushReminderDate));
    const [isPrivate, setIsPrivate] = useState(cloneObject.isPrivate);
    const [isAutoComplete, setIsAutoComplete] = useState(cloneObject.isAutoComplete);
    const [users, setUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState(cloneObject.users);
    const [subitems, setSubitems] = useState(cloneObject.subitems);

    useEffect(() => {
        setTitle(cloneObject.title);
        setDescription(cloneObject.description);
        setDate(dateRes(cloneObject.date));
        setTime(dateRes(cloneObject.date));
        setTodoType(cloneObject.type);
        setIsPrivate(cloneObject.isPrivate);
        setIsAutoComplete(cloneObject.isAutoComplete);
        setAddedUsers(cloneObject.users);
        // Filter friends who's already added to the todo
        const filterAlreadyAdded = friendlist.filter(friend => !cloneObject.users.some(user => friend.userId === user.userId));
        setUsers([...cloneObject.users, ...filterAlreadyAdded]);
    }, [todoObject]);

    const close = () => {
        closeModal();
        setTitle(cloneObject.title);
        setDescription(cloneObject.description);
        setDate(dateRes(cloneObject.date));
        setTime(dateRes(cloneObject.date));
        setTodoType(cloneObject.type);
        setReminderOption((reminderOptionsValue(cloneObject.date, cloneObject.pushReminderDate)));
        setIsPrivate(cloneObject.isPrivate);
        setIsAutoComplete(cloneObject.isAutoComplete);
        setAddedUsers(cloneObject.users);
    }

    const setTitleInputValue = (value) => {
        setTitle(value);
        document.getElementById("title-input-error").classList.add("hidden");
    }

    const onClickSaveTodo = () => {
        dispatch(setIsLoading(true));
        const timeHour = Moment(time).hours();
        const timeMins = Moment(time).minutes();
        const toDate = Moment(date).toDate();
        const dateRaw = toDate.setHours(timeHour, timeMins, 0);
        const dateData = Moment(dateRaw).format("YYYY-MM-DD HH:mm:00");
        const dateNowRes = dateNowFormatted();
        const data = {
            "id": todoObject.id,
            'title': title,
            'description': description,
            'date': dateData,
            'dateNow': dateNowRes,
            'type': todoType,
            'pushReminderDate': reminderOption,
            'isComplete': 0,
            'isPrivate': 1, //isPrivate disabled until sharing Calendar gets made - every todo will be private until then for privacy
            'isAutoComplete': isAutoComplete,
            'users': addedUsers,
            'prevUsers': todoObject.users,
        };

        if (addedUsers.length === 0) {
            NotificationManager.warning('Der skal mindst være én tilknyttet opgaven', 'Fejl!');
            dispatch(setIsLoading(false));
        } else if (title.length === 0) {
            document.getElementById("todoedit-title").scrollIntoView({ behavior: 'smooth' });
            document.getElementById("title-input-error").classList.remove("hidden");
            dispatch(setIsLoading(false));
        } else {
            axios.put(`${process.env.REACT_APP_API_URL}/todoitem/update`, data, AuthService.config())
                .then((response) => {
                    // handle success
                    const todoItem = response.data;
                    todoItem.subitems = subitems; //Add subitems as they're not coming from backend
                    updateTodoList(todoIndex, TODO_STATUS.update, todoItem);
                    setTitle(todoItem.title);
                    setDescription(todoItem.description);
                    setDate(dateRes(todoItem.date));
                    setTime(dateRes(todoItem.date));
                    setTodoType(todoItem.type);
                    setIsPrivate(todoItem.isPrivate);
                    setIsAutoComplete(todoItem.isAutoComplete);
                    setAddedUsers(todoItem.users);
                    closeModal();
                    NotificationManager.success('Ændringer er gemt');
                    dispatch(setIsLoading(false));
                })
                .catch((error) => {
                    NotificationManager.warning('Kunne ikke opdatere opgaven', 'Fejl!');
                    console.error(error);
                    dispatch(setIsLoading(false));
                });
        }
    }

    //Get todoTypeIndex from OptionsList
    let todoTypeAddIndex = 0;
    todoTypeOptions.map((item, index) => {
        if (todoType === item.value) {
            todoTypeAddIndex = index;
        }
    });

    //Get reminderOptionsIndex from OptionsList
    let reminderOptionsIndex = 0;
    reminderOptions.map((item, index) => {
        if (reminderOption === item.value) {
            reminderOptionsIndex = index;
        }
    });

    /* Returns added users */
    let userListAddSelected = [];
    if (!isEmpty(users)) {
        users.forEach((user, index) => {
            const { firstname, lastname } = user;
            const added = addedUsers.some(addedUser => addedUser.userId === user.userId);
            if (added) {
                const updatedList = addedUsers.filter(addedUser => addedUser.userId !== user.userId);
                const removeUser = (list) => {
                    if (list.length) {
                        setAddedUsers(list)
                    } else {
                        NotificationManager.warning('Minimum 1 person skal være tilknyttet en todo');
                    }
                }
                const componenet = <UserItem
                    key={`${index}-added-user`}
                    name={`${firstname} ${lastname}`}
                    onClickIcon={<IconButton
                        className="color--delete-hover"
                        icon={<DeleteForever />}
                        onClick={() => removeUser(updatedList)}
                    />}
                />
                userListAddSelected.push(componenet);
            } else {
                const userObject = {
                    userId: user.userId,
                    firstname: firstname,
                    lastname: lastname,
                    pictureUrl: user.pictureUrl,
                    todoId: todoObject.id
                };
                const componenet = <UserItem
                    key={`${index}-add-user`}
                    inactive={true}
                    name={`${firstname} ${lastname}`}
                    onClickIcon={<IconButton
                        className="color--add-hover"
                        icon={<Add />}
                        onClick={() => setAddedUsers([...addedUsers, userObject])}
                    />}
                />
                userListAddSelected.push(componenet);
            }
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={'structio-modal-container'}
            open={state}
            onClose={close}
            closeAfterTransition
        >
            <Fade in={state}>
                <div className="structio-modal-box">
                    <button className="icon-btn close-modal-btn" onClick={close}><Clear /></button>
                    <h1 className="modal-title">Rediger opgave</h1>
                    <div className="flex flex--column">
                        <span id="todoedit-title" className="title-text font-14">Opgave titel</span>
                        <InputField className="font-16 line" type="text" placeholder="Indtast opgavens titel" maxLength="50" value={title} onChange={(value) => setTitleInputValue(value.target.value)} />
                        <span id="title-input-error" className="hidden font-12 color--warning">&nbsp;(Opgavens titel må ikke være tom)</span>
                        <span className="font-13 color--graa4 margin-l--t">Tegn: {title.length} / 50</span>
                    </div>
                    <div className="flex flex--column">
                        <span className="title-text margin-l--t font-14">Opgave beskrivelse</span>
                        <InputField className="font-16 line" type="text" placeholder="Indtast en opgave beskrivelse" maxLength="99" value={description} onChange={(value) => setDescription(value.target.value)} />
                        <span className="font-13 color--graa4 margin-l--t">Tegn: {description.length} / 99</span>
                    </div>
                    {/* Disable todoTypes as sorting on todoTypes is now disabled as it has no value for user 
                        <span className="title-text margin-l--t font-14">Opgave type</span>
                        <Select
                            className="select-structio-dropdown line font-16"
                            classNamePrefix="select-structio-dropdown"
                            value={todoTypeOptions[todoTypeAddIndex]}
                            onChange={(target) => setTodoType(target.value)}
                            options={todoTypeOptions}
                            isSearchable={false}
                        />
                    */}
                    <span className="title-text margin-l--t font-14">Afslut opgave automatisk</span>
                    <div className="flex flex--column flex-justify--center line standard-structio-switch">
                        <div className="flex flex-align--center">
                            <span className="flex"><Clear className={isAutoComplete ? "color--graa5" : "color--structio"} /></span>
                            <Switch
                                checked={isAutoComplete}
                                onChange={() => setIsAutoComplete(!isAutoComplete)}
                                value={isAutoComplete}
                                size="switch-large"
                            />
                            <span className="flex"><Check className={isAutoComplete ? "color--structio" : "color--graa5"} /></span>
                        </div>
                    </div>
                    <span className="title-text margin-l--t font-14">Påmindelse</span>
                    <div className="flex flex--column flex-justify--center line standard-structio-switch">
                        <div className="flex flex-align--center">
                            <span className="flex"><NotificationsActive className="color--structio" /></span>
                            <Select
                                className="select-structio-dropdown font-16 full-width padding-s--l"
                                classNamePrefix="select-structio-dropdown"
                                value={reminderOptions[reminderOptionsIndex]}
                                onChange={(target) => setReminderOption(target.value)}
                                options={reminderOptions}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <span className="title-text margin-l--t font-14">Dato</span>
                    <Calendar
                        className="full-width"
                        value={new Date(date)}
                        onChange={(value) => setDate(value)}
                        locale="DA"
                    />
                    <span className="title-text margin-l--t font-14">Tidspunkt</span>
                    <Timepicker
                        className="line padding-m--b"
                        startTimeHours={Moment(time).format('HH')}
                        startTimeMinutes={Moment(time).format('mm')}
                        date={new Date(time)}
                        add={true}
                        onChangeDateAdd={(value) => setTime(value)}
                    />
                    {/* isPrivate disabled until sharing Calendar gets made - every todo made will be private until then for privacy
                    <span className="title-text margin-l--t font-14">Privat begivenhed</span>
                    <div className="flex flex--column flex-align--center flex-justify--center line standard-structio-switch">
                        <span className="flex flex-justify--center font-24 color--text">{isPrivate ? "Privat" : "Synlig"}</span>
                        <div className="flex flex-align--center">
                            <span className="flex"><LockOpenOutlined className={isPrivate ? "color--graa5" : "color--structio"} /></span>
                            <Switch
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                                value={isPrivate}
                                size="switch-large"
                            />
                            <span className="flex"><LockOutlined className={isPrivate ? "color--structio" : "color--graa5"} /></span>
                        </div>
                    </div>
                    */}
                    <span className="title-text margin-l--t font-14">Deltagere</span>
                    <div className="flex flex--column line margin-m--t">
                        {userListAddSelected}
                    </div>
                    <div className="flex flex--column flex-justify--center flex-align--center margin-m--t">
                        <Button
                            text={'Gem ændringer'}
                            type="add"
                            onClick={() => onClickSaveTodo()}
                        />
                        <Button
                            className="margin-m--t"
                            type="cancel"
                            text={'Annuller'}
                            onClick={() => close()}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
export default TodoEdit;
