import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components - Material UI
import Fade from '@mui/material/Fade';

// Content Components
import UserRead from './userContent/UserRead';
import UserEdit from './userContent/UserEdit';
import UserPanel from './userContent/UserPanel';
import UserFriendPanel from './userContent/userFriendPanel/UserFriendPanel';

// Style
import './User.scss';

const User = (props) => {
  // Store
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  // Initialize states
  const [contentShow, setContentShow] = useState('read');

  const onClickContentShow = (contentValue) => {
    setContentShow(contentValue);
  }

  let panelContent;
  let userContent;
  if (user) {
    panelContent = <UserPanel />;
    if (contentShow === "read") {
      userContent = <UserRead onClickContentShow={onClickContentShow} />;
    }
    if (contentShow === "update") {
      userContent = <UserEdit
        onClickContentShow={onClickContentShow}
      />;
    }
    if (contentShow === "friendlist") {
      userContent = <UserFriendPanel
        onClickContentShow={onClickContentShow}
      />;
    }
  }

  return (
    <Fade in timeout={1000}>
      <div className="content-container flex-center">
        <div className="center-content user-container">
          <div>
            {panelContent}
          </div>
          <div>
            {userContent}
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default User;
