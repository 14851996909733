import { deleteCookie, getCookie } from './FunctionHelper.tsx';

class AuthService {
  getAccessToken() {
    // console.log("tokenJWT", getCookie('tokenJWT'));
    return getCookie('tokenJWT')
  }

  deleteCookies() {
    deleteCookie('auth-uid');
    deleteCookie('user-email');
    deleteCookie('tokenJWT');
  }

  config() {
    return {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `${this.getAccessToken()}`
      },
      withCredentials: true
    };
  }

  /* axios doesnt accepts body load in .delete and need a full config */
  configWithData(data) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${this.getAccessToken()}`,
      },
      data: data
    }
  };
}

export default new AuthService()
