import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Types
import type { RootState } from "../../../../store/store.ts";

// Services
import AuthService from "../../../../services/Auth";

// Store
import { setIsLoading } from "../../../../store/features/spinner";
import { setNotes } from "../../../../store/features/notes";

// Components
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { NotificationManager } from "react-notifications";
import InputField from "../../../inputField";
import Switch from "../../../button/switchButton";
import SubItemCreate from "../../../listItem/subItem/SubItemCreate";
import Button from "../../../button/button";
import Textarea from "../../../textarea";

// Icons
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ButtonField from "../../../inputField/buttonField";

// Style
import "./ModalCreate.scss";

const ModalCreate = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const noteStore = useSelector((state: RootState) => state.notes);
  const noteList = noteStore.notes;
  /* props */
  const { state, toggleModal } = props;

  /* Initialize states */
  const descriptionLimit = 1000;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [showOnDashboard, setShowOnDashboard] = useState(false);
  const [subitems, setSubitems] = useState([] as any[]);
  const [subitemInput, setSubitemInput] = useState("");

  const setTitleInputValue = (value: string) => {
    setTitle(value);
    document.getElementById("title-input-error")?.classList.add("hidden");
  };

  const setDescriptionValue = async (value: string) => {
    setDescription(value);
    setDescriptionLength(value.length);
  };

  /* Sub note functionality */
  /* Makes it possible to create an subitem by pressing enter */
  const handleOnEnter = (e) => {
    if (e.key === "Enter") {
      createSubitem();
    }
  };

  const createSubitem = () => {
    if (subitemInput) {
      const subitemsList = [...subitems];
      const itemObject = {
        description: subitemInput,
        isComplete: 0,
      };
      subitemsList.push(itemObject);
      setSubitems(subitemsList);
      setSubitemInput("");
    } else {
      document
        .getElementById("subitem-input-error")
        ?.classList.remove("hidden");
    }
  };

  const deleteSubitem = (index: number) => {
    const subitemsList = [...subitems];
    subitemsList.splice(index, 1);
    setSubitems(subitemsList);
  };

  const setSubitemInputValue = (value: string) => {
    setSubitemInput(value);
    document.getElementById("subitem-input-error")?.classList.add("hidden");
  };

  let subitemList: any[] = [];
  subitems.forEach((item, index) => {
    subitemList.push(
      <SubItemCreate
        key={`${index}-added-subitem`}
        className="color--text"
        subitem={item}
        deleteSubitem={() => deleteSubitem(index)}
      />
    );
  });

  /* Create note */
  const onClickCreateNote = () => {
    dispatch(setIsLoading(true));
    const data = {
      title: title,
      description: description,
      showOnDashboard: showOnDashboard,
      subitems: subitems,
    };
    if (title.length === 0) {
      document
        .getElementById("note-title")
        ?.scrollIntoView({ behavior: "smooth" });
      document.getElementById("title-input-error")?.classList.remove("hidden");
      dispatch(setIsLoading(false));
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/note/create`,
          data,
          AuthService.config()
        )
        .then((response) => {
          const createdNoteItem = response.data;
          if (createdNoteItem) {
            const cloneNotes: any[] = noteList.slice();
            cloneNotes.push(createdNoteItem);
            dispatch(setNotes(cloneNotes));
            toggleModal();
            NotificationManager.success("Noten er oprettet", "");
            dispatch(setIsLoading(false));
          } else {
            NotificationManager.warning(
              "Kunne ikke oprette noten på nuværende tidspunkt.",
              ""
            );
            dispatch(setIsLoading(false));
          }
        })
        .catch((error) => {
          NotificationManager.warning("Kunne ikke oprette noten", "Fejl!");
          console.error(error);
          dispatch(setIsLoading(false));
        });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={"structio-modal-container"}
      open={state}
      onClose={toggleModal}
      closeAfterTransition
    >
      <Fade in={state}>
        <div className="structio-modal-box">
          <button
            className="icon-btn close-modal-icon"
            type="button"
            onClick={toggleModal}
          >
            <ClearIcon />
          </button>
          <h1 className="modal-title no-margin">Opret note</h1>
          <div className="flex flex--column margin-m--t">
            <span id="note-title" className="title-text font-14">
              Note titel
            </span>
            <InputField
              className="font-16 line"
              type="text"
              placeholder="Indtast notens titel"
              maxLength="50"
              value={title}
              onChange={(value) => setTitleInputValue(value.target.value)}
            />
            <span
              id="title-input-error"
              className="hidden font-12 color--warning"
            >
              &nbsp;(Notens titel må ikke være tom)
            </span>
            <span className="font-13 color--graa4">
              Tegn: {title.length} / 50
            </span>
          </div>
          <div className="flex flex--column margin-l--t">
            <span className="title-text font-14">Note beskrivelse</span>
            <Textarea
              value={description}
              onChange={(event) => setDescriptionValue(event.target.value)}
              maxLength={descriptionLimit}
              placeholder="Note beskrivelse"
            />
            <div>
              <span className="font-13 color--graa4">
                Tegn: {descriptionLength} / {descriptionLimit}
              </span>
            </div>
          </div>
          <span className="title-text margin-l--t font-14">Checkliste</span>
          <div className="flex flex--column line">
            <div className="flex flex--column">
              <ButtonField
                className="font-14"
                type="text"
                placeholder="Indtast beskrivelse"
                maxLength="50"
                value={subitemInput}
                onChange={(value) => setSubitemInputValue(value.target.value)}
                onKeyPress={(e: Event) => handleOnEnter(e)}
                icon={<CheckIcon />}
                onClick={() => createSubitem()}
              />
              <span
                id="subitem-input-error"
                className="hidden font-12 color--warning"
              >
                &nbsp;(titel må ikke være tom)
              </span>
              <span className="font-13 color--graa4">
                Tegn: {subitemInput.length} / 50
              </span>
            </div>
            <div className="flex flex--column margin-m--t">{subitemList}</div>
          </div>
          <div className="flex flex--column flex-justify--center line standard-structio-switch">
            <div className="show-task-ondashboard-container">
              <span className="flex color--text font-14">
                <DashboardIcon className="margin-s--r color--structio" />
                Vis note på Din dag
              </span>
              <div style={{ marginRight: "-15px" }}>
                <Switch
                  checked={showOnDashboard}
                  onChange={() => setShowOnDashboard(!showOnDashboard)}
                  value={showOnDashboard}
                  size="switch-large"
                />
              </div>
            </div>
          </div>
          <div className="flex flex--column flex-justify--center flex-align--center margin-m--t">
            <Button
              text={"Opret note"}
              type="add"
              onClick={() => onClickCreateNote()}
            />
            <Button
              className="margin-m--t"
              type="cancel"
              text={"Annuller"}
              onClick={() => toggleModal()}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalCreate;
