import React from 'react';

// Components
import Badge from '@mui/material/Badge';

// Icons

// Style
import './IconButton.scss';

/** @ViableTypes for props
 *
 ** className - string: Adds a custom class to the element
 ** text - String: Provides a text to the icon
 ** icon - Component: Provides a icon to the button
 ** iconRight - Component: Provides a icon to the button to the right
 ** selected - Boolean: Checks if the button is selected and adds a class
 ** badge - Number: makes it possible to apply a bagde to the element
** badgeColor - String: 'green' | 'red' | 'orange' -> Possible to add more colors in global.css - _badge.scss
 ** onClick - Func: Adds a functions to the button
 ** Reference - adds ref to component = able to get element good for listeners like click on element checks
 */

const IconButton = (props) => {
  /* data */
  const { text, icon, iconRight, selected, className, badge, badgeColor, reference, disabled, pulseBadge } = props;
  /* functions */
  const { onClick } = props;

  let selectedClass;
  if (selected) {
    selectedClass = "color--selected";
  }

  let pulseBadgeClass;
  if (pulseBadge) {
    pulseBadgeClass = "pulse-btn"
  }

  return (
    <button ref={reference} className={`icon-btn ${selectedClass} ${className} ${pulseBadgeClass}`} type="button" onClick={onClick} disabled={disabled ? "disabled" : null}>
      {badge ? <Badge overlap="rectangular" className={`flex icon-btn-badge ${badgeColor}`} badgeContent={badge} /> : null}
      {icon}
      {text ? <span>{text}</span> : null}
      {iconRight}
    </button>
  );
};

export default IconButton;
