// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWtdV7iR13kx-bRXThNkUHbKVNa-8t0R8",
  authDomain: "structio-12364.firebaseapp.com",
  projectId: "structio-12364",
  storageBucket: "structio-12364.appspot.com",
  messagingSenderId: "201368017306",
  appId: "1:201368017306:web:31145b2001275a74598223",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getNotificationToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BKXLNJb0uVDeSFnbIDjo62QjFqxakI9tdpJAjKthqIRGVTKEvZdOgwaAgE3avUYPlt7RDo4KNk6yeH6vR6sGV4A",
    });
    if (currentToken) {
      //console.log('current token for client: ', currentToken);
      return currentToken;
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
      // shows on the UI that permission is required
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    return null;
    // catch error while creating client token
  }
};

export const onMessageListener = (callback) => {
  onMessage(messaging, (payload) => {
    // console.log("frontMessage received: ", payload);
    callback(payload);
  });
};
