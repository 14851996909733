import axios from "axios";
import AuthService from "../../../../services/Auth";

import { NotificationManager } from "react-notifications";

class ApiService extends Object {
  completeTodo = (todoId, callback) => {
    const data = {
      todoId: todoId,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/todoitem/complete`,
        data,
        AuthService.config()
      )
      .then((response) => {
        // handle success
        NotificationManager.success("Opgaven er klaret!");
        callback();
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke afslutte opgaven", "Fejl!");
        console.error(error);
      });
  };

  uncompleteTodo = (todoId, callback) => {
    const data = {
      todoId: todoId,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/todoitem/uncomplete`,
        data,
        AuthService.config()
      )
      .then((response) => {
        // handle success
        NotificationManager.success("Opgaven er genaktiveret");
        callback();
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke genaktivere opgaven", "Fejl!");
        console.error(error);
      });
  };

  deleteTodo = (todo, callback) => {
    /* axios doesnt accepts body load in .delete and need a full config */
    const data = {
      todo: todo,
    };

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/todoitem/delete`,
        AuthService.configWithData(data)
      )
      .then((response) => {
        // handle success
        NotificationManager.success("Opgaven er slettet");
        callback();
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke slette opgaven", "Fejl!");
        console.error(error);
      });
  };

  acceptTodo = (todoId, title, userObject, callback, status) => {
    const userTodoId = userObject.id;
    const data = {
      userTodoId: userTodoId,
      todoId: todoId,
      title: title,
      status: status,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/usertodo/accept`,
        data,
        AuthService.config()
      )
      .then((response) => {
        // handle success
        let textRes = "Opgaven er accepteret";
        if (status === "declined") {
          textRes = "Opgaven er afvist";
        }
        NotificationManager.success(textRes);
        callback(response.data, status);
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke acceptere opgaven", "Fejl!");
        console.error(error);
      });
  };

  completeSubitem = (index, todoId, subitemId, callback) => {
    const data = {
      todoId: todoId,
      subitemId: subitemId,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/subitem/complete`,
        data,
        AuthService.config()
      )
      .then((response) => {
        // handle success
        callback(index);
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke godkende opgaven", "Fejl!");
        console.error(error);
      });
  };

  uncompleteSubitem = (index, todoId, subitemId, callback) => {
    const data = {
      todoId: todoId,
      subitemId: subitemId,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/subitem/uncomplete`,
        data,
        AuthService.config()
      )
      .then((response) => {
        // handle success
        callback(index);
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke godkende opgaven", "Fejl!");
        console.error(error);
      });
  };

  deleteSubitem = (index, todoId, subitemId, callback) => {
    const data = {
      todoId: todoId,
      subitemId: subitemId,
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/subitem/delete`,
        AuthService.configWithData(data)
      )
      .then((response) => {
        // handle success
        callback(index);
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke slette opgaven", "Fejl!");
        console.error(error);
      });
  };

  createSubitem = (todoId, description, callback) => {
    const data = {
      todoId: todoId,
      description: description,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/subitem/create`,
        data,
        AuthService.config()
      )
      .then((response) => {
        // handle success
        const subitem = response.data;
        callback(subitem);
      })
      .catch((error) => {
        NotificationManager.warning("Kunne ikke oprette opgaven", "Fejl!");
        console.error(error);
      });
  };
}

export default new ApiService();
