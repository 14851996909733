// Define a TypeScript type for your state
export interface IUser {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  zip: string;
  city: string;
  birth: string;
  gender: TType;
  subscription: string;
  trialPeriod: Date;
  pictureUrl: any;
  theme: ITheme;
}

type ITheme = "light" | "dark";

export type TType =
  | GENDERTYPES.male
  | GENDERTYPES.female
  | GENDERTYPES.anonymous;

export enum GENDERTYPES {
  male = "male",
  female = "female",
  anonymous = "anonymous",
}
