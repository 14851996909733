import React from 'react';
import { useSelector } from 'react-redux';

// Services

// Components
import { Link } from 'react-router-dom';
import Button from '../../button/button';
import NoteItem from '../../note/noteItem/';

// Icons
import { SpeakerNotes as NotesIcon } from '@mui/icons-material';

// Store

// Style
import './DashboardNotelist.scss';

const DashboardNotelist = (props) => {
    /* Intialize writing Store */
    /* Store */
    const noteStore = useSelector((state) => state.notes);
    const noteList = noteStore.notes;
    /* props */
    const { } = props
    /* Initialize states */

    let notes = [];
    if (noteList) {
        noteList.forEach((note, index) => {
            if (note.showOnDashboard) {
                notes.push(<NoteItem key={'noteitem' + index}
                    index={index}
                    object={note}
                />)
            }
        });
    }


    return (
        <div className="dashboard-item">
            <div className="dashboardnotelist-container">
                <div className="header-title text-left margin-s--b">Fremhævet noter</div>
                {notes.length > 0
                    ? <div>
                        {notes}
                    </div>
                    : <div className="flex flex-align--center flex--column">
                        <div className="flex dashboarditem-completed-icon"><NotesIcon style={{ color: 'var(--color--lightgreen)' }} /></div>
                        <div className="flex">
                            <span className="text-left font-14 opacity--50">Du har ingen fremhævet noter</span>
                        </div>
                        <Link className="flex anchor-no-line margin-s--tb" to={`${process.env.PUBLIC_URL}/notes`}>
                            <Button className="text-left font-14" text="Gå til noter" type="add" />
                        </Link>
                    </div>
                }

            </div>
        </div>
    )
}

export default DashboardNotelist;