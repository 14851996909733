import { createSlice } from "@reduxjs/toolkit";

export const friendlistSlice = createSlice({
  name: "friendlist",
  initialState: {
    friendlist: [],
    pendinglist: [],
    requestlist: [],
  },
  reducers: {
    setFriendlist: (state, action) => {
      const friendlist = [];
      const pendinglist = [];
      const requestlist = [];
      if (!action.payload) return;
      action.payload.forEach((friend) => {
        if (friend.isAccepted === 1) {
          friendlist.push(friend);
        } else if (friend.requestByMe === 0 && friend.isAccepted === 0) {
          pendinglist.push(friend);
        } else if (friend.requestByMe === 1 && friend.isAccepted === 0) {
          requestlist.push(friend);
        }
      });
      state.friendlist = friendlist;
      state.pendinglist = pendinglist;
      state.requestlist = requestlist;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFriendlist } = friendlistSlice.actions;

export default friendlistSlice.reducer;
