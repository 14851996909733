import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0; // has to be on body as I've disabled overflow on HTML and enabled it on Body
  }, [pathname]);

  return null;
}