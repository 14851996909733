import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Services
import { windowSizeListener } from '../services/FunctionHelper.tsx';

// Components
import BackgroundPicture from '../components/backgrounds/Background';

// Component Screens
import Login from './login/Login';

function LoginRoutes() {
  /* store */
  /* Initialize states */
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const device = windowSizeListener() ? 'smartphone full-width no-margin' : 'desktop full-width no-margin';
    setIsMobileView(device);
    document.documentElement.setAttribute('data-theme', "dark");
  }, []);

  return (
    <BrowserRouter>
      <BackgroundPicture />
      <div className="login-content-container">
        <div className={`${isMobileView} routes-container`} direction="row">
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              exact
              render={() => <Login />}
            />
            <Redirect to='/' />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default LoginRoutes;
