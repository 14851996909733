import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Services
import axios from 'axios';
import AuthService from '../../../services/Auth';
import { dateNowFormatted } from '../../../services/DateHandler';

// Components - Material UI
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

// Components
import { NotificationManager } from 'react-notifications';
import Button from '../../button/button';
import Textarea from '../../textarea';

// Icons
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

// Store

// Style
import './DashboardFeedback.scss';

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const DashboardFeedback = (props) => {
    /* Store */
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    /* props */
    const { } = props
    /* Initialize states */
    const [feedback, setFeedback] = useState('');
    const [modalFeedback, setModalFeedback] = useState(false);

    const showFeedbackModal = () => {
        setModalFeedback(!modalFeedback);
        setFeedback("");
    }

    const onClickSendFeedback = async () => {
        if (feedback.length) {
            const dateNowRes = dateNowFormatted();
            const data = {
                firstname: user.firstname,
                lastname: user.lastname,
                date: dateNowRes,
                description: feedback,
            };
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/feedback/create`, data, AuthService.config());
                const feedbackRes = res.data;
                if (feedbackRes) {
                    NotificationManager.success('Mange tak for din feedback!', '');
                    setModalFeedback(!modalFeedback);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            NotificationManager.warning('Din feedback må ikke være tom', '');
        }
    }

    return (
        <div className="dashboard-item-addfriend line">
            <div>
                <div className="header-title">Din feedback</div>
                <div className="font-14 padding-l--r">Hjælp Structio med at blive endnu bedre</div>
            </div>
            <div className="flex flex-align--center pointer" onClick={() => showFeedbackModal()}>
                <span className="font-14 margin-s--r">Angiv</span>
                <Button
                    type="circle"
                    width={30}
                    height={30}
                    className="add"
                    icon={<AddIcon />}
                />
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={'structio-modal-container'}
                open={modalFeedback}
                onClose={showFeedbackModal}
                closeAfterTransition
            >
                <Fade in={modalFeedback}>
                    <div className="structio-modal-box">
                        <button className="icon-btn close-modal-icon" type="button" onClick={showFeedbackModal}><ClearIcon /></button>
                        <h1 className="modal-title no-margin">Din feedback</h1>
                        <div className="row align-items-center line padding-m--t margin-m--b">
                            <div className="row align-items-center">
                                <div className="col">
                                    <span className="header-title">Din feedback kan som eksempel indeholde</span>
                                    <ul className="header-title margin-m--b">
                                        <li>Fundne fejl</li>
                                        <li>Irritationer i brugeroplevelsen</li>
                                        <li>Foreslag til forbedringer</li>
                                        <li>Ønsker til nye funktioner</li>
                                        <li>Nævne særlig gode funktioner</li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <span className="header-title">Angiv din feedback</span>
                                </div>
                                <div className="col">
                                    <Textarea value={feedback} onChange={(event) => setFeedback(event.target.value)} maxLength={600} />
                                    <span className="font-13 color--graa4">Tegn: {feedback.length} / 600</span>

                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col flex align-items-center padding-s">
                                <Button
                                    className="icon-btn"
                                    onClick={showFeedbackModal}
                                    text={'Annuller'}
                                />
                            </div>
                            <div className="col flex align-items-center padding-s">
                                <Button
                                    className="icon-btn add"
                                    onClick={() => onClickSendFeedback()}
                                    text={'Send'}
                                />
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default DashboardFeedback;