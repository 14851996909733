import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

// Services
import * as InputValidator from '../../../services/InputValidator';
import { genderOptions } from '../../../services/FunctionHelper.tsx';
import AuthService from '../../../services/Auth';

// Components
import Fade from '@mui/material/Fade';
import Select from 'react-select';
import Button from '../../../components/button/button';
import InputField from '../../../components/inputField/';
import { NotificationManager } from 'react-notifications';

// Store
import { getUser } from '../../../store/features/user.ts';
import { setIsLoading } from "../../../store/features/spinner.js";

const user = (props) => {
  // Store
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  // functions
  const { onClickContentShow } = props;
  // Initialize states
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState(null);
  const [birth, setBirth] = useState(null);
  const [gender, setGender] = useState('male');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Initialize values based on user information on load
    if (user) {
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setCity(user.city);
      setZip(user.zip);
      setBirth(user.birth);
      setGender(user.gender);
      setEmail(user.email);
    }
  }, []);

  const onChangeFirstname = (event) => {
    setFirstname(event.target.value);
    document.getElementById("firstname-input-field").classList.remove("input-error");
    document.getElementById("firstname-input-error").className = "hidden";
  }

  const onChangeLastname = (event) => {
    setLastname(event.target.value);
    document.getElementById("lastname-input-field").classList.remove("input-error");
    document.getElementById("lastname-input-error").className = "hidden";
  }

  const onChangeCity = (event) => {
    setCity(event.target.value);
    document.getElementById("city-input-field").classList.remove("input-error");
    document.getElementById("city-input-error").className = "hidden";
  }

  const onChangeZip = (event) => {
    setZip(event.target.value);
    document.getElementById("zip-input-field").classList.remove("input-error");
    document.getElementById("zip-input-error").className = "hidden";
  }

  const onChangeBirth = (birth) => {
    setBirth(birth.value);
  }

  const onChangeGender = (gender) => {
    setGender(gender.value);
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
    document.getElementById("email-input-field").classList.remove("input-error");
    document.getElementById("email-input-error").className = "hidden";
  }

  const validateForm = () => {
    let res = true;
    let resFirstname = InputValidator.name(firstname);
    let resLastname = InputValidator.name(lastname);
    let resCity = city.length > 0 ? InputValidator.name(city) : true;
    let resZip = zip.length > 0 ? InputValidator.zip(zip) : true;
    let resEmail = InputValidator.email(email);

    if (!resFirstname) {
      document.getElementById("firstname-input-field").classList.add("input-error");
      document.getElementById("firstname-input-error").className = "color--warning show";
      document.getElementById("firstname-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    if (!resLastname) {
      document.getElementById("lastname-input-field").classList.add("input-error");
      document.getElementById("lastname-input-error").className = "color--warning show";
      document.getElementById("lastname-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    if (!resCity) {
      document.getElementById("city-input-field").classList.add("input-error");
      document.getElementById("city-input-error").className = "color--warning show";
      document.getElementById("city-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    if (!resZip) {
      document.getElementById("zip-input-field").classList.add("input-error");
      document.getElementById("zip-input-error").className = "color--warning show";
      document.getElementById("zip-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    if (!resEmail) {
      document.getElementById("email-input-field").classList.add("input-error");
      document.getElementById("email-input-error").className = "color--warning show";
      document.getElementById("email-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    return res;
  }

  const onClickSaveUser = async () => {
    const validData = validateForm();
    if (!validData) return;

    dispatch(setIsLoading(true));

    const data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      zip: zip,
      city: city,
      birth: birth,
      gender: gender
    };

    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/user/update`, data, AuthService.config())
      // handle success
      dispatch(getUser(res.data));
      dispatch(setIsLoading(false));
      onClickContentShow("read");
      NotificationManager.success('Profil informationerne er gemt', '');
    } catch (err) {
      console.error("Error on saving user", err);
      NotificationManager.warning('Beklager, kan ikke gemme dine ændringer på nuværende tidspunkt.');
      dispatch(setIsLoading(false));
    }
  }

  // Get todoTypeIndex from OptionsList
  const birthOptions = [];
  let age = new Date().getFullYear(); // Count 100 down this case 1920
  for (let i = 0; i < 101; i++) {
    const currentAge = age--;
    birthOptions.push({ value: currentAge, label: <span className="todo-type-select-item">{currentAge}</span> });
  }
  let birthOptionIndex = 'Vælg årstal';
  birthOptions.forEach((item, index) => {
    if (birth === item.value.toString()) {
      birthOptionIndex = index;
    }
  });
  let genderOptionIndex = 0;
  genderOptions.forEach((item, index) => {
    if (gender === item.value) {
      genderOptionIndex = index;
    }
  });
  return (
    <Fade in>
      <div className="fade user-content">
        <div className="user-content-box">
          <div className="flex flex-wrap--wrap align-items-center padding-m--t">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Fornavn</span>
                <span id="firstname-input-error" className="hidden">&nbsp;(Ugyldigt fornavn)</span>
              </div>
              <div>
                <InputField id="firstname-input-field" type="text" placeholder="Indtast fornavn" value={firstname} onChange={(e) => onChangeFirstname(e)} />
              </div>
            </div>
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Efternavn</span>
                <span id="lastname-input-error" className="hidden">&nbsp;(Ugyldigt efternavn)</span>
              </div>
              <div>
                <InputField id="lastname-input-field" type="text" placeholder="Indtast efternavn" value={lastname} onChange={(e) => onChangeLastname(e)} />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>By</span>
                <span id="city-input-error" className="hidden">&nbsp;(Ugyldigt bynavn)</span>
              </div>
              <div>
                <InputField id="city-input-field" type="text" placeholder="Angiv by" value={city} onChange={(e) => onChangeCity(e)} />
              </div>
            </div>
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Post nr.</span>
                <span id="zip-input-error" className="hidden">&nbsp;(Ugyldigt post nr)</span>
              </div>
              <div>
                <InputField id="zip-input-field" type="text" placeholder="Angiv post nr." value={zip} onChange={(e) => onChangeZip(e)} />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center">
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Fødselsår</span>
              </div>
              <div>
                <Select
                  className="select-structio-dropdown profile-text"
                  classNamePrefix="select-structio-dropdown"
                  value={birthOptions[birthOptionIndex]}
                  onChange={(e) => onChangeBirth(e)}
                  options={birthOptions}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="user-data-col align-items-center line padding-m--t">
              <div className="profile-title">
                <span>Køn</span>
              </div>
              <div>
                <Select
                  className="select-structio-dropdown profile-text"
                  classNamePrefix="select-structio-dropdown"
                  value={genderOptions[genderOptionIndex]}
                  onChange={(e) => onChangeGender(e)}
                  options={genderOptions}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap align-items-center line padding-m--t margin-m--b">
            <div className="user-data-col align-items-center">
              <div className="profile-title">
                <span>Email <span className="color--graa5 font-12">(kan ikke ændres)</span></span>
              </div>
              <div className="profile-text">
                <span>{email}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap--wrap flex--center">
            <div className="flex padding-s">
              <Button
                className="icon-btn"
                onClick={() => onClickContentShow("read")}
                text={'Annuller'}
              />
            </div>
            <div className="flex padding-s">
              <Button
                className="icon-btn add"
                onClick={() => onClickSaveUser()}
                text={'Gem'}
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default user;
