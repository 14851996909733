import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Services
import AuthService from '../../../services/Auth';
import cloneDeep from 'lodash/cloneDeep';

// Store
import { setIsLoading } from '../../../store/features/spinner';
import { setNotes } from '../../../store/features/notes';

// Components
import { NotificationManager } from 'react-notifications';
import IconButton from '../../../components/button/iconButton';
import ModalEdit from '../../../components/note/modal/edit/ModalEdit';
import ModalDelete from '../../../components/note/modal/delete/ModalDelete';
import SubItem from '../../../components/listItem/subItem';
import ButtonField from '../../../components/inputField/buttonField';
import Switch from '../../../components/button/switchButton/';

// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Style
import './NoteItem.scss';

const NoteItem = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const noteStore = useSelector((state) => state.notes);
  const noteList = noteStore.notes;

  /* props */
  const { index, object } = props

  const { id, title, description, showOnDashboard, subitems } = object;
  /* Initialize states */
  const [toggleModalEdit, setToggleModalEdit] = useState(false);
  const [toggleModalDelete, setToggleModalDelete] = useState(false);
  const [showCreateSubitem, setShowCreateSubitem] = useState(false);
  const [subitemInput, setSubitemInput] = useState("");

  /* Makes it possible to create an subitem by pressing enter */
  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      createSubitem();
    }
  }

  const createSubitem = async () => {
    if (subitemInput) {
      try {
        dispatch(setIsLoading(true));
        const data = {
          noteId: id,
          description: subitemInput,
        };
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/subnote/create`, data, AuthService.config());
        const noteObject = res.data;
        /* Find the note that have been edited and swap it with the new updated object in the noteList
         * Then update the Store with the new noteList-object
         */
        let cloneNotes = [];
        noteList.forEach(note => {
          if (note.id === noteObject.id) {
            note = noteObject
          }
          cloneNotes.push(note);
        })
        dispatch(setNotes(cloneNotes));
        setShowCreateSubitem(false);
        setSubitemInput("");
        dispatch(setIsLoading(false));
      } catch (error) {
        dispatch(setIsLoading(false));
        NotificationManager.warning("Kunne ikke oprette noten", "Fejl!");
        console.error(error);
      }
    } else {
      document.getElementById(`subitem-note-input-error${id}`).classList.remove("hidden");
    }
  }

  const deleteSubitem = async (subnoteId) => {
    try {
      dispatch(setIsLoading(true));
      /* axios doesnt accepts body load in .delete and need a full config */
      const data = {
        id: subnoteId,
        noteId: id
      }
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/subnote/delete`, AuthService.configWithData(data))
      const noteObject = res.data;
      if (noteObject) {
        /* Find the note that have been edited and remove the deleted subnote in the noteList
        * Then update the Store with the new noteList-object
        */
        let cloneNotes = [];
        noteList.forEach(note => {
          if (note.id === noteObject.id) {
            note = noteObject
          }
          cloneNotes.push(note);
        })
        dispatch(setNotes(cloneNotes));
        dispatch(setIsLoading(false));
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      NotificationManager.warning("Kunne ikke slette på nuværende tidspunkt", "Fejl!");
      console.error(err);
    }
  }

  const completeSubnote = async (subnoteId, isComplete) => {
    try {
      dispatch(setIsLoading(true));
      const data = {
        id: subnoteId,
        noteId: id,
        isComplete: isComplete,
      };
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/subnote/complete`, data, AuthService.config())
      const noteObject = res.data;
      if (noteObject) {
        /* Find the note that have been edited and remove the deleted subnote in the noteList
        * Then update the Store with the new noteList-object
        */
        let cloneNotes = [];
        noteList.forEach(note => {
          if (note.id === noteObject.id) {
            note = noteObject
          }
          cloneNotes.push(note);
        })
        dispatch(setNotes(cloneNotes));
        dispatch(setIsLoading(false));
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      NotificationManager.warning("Kunne ikke ændre status på nuværende tidspunkt", "Fejl!");
      console.error(err);
    }
  }

  const deleteNote = () => {
    dispatch(setIsLoading(true));
    /* axios doesnt accepts body load in .delete and need a full config */
    const data = {
      noteId: id,
    };
    axios.delete(`${process.env.REACT_APP_API_URL}/note/delete`, AuthService.configWithData(data))
      .then((response) => {
        /* Clone noteList and take out the item that has just been deleted -> update to noteList Store */
        const cloneNotes = noteList.slice();
        cloneNotes.splice(index, 1);
        dispatch(setNotes(cloneNotes));
        setToggleModalDelete(false);
        NotificationManager.success("Noten er slettet");
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        NotificationManager.warning("Kunne ikke slette noten", "Fejl!");
        console.error(error);
      });
  };

  const showItemOnDashboard = async (showNote) => {
    try {
      dispatch(setIsLoading(true));
      const data = {
        id: id,
        showOnDashboard: showNote,
      };
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/note/showondashboard`, data, AuthService.config());
      /* Returns true on success otherwise false */
      if (res.data) {
        const noteObject = cloneDeep(object);
        noteObject.showOnDashboard = showNote;
        /* Find the note that have been edited and swap it with the new updated object in the noteList
         * Then update the Store with the new noteList-object
         */
        let cloneNotes = [];
        noteList.forEach(note => {
          if (note.id === noteObject.id) {
            note = noteObject
          }
          cloneNotes.push(note);
        })
        dispatch(setNotes(cloneNotes));
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      NotificationManager.warning("Kunne ikke oprette noten", "Fejl!");
      console.error(error);
    }
  }

  const setSubitemInputValue = (value) => {
    setSubitemInput(value);
    document.getElementById(`subitem-note-input-error${id}`).classList.add("hidden");
  }

  const closeSubitem = () => {
    setShowCreateSubitem(false);
    setSubitemInput("");
  }

  let subitemList = [];
  let completedSubitems = 0;
  if (subitems) {
    subitems.forEach((item, index) => {
      if (item.isComplete) completedSubitems++;
      subitemList.push(<div key={`subitem-${item.id}`} className="flex">
        <SubItem
          canEdit={true}
          subitem={item}
          completeSubitem={() => completeSubnote(item.id, true)}
          uncompleteSubitem={() => completeSubnote(item.id, false)}
          deleteSubitem={() => deleteSubitem(item.id)}
        />
      </div>)
    });
  }

  return (
    <div className="noteitem-container">
      <div className="noteitem-content">
        <div className="noteitem-row noteitem-title">
          <div><p className="no-margin">{title}</p></div>
        </div>
        <div className="noteitem-row margin-s--t">
          <div className="noteitem-html-description" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="noteitem-row margin-m--t">
          <div className="flex flex-justify--between">
            <div className="todo-item-subtitle">Checkliste</div>
            <div>{completedSubitems} / {subitems.length}</div>
          </div>
          <div className="flex flex--column">
            <div className="flex margin-s--b margin-xs--t">
              {showCreateSubitem ? <div className="flex flex--column full-width">
                <ButtonField
                  id={`subitem-note-input${id}`}
                  className="font-14"
                  type="text"
                  placeholder="Indtast beskrivelse"
                  maxLength="50"
                  value={subitemInput}
                  autoFocus={true}
                  onChange={(value) => setSubitemInputValue(value.target.value)}
                  onKeyPress={(e) => handleOnEnter(e)}
                  icon={subitemInput.length > 0 ? <CheckIcon /> : <CloseIcon />}
                  onClick={() => subitemInput.length > 0 ? createSubitem() : closeSubitem()}
                />
                <span id={`subitem-note-input-error${id}`} className="hidden font-12 color--warning">&nbsp;(Opgavens titel må ikke være tom)</span>
                <span className="font-13 color--graa4">Tegn: {subitemInput.length} / 50</span>
              </div> : <IconButton
                className="color--approve-hover"
                icon={<AddIcon style={{ color: 'var(--color--approve)' }} />}
                text={"Tilføj"}
                onClick={() => setShowCreateSubitem(true)}
              />}
            </div>
            <div>
              {subitemList}
            </div>
            <div className="flex flex-align--center flex-justify--between margin-s--t">
              <div className="flex flex-align--center">
                <span className="todo-item-subtitle font-13">Vis note på Din dag</span>
              </div>
              <div className="noteitem-switch standard-structio-switch">
                <Switch
                  checked={showOnDashboard}
                  onChange={() => showItemOnDashboard(!showOnDashboard)}
                  value={showOnDashboard}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="noteitem-row noteitem-controls line-t">
        <IconButton
          className="color--approve-hover"
          icon={<CreateIcon />}
          text={"Rediger"}
          onClick={() => setToggleModalEdit(!toggleModalEdit)}
        />
        <IconButton
          className="color--delete-hover"
          iconRight={<DeleteForeverIcon />}
          text={"Slet"}
          onClick={() => setToggleModalDelete(toggleModalDelete ? false : true)} />
      </div>
      {toggleModalEdit && <ModalEdit
        noteObject={object}
        state={toggleModalEdit}
        toggleModal={() => setToggleModalEdit(toggleModalEdit ? false : true)}
      />}
      {toggleModalDelete && <ModalDelete
        title={title}
        state={toggleModalDelete}
        toggleModal={() => setToggleModalDelete(toggleModalDelete ? false : true)}
        onClick={() => deleteNote()}
      />}
    </div>
  )
}

export default NoteItem;