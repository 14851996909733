import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Fade from '@mui/material/Fade';

// Services
import Moment from 'moment';
import ApiService from '../../services/ApiService';
import { dateNowFormatted } from '../../services/DateHandler';
import { debounce } from '../../services/FunctionHelper.tsx';
import { TODO_STATUS } from '../../services/Enums';

// Store
import { setTodoitems, setDateCache, setCalendarDate } from '../../store/features/todoitems';
import { setTodoNotifications } from '../../store/features/notifications.ts';

// Components
import Calendar from 'react-calendar';
import TodoItem from '../../components/todo/todoItem';
import Button from '../../components/button/button';
import AddTodoModal from '../../components/todo/modal/todoAdd/TodoAdd';
import EmptyItem from '../../components/todo/emptyItem';
import Spinner from '../../components/spinner/Spinner';

// Icons
import AddIcon from '@mui/icons-material/Add';

// Style
import './Todo.scss';

const Todo = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;
  const notificationsStore = useSelector((state) => state.notifications);
  const notifications = notificationsStore.todoNotifications;
  const todoitemsStore = useSelector((state) => state.todoitems);
  const todoitems = todoitemsStore.all;
  const todoitemsLoaded = todoitemsStore.todoitemsLoaded;
  const selectedCalendarDate = todoitemsStore.calendarDate;
  const dateCache = todoitemsStore.dateCache;
  /* props */
  const { TODO_TYPE } = props;
  const { stateHandlerSetAllTodos } = props;
  /* Initialize states */
  const [showPendingTodos, setShowPendingTodos] = useState(false);
  const [modalAddTodo, setModalAddTodo] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [todoType, setTodoType] = useState(TODO_TYPE);

  useEffect(() => {
    if (user.id && todoitemsLoaded && dateCache) {
      fetchTodos(selectedCalendarDate);
    }
    createWeekNumberTitle();
  }, []);

  /* Hackish way to apply "uge Nr." to the Calendar */
  const createWeekNumberTitle = () => {
    /* Check if element exists if NOT drawn the element into the calendar weekday container */
    const weekNrTitle = document.querySelector('.week-nr-title');
    if (!weekNrTitle) {
      const containerEl = document.querySelector('.react-calendar__month-view__weekNumbers');
      if (containerEl) {
        containerEl.insertAdjacentHTML('afterbegin', "<span class='week-nr-title'>Uge Nr.</span>")
      }
    }
  }

  const updateTodoList = (index, updateStatus, todoObject, skipSort) => {
    const { stateHandlerUpdateTodoList } = props;
    const cloneArray = todoitems.slice();
    switch (updateStatus) {
      case TODO_STATUS.create:
        cloneArray.push(todoObject);
        stateHandlerUpdateTodoList(cloneArray, skipSort);
        break;
      case TODO_STATUS.delete:
        cloneArray.splice(index, 1);
        stateHandlerUpdateTodoList(cloneArray, skipSort);
        break;
      default:
        cloneArray[index] = todoObject;
        stateHandlerUpdateTodoList(cloneArray, skipSort);
    }
  }

  /* Find calendar todo and paint a dot
   * It runs through all todoitems and find if theres a match on the calendar date and todoDate
   * if yes then paint a dot
   * Special case: If there's a pendingTodo then the pending-dot will overrule the normal-dot incase there's multiple tasks in same date
   */
  const findCalendarTodos = (date) => {
    let todoExist = false;
    let isPendingRes = false;
    todoitems.forEach(todo => {
      const dateCheck = Moment(todo.date).format("DD-MM-YYYY") === Moment(date).format("DD-MM-YYYY");
      const typeCheck = todo.type === todoType || todoType === "all";
      const userCheck = todo.users.find(userObject => userObject.userId === user.id);
      const isPending = userCheck ? userCheck.isAccepted === "pending" : false;
      const isDeclined = userCheck ? userCheck.isAccepted === "declined" : false;
      if (dateCheck && typeCheck && userCheck && !isDeclined) {
        if (isPending) isPendingRes = true;
        todoExist = true;
      }
    });
    const paintDotClass = isPendingRes ? 'calendar-dot-pending' : todoExist ? "calendar-dot" : "";
    return paintDotClass;
  }

  /* Find pending todo's and set a badge */
  const getPendingBadge = () => {
    return notifications.todolistPending +
      notifications.worklistPending +
      notifications.buylistPending +
      notifications.eventlistPending +
      notifications.activitylistPending +
      notifications.foodplanlistPending
  }

  let badgePending = getPendingBadge();

  // Add debouncer to prevent fetch of multiple months at the same time
  const fetchMonth = debounce((value) => {
    if (!spinnerLoading) {
      fetchTodos(value);
    }
  }, 400);

  /**
   * Fetches todos for a specific month and caches that date so it wouldn't be fetched again.
   * NOTE: backend also fetches ALL todos with isAccepted = Pending
   */
  const fetchTodos = async (value) => {
    setSpinnerLoading(true);
    const dateNowRes = dateNowFormatted();
    const dateFromRes = Moment(value).format("YYYY-MM-01 00:00:00");
    const cachedDate = dateCache.includes(dateFromRes);
    if (!cachedDate) {
      dispatch(setDateCache(dateFromRes));
      await ApiService.getTodoItems(dateNowRes, dateFromRes).then(res => {
        let allTodos = [...todoitems, ...res];
        allTodos = allTodos.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        dispatch(setTodoitems(allTodos));
        dispatch(setTodoNotifications({ todoitems: allTodos, userId: user.id }))
        setSpinnerLoading(false);
        localStorage.setItem("currentSelectedDate", dateFromRes);
      });
    } else {
      setSpinnerLoading(false);
    }
  }

  let todos = [];
  let todosPending = [];
  let todosPendingBadge = 0;
  let todosCompleted = 0;
  todoitems.forEach((todo, index) => {
    const type = todo.type === todoType || todoType === "all";
    if (type) {
      const selectedDay = Moment(todo.date).format("DD-MM-YYYY") === Moment(selectedCalendarDate).format("DD-MM-YYYY");
      const userObject = todo.users.find(userObject => userObject.userId === user.id);
      const isPending = userObject ? userObject.isAccepted === "pending" : false;
      if (selectedDay) {
        const isDeclined = userObject ? userObject.isAccepted === "declined" : false;
        const privateForUser = todo.isPrivate === 1 && !userObject;
        if (!privateForUser && !isDeclined) {
          if (todo.isComplete === 1) todosCompleted++;
          todos.push(<TodoItem key={'todoitem' + index}
            object={todo}
            userObject={userObject}
            index={index}
            updateTodoList={updateTodoList}
            canEdit={userObject ? true : false}
            isPending={isPending}
          />)
        }
      }
      // Add todos to pending list
      if (isPending) {
        todosPendingBadge++;
        todosPending.push(<TodoItem key={'todoitem' + index}
          object={todo}
          userObject={userObject}
          index={index}
          updateTodoList={updateTodoList}
          canEdit={false}
        />)
      }
    }
  })
  return (
    <Fade in timeout={1000}>
      <div className="todo-content-container">
        <div className="todo-content">
          <div className="row color--text">
            <div className="flex flex-justify--center flex-align--center full-width">
              <div className="todo-title">
                <div className="flex align--center margin-s--b">
                  <span className="title font-30">Opgaver</span>
                </div>
                <div>
                  <Button className="structio margin-m--b" icon={<AddIcon className="todo-svg" />} text="Opret opgave" onClick={() => setModalAddTodo(modalAddTodo ? false : true)} />
                </div>
                <div>
                  <Button
                    onClick={() => setShowPendingTodos(showPendingTodos ? false : true)}
                    text={!showPendingTodos ? 'Afventende opgaver' : 'Din kalender'}
                    badge={!showPendingTodos ? badgePending : 0}
                    badgeColor="red"
                  />
                </div>
              </div>
            </div>
          </div>
          {showPendingTodos ?
            <div className="todo-pending">
              <span className="header-title text-left margin-xs--b">Afventende opgaver</span>
              {todosPending.length > 0 ? todosPending :
                <EmptyItem
                  text="Du har ingen afventende opgaver!"
                  component={<Button className="button margin-s--t" text="Din kalender" type="add" onClick={() => setShowPendingTodos(showPendingTodos ? false : true)} />}>
                </EmptyItem>
              }
            </div>
            :
            <div className="todo-cells">
              <div className="todo-calendar">
                <div className="pos-relative flex flex--column flex-align--center">
                  <Calendar
                    className="full-width"
                    value={new Date(selectedCalendarDate)}
                    onChange={(value) => dispatch(setCalendarDate(value.toString()))}
                    tileClassName={({ date, view }) => findCalendarTodos(date)}
                    onActiveDateChange={(value) => fetchMonth(value.activeStartDate)} /* use this to fetch monthly data */
                    locale="DA"
                    maxDetail="month" /* Only makes it possible to view "month"-view */
                    minDetail='month'
                    showNeighboringMonth={false} /* Only shows the dates from the current month */
                    showWeekNumbers={true}
                  />
                  {spinnerLoading && <Spinner inline={true} delay={400} />}
                </div>
              </div>
              <div className="todo-cell text-left">
                <div className="header-title margin-xs--b" style={{ visibility: todos.length > 0 ? 'visible' : 'hidden' }}>Opgaver {todosCompleted} / {todos.length}</div>
                {todos.length > 0 ? todos : <EmptyItem className="margin-s" text="Ingen opgaver på denne dag" />}
              </div>
            </div>
          }
        </div>
        {modalAddTodo && <AddTodoModal
          TODO_TYPE={todoType}
          state={modalAddTodo}
          setState={() => setModalAddTodo(modalAddTodo ? false : true)}
          stateHandlerSetAllTodos={stateHandlerSetAllTodos}
          selectedDate={selectedCalendarDate}
        />}
      </div>
    </Fade>
  )
}

export default Todo;
