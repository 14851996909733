import React from 'react';

// Services

// Components

// Icons
import { SentimentVerySatisfied } from '@mui/icons-material';

// Style
import './EmptyItem.scss';

const EmptyItem = (props) => {
  const { className, component, text } = props; // props
  return (
    <div className={`todo-item--empty padding-l ${className}`}>
      <div className="emptyitem-completed-icon"><SentimentVerySatisfied className="type-svg" /></div>
      <div>
        <span className="opacity--50">{text}</span>
      </div>
      {component}
    </div>
  );
};

export default EmptyItem;
