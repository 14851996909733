import React from 'react';

// Components

// Icons

// Style
import './Icon.scss';

const Icon = (props) => {
    const { className, width = "100%", height = "100%", color = "structio", children } = props

    return (
        <div className={`icon-container ${className}`} style={{ '--icon-width': `${width}`, '--icon-height': `${height}`, '--icon-color': `${color}` }}>
            {children}
        </div>
    )
}

export default Icon;
