import React from 'react';

// Components

// Icons

// Style
import './InputField.scss';

/** @ViableTypes for props
 ** id - Adds a id to the input
 ** className - String: Custom classNames
 ** type - type of inputfield
 ** placeholder - inputfields placeholder
 ** value - value assorciated with inputfield
 ** onChange - Func: changes value of inputfield
 ** onKeyPress - Func: Gets executed everytime a key is pressed - i.e check enter press.
 ** maxLength - string: number of chars in inputfield
 */

const InputField = (props) => {
    /* data */
    const { id, className, type, placeholder, value, maxLength, autocomplete = false } = props
    /* functions */
    const { onChange, onKeyPress } = props

    return (
        <input id={id} autoComplete={autocomplete ? "on" : "off"} className={`standard-input ${className}`} type={type} placeholder={placeholder} value={value ? value : ''} onChange={onChange} maxLength={maxLength} onKeyPress={onKeyPress} />
    )
}

export default InputField;
