import { createSlice } from "@reduxjs/toolkit";
// Types
import type { INotification } from "../../types";

import { checkNotificationPermission } from "../../services/FunctionHelper.tsx";

// Define a TypeScript type for your state
interface NotificationState {
  notifications: INotification[];
  notificationPermission:
    | "none"
    | "granted"
    | "default"
    | "denied"
    | "notEligible"; // Define the possible values
  todoNotifications: {
    todolistPending: number;
    worklistPending: number;
    buylistPending: number;
    eventlistPending: number;
    activitylistPending: number;
    foodplanlistPending: number;
  };
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    notificationPermission: "none",
    todoNotifications: {
      todolistPending: 0,
      worklistPending: 0,
      buylistPending: 0,
      eventlistPending: 0,
      activitylistPending: 0,
      foodplanlistPending: 0,
    },
  } as NotificationState,
  reducers: {
    getNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    getNotificationPermission: (state) => {
      const permission = checkNotificationPermission();
      state.notificationPermission = permission;
    },
    /* action need to contain: {todoitems: res, userId: user.id} */
    setTodoNotifications: (state, action) => {
      let todolistPending = 0;
      let worklistPending = 0;
      let buylistPending = 0;
      let eventlistPending = 0;
      let activitylistPending = 0;
      let foodplanlistPending = 0;
      if (!action.payload.todoitems) return;
      action.payload.todoitems.forEach((todo) => {
        const userObject = todo.users.find(
          (userObject) => userObject.userId === action.payload.userId
        );
        const isPending = userObject
          ? userObject.isAccepted === "pending"
          : false;
        if (isPending) {
          switch (todo.type) {
            case "todo":
              todolistPending++;
              break;
            case "work":
              worklistPending++;
              break;
            case "shop":
              buylistPending++;
              break;
            case "event":
              eventlistPending++;
              break;
            case "sport":
              activitylistPending++;
              break;
            case "food":
              foodplanlistPending++;
              break;
          }
        }
        //Lav function der løber alle todo listes igennem og tilknytter pendings ud fra todoItems (ligesom under todos.jsx pending)
      });
      state.todoNotifications.todolistPending = todolistPending;
      state.todoNotifications.worklistPending = worklistPending;
      state.todoNotifications.buylistPending = buylistPending;
      state.todoNotifications.eventlistPending = eventlistPending;
      state.todoNotifications.activitylistPending = activitylistPending;
      state.todoNotifications.foodplanlistPending = foodplanlistPending;
    },
    getTodolistPending: (state, action) => {
      state.todoNotifications.todolistPending = action.payload;
    },
    getWorklistPending: (state, action) => {
      state.todoNotifications.worklistPending = action.payload;
    },
    getBuylistPending: (state, action) => {
      state.todoNotifications.buylistPending = action.payload;
    },
    getEventlistPending: (state, action) => {
      state.todoNotifications.eventlistPending = action.payload;
    },
    getActivitylistPending: (state, action) => {
      state.todoNotifications.activitylistPending = action.payload;
    },
    getFoodplanlistPending: (state, action) => {
      state.todoNotifications.foodplanlistPending = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getNotifications,
  getNotificationPermission,
  setTodoNotifications,
  getTodolistPending,
  getWorklistPending,
  getBuylistPending,
  getEventlistPending,
  getActivitylistPending,
  getFoodplanlistPending,
} = notificationSlice.actions;

export default notificationSlice.reducer;
