import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'

// Services
import AuthService from '../../../../../services/Auth';

// Notifications
import { NotificationManager } from 'react-notifications';

// Components
import UserItem from '../../../../../components/listItem/userItem';
import IconButton from '../../../../../components/button/iconButton';

// Icons
import { DeleteForever } from '@mui/icons-material';

// Store
import { setFriendlist } from '../../../../../store/features/friendlist';

const Requestlist = (props) => {
    /* Store */
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    const friendlistStore = useSelector((state) => state.friendlist);
    const requestlist = friendlistStore.requestlist;

    const onClickWithdrawFriendRequest = (friendId) => {
        const data = {
            friendId: friendId,
        };
        axios.delete(`${process.env.REACT_APP_API_URL}/user/removefriend`, AuthService.configWithData(data))
            .then((response) => {
                dispatch(setFriendlist(response.data));
            })
            .catch((error) => {
                console.error(error);
                NotificationManager.warning('Kan ikke tilbagetrække din anmodning på nuværende tidspunkt', 'Fejl!');
            });
    }

    let requestList = [];
    if (requestlist) {
        requestlist.forEach(friend => {
            if (friend.requestByMe === 1 && friend.isAccepted === 0) {
                requestList.push(<div key={`connectedUser ${friend.id}`} className="flex flex-justify--between">
                    <UserItem
                        key={`friendlist-user-${friend.id}`}
                        name={`${friend.firstname} ${friend.lastname}`}
                        picture={friend.pictureUrl}
                    />
                    <div className="flex align-center-items padding-s--b">
                        <IconButton
                            className="color--delete-hover"
                            icon={<DeleteForever />}
                            onClick={() => onClickWithdrawFriendRequest(friend.id)}
                        />
                    </div>
                </div>)
            }
        })
    }
    return (
        <div className="full-width">
            <div className="row flex justify-space-between profile-title padding-m--t">
                <span>Sendte anmodninger</span>
            </div>
            <div className="row align-items-center line">
                <div className="col profile-text padding-m--t">
                    {requestList.length > 0 ? requestList : <span>Ingen sendte anmodninger</span>}
                </div>
            </div>
        </div>
    );
}

export default Requestlist;
