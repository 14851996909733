import React from 'react';

// Components - Material UI
import Switch from '@mui/material/Switch';

// Components

// Icons

// Style
import './SwitchButton.scss';

/** @ViableTypes for props
 *
 ** size -- string: switch-small | switch-medium(default) | switch-large
 */

const SwitchButton = (props) => {
    const { size, checked } = props;

    const { onChange } = props;

    return (
        <div className={`savebutton-content-container ${size ? size : "switch-medium"}`}>
            <Switch
                checked={checked ? true : false}
                onChange={onChange}
                value={checked}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        </div>
    )
}
export default SwitchButton;
