import { createSlice } from "@reduxjs/toolkit";

// Services
import ApiService from "../../services/ApiService";

// Define a TypeScript type for your state
type TThemeType = "light" | "dark";

interface ThemeState {
  theme: TThemeType;
}

interface ISetTheme {
  payload: {
    theme: TThemeType;
  };
}

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: "dark",
  } as ThemeState,
  reducers: {
    // Sets the theme and saves it in DB on user.theme
    setTheme: (state, action: ISetTheme) => {
      const selectedThemeColor =
        action.payload.theme === "light" ? "dark" : "light";
      document.documentElement.classList.add("color-theme-in-transition");
      document.documentElement.setAttribute("data-theme", selectedThemeColor);
      const metaThemeColor =
        action.payload.theme === "dark" ? "#0c1414" : "#fff";
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", metaThemeColor);
      window.setTimeout(() => {
        document.documentElement.classList.remove("color-theme-in-transition");
      }, 1000);
      // Update User theme in DB
      ApiService.changeTheme(selectedThemeColor);
      // Set state of theme
      state.theme = selectedThemeColor;
    },

    /* Updates the theme in frontend */
    updateTheme: (state, action: ISetTheme) => {
      // Set state of theme
      state.theme = action.payload.theme;
      // sets the theme-color
      document.documentElement.setAttribute(
        "data-theme",
        `${action.payload.theme}`
      );
      const metaThemeColor =
        action.payload.theme === "dark" ? "#0c1414" : "#fff";
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", metaThemeColor);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTheme, updateTheme } = themeSlice.actions;

export default themeSlice.reducer;
