import React, { useState } from 'react';

// Services

// Store

// Components - Material UI
import Fade from '@mui/material/Fade';

// Components
import LoginContent from './content/LoginContent';
import SignupContent from './content/SignupContent';
import RecoverContent from './content/RecoverContent';

// Icons

// Style
import './Login.scss';

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const Login = (props) => {
  /* Store */
  /* Props */
  const { } = props
  /* Initialize states */
  const [content, setContent] = useState('login');

  const redirect = (redirectTo) => {
    setContent(redirectTo);
  }

  return (
    <Fade in timeout={1000}>
      <div className="login-container">
        <div className="login-background">
          <div className="bg-effect"></div>
          <div className="bg-picture"></div>
        </div>
        <div className="login-content">
          {content === "login" ? <LoginContent redirect={redirect} /> : null}
          {content === "signup" ? <SignupContent redirect={redirect} /> : null}
          {content === "recover" ? <RecoverContent redirect={redirect} /> : null}
        </div>
      </div>
    </Fade>
  )
}

export default Login;
