import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Components
import {
  Notifications as NotificationIcon, Description as NotesIcon, Person as ProfileIcon, LibraryBooks as AllIcon, Dashboard as DashboardIcon
} from "@mui/icons-material";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Badge from '@mui/material/Badge';

// Store
import { setCalendarDate } from '../../../store/features/todoitems';
import { getNotificationPermission } from '../../../store/features/notifications.ts';

// Style
import './BottomMenu.scss';

const BottomMenu = (props) => {
  /* Intialize writing Store */
  const dispatch = useDispatch();
  /* Store */
  const userStore = useSelector((state) => state.user);
  const user = userStore.user;

  const notificationsStore = useSelector((state) => state.notifications);
  const notificationsTodos = notificationsStore.todoNotifications;
  const notifications = notificationsStore.notifications;
  const notificationPermission = notificationsStore.notificationPermission;

  const todoitemsStore = useSelector((state) => state.todoitems);
  const todayslist = todoitemsStore.todayslist;
  const today = new Date().setHours(0, 0, 0); // Used to navigate to allTodos and todays date will be selected

  const friendlistStore = useSelector((state) => state.friendlist);
  const pendinglist = friendlistStore.pendinglist;
  /* Initialize states */
  const [menuSelected, setMenuSelected] = useState(0);
  const [location, setLocation] = useState(window.location.pathname.split("/")[1]);


  useEffect(() => {
    getCurrentLocation(location);
    props.history.listen((newLocation, action) => {
      getCurrentLocation(newLocation.pathname.split("/")[1]);
    });

    // Check push notification
    dispatch(getNotificationPermission());
  }, []);

  const getCurrentLocation = (location) => {
    switch (location) {
      case ("alltodos"):
        setMenuSelected(1);
        setLocation(location);
        break;
      case ("notes"):
        setMenuSelected(2);
        setLocation(location);
        break;
      case ("notifications"):
        setMenuSelected(3);
        setLocation(location);
        break;
      case ("user"):
        setMenuSelected(4);
        setLocation(location);
        break;
      default:
        setMenuSelected(0);
        setLocation("/");
    }
  }

  let unreadNotifications = 0;
  if (notifications) {
    notifications.forEach(item => {
      if (item.isRead === 0) unreadNotifications++
    });
  }

  let badgeToday = 0;
  todayslist.forEach(item => {
    const acceptedCheck = item.users.find(userObject => userObject.isAccepted === "accepted" && userObject.userId === user.id); // check if user has accepted the todo
    const notComplete = !item.isComplete;
    if (acceptedCheck && notComplete) {
      badgeToday++;
    }
  });

  const getPendingBadge = () => {
    return notificationsTodos.todolistPending +
      notificationsTodos.worklistPending +
      notificationsTodos.buylistPending +
      notificationsTodos.eventlistPending +
      notificationsTodos.activitylistPending +
      notificationsTodos.foodplanlistPending
  }

  let badgePending = getPendingBadge();

  const badgeProfile = pendinglist.length;
  return (
    <BottomNavigation
      value={menuSelected}
      onChange={(event, newValue) => setMenuSelected(newValue)}
      showLabels
      className={'bottom-navbar-container box-shadow'}
    >
      <BottomNavigationAction className="bottom-menu-tab" component={Link} to={`${process.env.PUBLIC_URL}/`} label="Din dag" icon={<Badge overlap="rectangular" className="menu-bagde green" badgeContent={badgeToday}><DashboardIcon /></Badge>} />
      <BottomNavigationAction className="bottom-menu-tab" component={Link} to={`${process.env.PUBLIC_URL}/alltodos`} label="Opgaver" icon={<Badge overlap="rectangular" className="menu-bagde red" badgeContent={badgePending}><AllIcon /></Badge>} onClick={() => dispatch(setCalendarDate(today))} />
      <BottomNavigationAction className="bottom-menu-tab" component={Link} to={`${process.env.PUBLIC_URL}/notes`} label="Noter" icon={<NotesIcon />} />
      <BottomNavigationAction className="bottom-menu-tab" component={Link} to={`${process.env.PUBLIC_URL}/notifications`} label="Notifikationer" icon={<Badge overlap="rectangular" className="menu-bagde red" badgeContent={notificationPermission === "granted" ? unreadNotifications : "!"}><NotificationIcon /></Badge>} />
      <BottomNavigationAction className="bottom-menu-tab" component={Link} to={`${process.env.PUBLIC_URL}/user`} label="Profil" icon={<Badge overlap="rectangular" className="menu-bagde" badgeContent={badgeProfile} color="error"><ProfileIcon /></Badge>} />
    </BottomNavigation>
  )
}

export default withRouter(BottomMenu);
