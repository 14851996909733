import { createSlice } from "@reduxjs/toolkit";

// Services

export const notesSlice = createSlice({
  name: "notes",
  initialState: {
    notes: [],
  },

  reducers: {
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNotes } = notesSlice.actions;

export default notesSlice.reducer;
