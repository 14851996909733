import React from 'react';

// Components - Material UI

// Components
import HexagonPicture from '../../picture/hexagon';

// Icons
import { Check, Close, HelpOutline } from '@mui/icons-material';

// Style
import './UserItem.scss';

/** @ViableTypes for props
 ** className - Possible to add custom classNames to the component
 ** name - Name on the user
 ** picture - Picture on the user if have any
 ** pictureSize - Values Large | Medium | Small
 ** onIconClick - IconElement with onClick
 ** inactive - Add 50% opacity to the name
 ** itemStatus - if set adds a icon to the right ( accepted | declined | pending )
 */

const UserItem = (props) => {
    /* data */
    const { className, name, picture, onClickIcon, inactive, pictureSize, itemStatus } = props;
    /* functions */

    let pictureComponent = <HexagonPicture
        name={name}
        size={pictureSize ? pictureSize : "medium"} />
    if (picture) {
        pictureComponent = <HexagonPicture
            picture={picture}
            size={pictureSize ? pictureSize : "medium"}
        />
    }

    const getStatusIcon = (status) => {
        switch(status) {
            case "accepted":
                return <Check style={{ color: 'var(--color--approve)' }} />;
            case "declined":
                return <Close style={{ color: 'var(--color--delete)' }} />;
            case "pending":
                return <HelpOutline style={{ color: 'var(--color--graa6)' }} />;
            default:
                return;
        }
    }

    return (
        <div className={`user-item full-width flex flex-justify--between padding-s--b ${className}`}>
            <div className="flex flex-align--center">
                {pictureComponent}
                <div className={pictureSize === "small" ? "margin-xs--l" : "margin-m--l"}>
                    <span className={`${inactive ? "opacity--50" : ""}`}>{name}</span>
                </div>
            </div>
            <div className="flex flex-align--center padding-m--l">
                {getStatusIcon(itemStatus)}
                {onClickIcon}
            </div>
        </div>
    )
}
export default UserItem;
