import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Switch from "@mui/material/Switch";
// Types
import type { RootState } from "../../../../store/store.ts";
// Services
import ApiService from "../../../../services/ApiService.jsx";
import AuthService from "../../../../services/Auth.jsx";
// Icons
import { Close, WbSunny, Brightness2 } from "@mui/icons-material";
// Components
import IconButton from "../../../button/iconButton/index.js";
import MenuButton from "../../../button/menuButton/index.js";
import Logo from "../../../../assets/default_images/structio-logo-with-text-alfa.png";
// Store
import { setIsLoading } from "../../../../store/features/spinner.js";
import { setTheme } from "../../../../store/features/theme.ts";
// Style
import "./DropdownModal.scss";

const DropdownModal = (props) => {
  /* Store */
  const dispatch = useDispatch();
  const userStore = useSelector((state: RootState) => state.user);
  const user = userStore.user;
  const themeStore = useSelector((state: RootState) => state.theme);
  const themeState = themeStore.theme;
  /* data */
  const { state } = props;
  /* functions */
  const { closeModal } = props;

  const logout = async () => {
    dispatch(setIsLoading(true));
    await ApiService.removePushDevice();
    AuthService.deleteCookies();
    window.location.reload();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="structio-modal-container"
      open={state}
      onClose={closeModal}
      closeAfterTransition
    >
      <Fade in={state}>
        <div className="structio-modal-box">
          <div className="topmenu-content close-topbar-downdrop">
            <div className="flex modal-picture no-padding">
              <img alt="Structio Logo" src={Logo} />
            </div>
            <div className="flex flex-justify--end">
              <IconButton icon={<Close />} onClick={closeModal} effect />
            </div>
          </div>
          <div className="topmenu-content borderline-top">
            <div className="name flex flex--center">
              <span>
                {user.firstname} {user.lastname}
              </span>
            </div>
            <div className="text-center">
              <span>
                <span className="opacity--50">profil-ID:</span> {user.firstname}
                #{user.id}
              </span>
            </div>
          </div>
          <div className="topmenu-content borderline-top">
            <div className="modal-menu-title">
              <span>Din menu</span>
            </div>
            <div className="menu-btn">
              <Link
                className="link anchor-no-line"
                to={`${process.env.PUBLIC_URL}/user`}
              >
                <MenuButton text="Profil" height="50px" onClick={closeModal} />
              </Link>
            </div>
            <div className="menu-btn logout">
              <MenuButton
                text="Log ud"
                height="50px"
                onClick={() => logout()}
              />
            </div>
          </div>
          <div className="topmenu-content footer borderline-top">
            <div className="flex flex--center title">
              <span>Tema</span>
            </div>
            <div className="topbar-theme-changer">
              <span>Lys&nbsp;</span>
              <WbSunny
                className={`light-theme ${
                  themeState === "light" ? "light-active" : ""
                }`}
              />
              <div className="switch-content-container">
                <Switch
                  checked={themeState === "dark" ? true : false}
                  onChange={() =>
                    dispatch(
                      setTheme({
                        theme: themeState,
                      })
                    )
                  }
                  value={themeState}
                />
              </div>
              <Brightness2
                className={`dark-theme ${
                  themeState === "dark" ? "dark-active" : ""
                }`}
              />
              <span>&nbsp;Mørk</span>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DropdownModal;
