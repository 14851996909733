import React from 'react';

// Components - Material UI
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// Components
import Button from '../../../button/button/Button';

// Icons
import Clear from '@mui/icons-material/Clear';

// Style
import './TodoDelete.scss';

class TodoDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { state, title } = this.props; // props
        const { setState, onClick } = this.props; // func
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={'structio-modal-container'}
                open={state}
                onClose={setState}
                closeAfterTransition
            >
                <Fade in={state}>
                    <div className="structio-modal-box">
                        <button className="icon-btn close-modal-btn" onClick={setState}><Clear /></button>
                        <h1 className="margin-s--tb color--structio">Slet opgave</h1>
                        <div className="line" />
                        <span className="title-text flex-justify--center margin-m--t">Er du sikker på at du vil slette følgende opgave</span>
                        <div className="flex flex-justify--center margin-m--b">
                            <span className="text-color-standard font-24">{title}</span>
                        </div>
                        <div className="flex flex-justify--center">
                            <Button
                                className="margin-s"
                                type="delete"
                                text={'Slet opgave'}
                                onClick={onClick}
                            />
                            <Button
                                className="margin-s"
                                text={'Annuler'}
                                onClick={setState}
                            />
                        </div>
                    </div>
                </Fade>
            </Modal>
        )
    }
}
export default TodoDelete;
