import React from 'react';

// Components - Material UI

// Components

// Style
import './Background.scss';

const Background = (props) => {
  return (
    <div>
      <div className="background" />
      <div className="background-transparent-overlay" />
    </div>
  );
}

export default Background;
