import React from 'react';

// Components - Material UI

// Components
import IconButton from '../../../components//button/iconButton';

// Icons
import { CheckBox, CheckBoxOutlineBlank, DeleteForever } from '@mui/icons-material';

// Style
import './SubItem.scss';

/** @ViableTypes for props
 ** className - Possible to add custom classNames to the component
 ** subitem - Object of an subitem
 */

const SubItem = (props) => {
    /* data */
    const { className, subitem, canEdit } = props;
    const { description, isComplete } = subitem;
    /* functions */
    const { completeSubitem, uncompleteSubitem, deleteSubitem } = props;

    return (
        <div className={`flex flex-justify--between full-width padding-s--b ${className}`}>
            {canEdit ?
                <div className="flex flex-justify--between full-width">
                    <div className="flex flex-align--center">
                        <div className="flex flex-align--center">
                            {isComplete ? <IconButton
                                className="color--approve"
                                iconRight={<CheckBox />}
                                onClick={uncompleteSubitem} /> :
                                <IconButton
                                    className="color--approve"
                                    iconRight={<CheckBoxOutlineBlank />}
                                    onClick={completeSubitem} />}
                        </div>
                        <div className="flex flex-align--center padding-s--l">
                            <span className={isComplete ? "text--linethrough opacity--50" : ""}>{description}</span>
                        </div>
                    </div>
                    <div className="flex flex-align--center">
                        <IconButton
                            className="color--delete-hover opacity--50"
                            iconRight={<DeleteForever />}
                            onClick={deleteSubitem} />
                    </div>
                </div>
                :
                <div className="flex flex-align--center">
                    <div className="flex flex-align--center">
                        {isComplete ? <IconButton
                            className="color--approve"
                            iconRight={<CheckBox />}
                        /> :
                            <IconButton
                                className="color--approve"
                                iconRight={<CheckBoxOutlineBlank />}
                            />}
                    </div>
                    <div className="flex flex-align--center padding-s--l">
                        <span className={isComplete ? "text--linethrough opacity--50" : ""}>{description}</span>
                    </div>
                </div>
            }
        </div>
    )
}
export default SubItem;
