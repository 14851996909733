import React, { useEffect, useState } from 'react';

// Services
import Moment from 'moment';
import 'moment/locale/da';

// Components - Material UI

// Components

// Icons

// Style
import './DashboardDate.scss';

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const DashboardAddFriend = (props) => {
    /* Store */

    /* props */
    const { } = props
    /* Initialize states */
    const [timeMins, setTimeMins] = useState(0);
    const [timeHours, setTimeHours] = useState(0);
    const [weekDay, setWeekday] = useState(0);
    const [todayDate, setTodayDate] = useState(0);

    useEffect(() => {
        getTime();
        const startTimeChecker = setInterval(getTime, 1000);
        const now = Moment().isoWeek();
        const getTodayDate = Moment().format("ddd D. MMM");
        setWeekday(now);
        setTodayDate(getTodayDate);
        return () => clearInterval(startTimeChecker); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);

    const getTime = () => {
        const now = new Date();
        const curMins = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
        setTimeMins(curMins);
        const curHour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
        setTimeHours(curHour);
    }
    return (
        <div className="dashboard-date-info-container line">
            <div>
                <div className="header-title">Klokken</div>
                <div className="font-20">{timeHours}<span className="time-blinking">:</span>{timeMins}</div>
            </div>
            <div>
                <div className="header-title">Dato</div>
                <div className="font-20">{todayDate}</div>
            </div>
            <div>
                <div className="header-title">Uge Nr.</div>
                <div className="font-20">{weekDay}</div>
            </div>
        </div>
    )
}

export default DashboardAddFriend;