import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user.ts";
import friendlistReducer from "./features/friendlist";
import notificationReducer from "./features/notifications.ts";
import spinnerReducer from "./features/spinner";
import themeReducer from "./features/theme.ts";
import todoitemsReducer from "./features/todoitems";
import notesReducer from "./features/notes";

// Define a RootState interface for the entire Redux state
interface IRootState {
  user: ReturnType<typeof userReducer>; // ReturnType<typeof reducerFunction> gives the return type of the reducer function
  friendlist: ReturnType<typeof friendlistReducer>;
  notifications: ReturnType<typeof notificationReducer>;
  spinner: ReturnType<typeof spinnerReducer>;
  theme: ReturnType<typeof themeReducer>;
  todoitems: ReturnType<typeof todoitemsReducer>;
  notes: ReturnType<typeof notesReducer>;
}

// Define your rootReducer with the combineReducers function
const rootReducer = combineReducers<IRootState>({
  user: userReducer,
  friendlist: friendlistReducer,
  notifications: notificationReducer,
  spinner: spinnerReducer,
  theme: themeReducer,
  todoitems: todoitemsReducer,
  notes: notesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const reducers = configureStore({
  reducer: {
    user: userReducer,
    friendlist: friendlistReducer,
    notifications: notificationReducer,
    spinner: spinnerReducer,
    theme: themeReducer,
    todoitems: todoitemsReducer,
    notes: notesReducer,
  },
});

export default reducers;
