import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Services
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

// Store
import { setIsLoading } from '../../../store/features/spinner';

// Components
import { NotificationManager } from 'react-notifications';
import InputField from '../../../components/inputField/';
import Button from '../../../components/button/button/';
import StructioLogo from '../../../assets/default_images/structio-logo-with-text-alfa.png';

// Icons

// Style

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const RecoverPassword = (props) => {
    /* Store */
    const dispatch = useDispatch();
    /* data */
    const { } = props
    /* functions */
    const { redirect } = props
    /* Initialize states */
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleOnEnter = (e) => {
        if (e.key === 'Enter') {
            onClickRecoverPassword(e, email);
        }
    }

    const onChangeEmail = (value) => {
        setEmail(value);
        document.getElementById("email-input-field").classList.remove("input-error");
        document.getElementById("email-input-error").className = "hidden";
    }

    const onClickRecoverPassword = (e, email) => {
        if (email !== "") {
            recoverPassword(e, email);
        } else {
            setEmailError('Ugyldig email');
            document.getElementById("email-input-field").classList.add("input-error");
            document.getElementById("email-input-error").className = "color--warning font-12 show";
        }
    }

    const recoverPassword = async (event, email) => {
        event.preventDefault();
        dispatch(setIsLoading(true));
        grecaptcha.ready(function () {
            grecaptcha.execute(`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, { action: 'submit' }).then(function (token) {
                const authentication = getAuth();
                sendPasswordResetEmail(authentication, email).then((response) => {
                    NotificationManager.success('Husk at kigge i spam, hvis du ikke kan finde emailen.', 'Du har nu modtaget en email til at genskabe din adgangskode. ', 20000);
                    redirect("login");
                    dispatch(setIsLoading(false));
                }).catch((error) => {
                    if (error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found') {
                        setEmailError('Ugyldig email');
                        document.getElementById("email-input-field").classList.add("input-error");
                        document.getElementById("email-input-error").className = "color--warning font-12 show";
                    }
                    dispatch(setIsLoading(false));
                    console.error(error);
                });
            });
        });
    };

    return (
        <div className="login-form">
            <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--b">
                <img src={StructioLogo} alt="Structio Logo" style={{ maxWidth: 220 + 'px' }} />
            </div>
            <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--tb">
                <h1 className="color--text no-margin">Nulstil din adgangskode</h1>
            </div>
            <div className="flex flex--column">
                <span id="login-email" className="title-text font-14">Email</span>
                <InputField id="email-input-field" className="font-16 line" type="email" placeholder="Indtast email" maxLength="50" value={email} onChange={(value) => onChangeEmail(value.target.value)} onKeyPress={(e) => handleOnEnter(e)} />
                <span id="email-input-error" className="hidden font-12 color--warning">{emailError}</span>
            </div>
            <div className="col text-center padding-m--t">
                <Button
                    className="structio"
                    text={'Nulstil'}
                    onClick={(e) => onClickRecoverPassword(e, email)}
                />
                <Button
                    className="login-btn margin-s--t"
                    text={'Login'}
                    onClick={() => redirect("login")}
                />
            </div>
        </div>
    )
}

export default RecoverPassword;
