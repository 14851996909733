import React from "react";

import Moment from "moment";

// Types
import type { TType } from "../types/index.ts";
import { GENDERTYPES } from "../types/index.ts";

// Components
import TodoIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";
import ShoppingIcon from "@mui/icons-material/ShoppingCart";
import EventIcon from "@mui/icons-material/EventNote";
import SportIcon from "@mui/icons-material/DirectionsRun";
import DinnerIcon from "@mui/icons-material/Restaurant";

/**
 * Add a eventListener to window that check window size on every resize
 * Sets to mobileView if under 768px
 */

export const windowSizeListener = () => {
  window.addEventListener(
    "resize",
    () => {
      return window.innerWidth < 768;
    },
    false
  );
  return window.innerWidth < 768;
};
/* Set cookie */
export const setCookie = (name: string, value: string, days: number) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

/* Get cookie */
export const getCookie = (cname: string) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

/* delete cookie */
export const deleteCookie = (name: string) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (
  callback: (e: any) => void,
  wait: number,
  immediate?: boolean
) => {
  let timeout: any;
  return (...passedArgumentsargs) => {
    const args = passedArgumentsargs;
    const later = () => {
      timeout = null;
      if (!immediate) {
        callback.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      callback.apply(this, args);
    }
  };
};

export const todoTypeOptions = [
  {
    value: "todo",
    label: (
      <span className="todo-type-select-item">
        <TodoIcon style={{ fill: "var(--color--structio)" }} /> Huskeliste
      </span>
    ),
  },
  {
    value: "work",
    label: (
      <span className="todo-type-select-item">
        <WorkIcon style={{ fill: "var(--color--structio)" }} /> Arbejdsliste
      </span>
    ),
  },
  {
    value: "shop",
    label: (
      <span className="todo-type-select-item">
        <ShoppingIcon style={{ fill: "var(--color--structio)" }} /> Indkøbsliste
      </span>
    ),
  },
  {
    value: "event",
    label: (
      <span className="todo-type-select-item">
        <EventIcon style={{ fill: "var(--color--structio)" }} /> Begivenheder
      </span>
    ),
  },
  {
    value: "sport",
    label: (
      <span className="todo-type-select-item">
        <SportIcon style={{ fill: "var(--color--structio)" }} /> Aktiviteter
      </span>
    ),
  },
  {
    value: "food",
    label: (
      <span className="todo-type-select-item">
        <DinnerIcon style={{ fill: "var(--color--structio)" }} /> Madplan
      </span>
    ),
  },
];

/* All values are in minutes i.e 1440 = 24 hours */
export const reminderOptions = [
  {
    value: "none",
    label: <span className="todo-type-select-item">På start tidspunkt</span>,
  },
  {
    value: 5,
    label: <span className="todo-type-select-item">5 minutter før</span>,
  },
  {
    value: 15,
    label: <span className="todo-type-select-item">15 minutter før</span>,
  },
  {
    value: 30,
    label: <span className="todo-type-select-item">30 minutter før</span>,
  },
  {
    value: 60,
    label: <span className="todo-type-select-item">1 time før</span>,
  },
  {
    value: 120,
    label: <span className="todo-type-select-item">2 timer før</span>,
  },
  {
    value: 300,
    label: <span className="todo-type-select-item">5 timer før</span>,
  },
  {
    value: 1440,
    label: <span className="todo-type-select-item">24 timer før</span>,
  },
];

/* All values are in minutes i.e 1440 = 24 hours */
export const reminderOptionsText = (date: Date, reminderDate: Date) => {
  const reminderInMinutes = reminderOptionsValue(date, reminderDate);
  switch (reminderInMinutes) {
    case 5:
      return "5 minutter før";
    case 15:
      return "15 minutter før";
    case 30:
      return "30 minutter før";
    case 60:
      return "1 time før";
    case 120:
      return "2 timer før";
    case 300:
      return "5 timer før";
    case 1440:
      return "24 timer før";
    default:
      return "På start tidspunkt";
  }
};

export const reminderOptionsValue = (date: Date, reminderDate: Date) => {
  return Moment(date).diff(Moment(reminderDate), "minutes");
};

export const checkNotificationPermission = () => {
  if (!("Notification" in window)) {
    return "none";
  } else if (Notification.permission === "granted") {
    return "granted";
  } else if (Notification.permission !== "denied") {
    return "default";
  } else {
    return "denied";
  }
};

export const genderOptions = [
  { value: "male", label: <span className="todo-type-select-item">Mand</span> },
  {
    value: "female",
    label: <span className="todo-type-select-item">Kvinde</span>,
  },
  {
    value: "anonymous",
    label: <span className="todo-type-select-item">Anonym</span>,
  },
];

export const genderFormatter = (value: TType) => {
  switch (value) {
    case GENDERTYPES.male:
      return "Mand";
    case GENDERTYPES.female:
      return "Kvinde";
    default:
      return "Anonym";
  }
};
