
/**
 * Validates the input-field and checks if it's not empty
 * @param {*} empty 
 */
export const empty = (empty) => {
    if (empty === '' || empty === null) {
        return false
    } else {
        return true
    }
}

/**
 * Validates the name from a input-field
 * @param {*} name 
 */
export const name = (name) => {
    if (name === '' || name === null || name.length > 20) {
        return false
    } else {
        if (name.match(/^[a-zA-ZÆØÅæøåöÖäÄ ]+$/)) {
            return true
        } else {
            return false
        }
    }
}

/**
 * Validates the email from a input-field
 * @param {*} email 
 */
export const email = (email) => {
    if (email === '' || email === null) {
        return false
    } else {
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            return true
        } else {
            return false
        }
    }
}

/**
 * Validates the CPR from a input-field
 * @param {*} cpr 
 */
export const cpr = (cpr) => {
    if (cpr === '' || cpr === null || cpr.length !== 4) {
        return false
    } else {
        if (cpr.match(/^\d+$/)) {
            return true
        } else {
            return false
        }
    }
}

/**
 * Validates the CVR from a input-field
 * @param {*} cvr 
 */
export const cvr = (cvr) => {
    if (cvr === '' || cvr === null || cvr.length !== 8) {
        return false
    } else {
        if (cvr.match(/^\d+$/)) {
            return true
        } else {
            return false
        }
    }
}

/**
 * Validates the Birth from a input-field
 * @param {*} birth 
 */
export const birth = (birth) => {
    if (birth === '' || birth === null || birth.length !== 6) {
        return false
    } else {
        if (birth.match(/^\d+$/)) {
            return true
        } else {
            return false
        }
    }
}

/**
 * Validates the address from a input-field
 * @param {*} address 
 */
export const address = (address) => {
    if (address === '' || address === null) {
        return false
    } else {
        return true
    }
}

/**
 * Validates the zip-code from a input-field
 * @param {*} zip 
 */
export const zip = (zip) => {
    if (zip === '' || zip === null || zip.length !== 4) {
        return false
    } else {
        if (zip.match(/^\d+$/)) {
            return true
        } else {
            return false
        }
    }
}

/**
 * Validates the phone-number from a input-field
 * @param {*} number 
 */
export const phone = (number) => {
    if (number === '' || number === null || number.length < 5 || number.length > 20) {
        return false;
    } else {
        if (number.match(/^\d+$/)) {
            return true;
        } else {
            return false;
        }
    }
}

/**
 * Validates the password from a input-field
 * @param {*} pass 
 */
export const password = (pass) => {
    if(pass === '' || pass === null || pass.length < 8 || pass.length > 30) {
        return false;
    } else {
        return true;
    }
}

/**
 * Validates if the selected item is valid !== 1st item in list.
 * @param {*} item 
 */
export const select = (item) => {
    if(item === "" || item === null) {
        return false;
    } else {
        return true;
    }
}