import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// Services
import * as InputValidator from '../../../services/InputValidator';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { setCookie } from '../../../services/FunctionHelper.tsx';
import ApiService from '../../../services/ApiService';

// Store
import { setIsLoading } from '../../../store/features/spinner';

// Components
import { NotificationManager } from 'react-notifications';
import InputField from '../../../components/inputField/';
import Button from '../../../components/button/button/';
import StructioLogo from '../../../assets/default_images/structio-logo-with-text-alfa.png';

// Icons

// Style

/** @ViableTypes for props
 ** Example - String: Provides a description to the props it recieve
 */

const Signup = (props) => {
  /* Store */
  const dispatch = useDispatch();
  /* data */
  const { } = props
  /* functions */
  const { redirect } = props
  /* Initialize states */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repasswordError, setRepasswordError] = useState('');


  const onChangeEmail = (value) => {
    setEmail(value);
    document.getElementById("email-input-field").classList.remove("input-error");
    document.getElementById("email-input-error").className = "hidden";
  }

  const onChangePassword = (value) => {
    setPassword(value);
    document.getElementById("password-input-field").classList.remove("input-error");
    document.getElementById("password-input-error").className = "hidden";
  }

  const onChangeRepassword = (value) => {
    setRepassword(value);
    document.getElementById("repassword-input-field").classList.remove("input-error");
    document.getElementById("repassword-input-error").className = "hidden";
  }

  const validateForm = () => {
    let res = true;
    let resEmail = InputValidator.email(email);
    let resPassword = InputValidator.password(password);

    if (!resEmail) {
      setEmailError('Ugyldig email');
      document.getElementById("email-input-field").classList.add("input-error");
      document.getElementById("email-input-error").className = "color--warning font-12 show";
      document.getElementById("email-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    if (!resPassword) {
      setPasswordError('Adgangskode skal indeholde mellem 8 og 30 tegn');
      document.getElementById("password-input-field").classList.add("input-error");
      document.getElementById("password-input-error").className = "color--warning font-12 show";
      document.getElementById("password-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    if (password !== repassword) {
      setRepasswordError('Adgangskoden skal være ens');
      document.getElementById("repassword-input-field").classList.add("input-error");
      document.getElementById("repassword-input-error").className = "color--warning font-12 show";
      document.getElementById("repassword-input-field").scrollIntoView({ behavior: "smooth" });
      res = false;
    }
    return res;
  }

  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      createUser(e);
    }
  }

  const createUser = (event) => {
    if (validateForm()) {
      createAccount(event, email, password);
    }
  }

  const createAccount = (event, email, password) => {
    event.preventDefault();
    dispatch(setIsLoading(true));
    grecaptcha.ready(function () {
      grecaptcha.execute(`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, { action: 'submit' }).then(function (token) {
        const authentication = getAuth();
        createUserWithEmailAndPassword(authentication, email, password).then(async (userCredential) => {
          const user = userCredential.user;
          const authToken = await user.getIdToken(); // Get Firebase Auth Token
          const authUid = user.uid; // Get User UID
          setCookie('auth-uid', authUid, 180); /* Sets to 180 day lifetime because of backend has the same value - Refresh token */
          setCookie('user-email', email, 180); /* Sets to 180 day lifetime because of backend has the same value - Refresh token */
          const config = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
          };
          axios.post(`${process.env.REACT_APP_API_URL}/createuser/${email}`, config)
            .then(async (response) => {
              await ApiService.createJWT(email, authUid, authToken);
              window.location.reload();
            })
            .catch((error) => {
              NotificationManager.warning('Profil kan ikke oprettes. Prøv at genindlæse siden.');
              dispatch(setIsLoading(false));
              console.error(error);
            });
        }).catch((error) => {
          if (error.code === 'auth/invalid-email') {
            setEmailError('Ugyldig email');
            document.getElementById("email-input-field").classList.add("input-error");
            document.getElementById("email-input-error").className = "color--warning font-12 show";
          }
          if (error.code === 'auth/email-already-in-use') {
            setEmailError('Email er allerede i brug');
            document.getElementById("email-input-field").classList.add("input-error");
            document.getElementById("email-input-error").className = "color--warning font-12 show";
          }
          dispatch(setIsLoading(false));
          console.error(error);
        });
      });
    });
  }

  return (
    <div className="login-form">
      <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--b">
        <img src={StructioLogo} alt="Structio Logo" style={{ maxWidth: 220 + 'px' }} />
      </div>
      <div className="flex flex--column flex-align--center flex-wrap--wrap flex-justify--center padding-m--tb">
        <h1 className="color--text no-margin">Opret profil</h1>
      </div>
      <div className="flex flex--column">
        <span className="title-text font-14">Email</span>
        <InputField id="email-input-field" className="font-16 line" type="email" placeholder="Indtast email" maxLength="50" value={email} onChange={(value) => onChangeEmail(value.target.value)} onKeyPress={(e) => handleOnEnter(e)} />
        <span id="email-input-error" className="hidden font-12 color--warning">{emailError}</span>
      </div>
      <div className="flex flex--column margin-m--t">
        <span className="title-text font-14">Adgangskode</span>
        <InputField id="password-input-field" className="font-16 line" type="password" placeholder="Indtast adgangskode" maxLength="50" value={password} onChange={(value) => onChangePassword(value.target.value)} onKeyPress={(e) => handleOnEnter(e)} />
        <span id="password-input-error" className="hidden font-12 color--warning">{passwordError}</span>
      </div>
      <div className="flex flex--column margin-m--t">
        <span className="title-text font-14">Gentag adgangskode</span>
        <InputField id="repassword-input-field" className="font-16 line" type="password" placeholder="gentag adgangskode" maxLength="50" value={repassword} onChange={(value) => onChangeRepassword(value.target.value)} onKeyPress={(e) => handleOnEnter(e)} />
        <span id="repassword-input-error" className="hidden font-12 color--warning">{repasswordError}</span>
      </div>
      <div className="col text-center padding-m--t">
        <Button
          type="add"
          text={'Opret profil'}
          onClick={(e) => createUser(e)}
        />
        <Button
          className="login-btn margin-s--t"
          text={'Log ind'}
          onClick={() => redirect("login")}
        />
      </div>
    </div>
  )
}

export default Signup;
