import React, { useEffect, useState } from 'react';

// Components - Material UI

// Components
import RingLoaderfrom from 'react-spinners/RingLoader';

// Icons

// Style
import './Spinner.scss';

/*
 ** IMPORTANT: Spinner has been made with a global store and ONLY imported in router.jsx.
 ** Means settings isLoading = true from store in whatever file will always activate the Spinner from router.jsx
 ** BUT it's possible to implement a Spinner inside compoenents on a page like Calendar with inline = true (read below)
 ** and by adding a local isLoading state so it doesn't tricker the store Spinner.
 **
 ** delay: number: 300 -> number in milliconds before the Spinner is activating. To Prevent blinks on fast-load.
 ** inline: boolean -> Styles the Spinner to be inside of a Compoenent i.e calendar
 */

const Spinner = (props) => {
  /* Store */

  /* props */
  const { delay = 300, inline = false } = props
  /* Initialize states */
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsLoading(true);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="spinner-container">
      {isLoading && <div className={`spinner spinner-line-${inline}`}>
        <span className="spinner-with-text">Arbejder på sagen..</span>
        <div className="spinner-component">
          <RingLoaderfrom
            size={75}
            color={'#5aa8f0'}
            loading={true}
          />
        </div>
      </div>}
    </div>
  )
}

export default Spinner;