import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import { isEmpty } from "lodash";
import axios from 'axios';

// Utilities
import AuthService from '../../../../services/Auth';
import { reminderOptions, todoTypeOptions } from '../../../../services/FunctionHelper.tsx';
import { dateNowFormatted, dateRes } from '../../../../services/DateHandler';
import cloneDeep from 'lodash/cloneDeep';

// Components - Material UI
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// Components
import { NotificationManager } from 'react-notifications';
import { Add, Check, Clear, DeleteForever, Repeat, LockOutlined, LockOpenOutlined, NotificationsActive } from '@mui/icons-material';
import Select from 'react-select';
import Calendar from 'react-calendar';
import IconButton from '../../../button/iconButton';
import InputField from '../../../inputField/';
import ButtonField from '../../../inputField/buttonField';
import Timepicker from '../../../timepicker/Timepicker';
import Button from '../../../button/button';
import Switch from '../../../button/switchButton/';
import UserItem from '../../../listItem/userItem';
import SubItemCreate from '../../../listItem/subItem/SubItemCreate';
// Store
import { setIsLoading } from '../../../../store/features/spinner';

// Style
import './TodoAdd.scss';

const TodoAdd = (props) => {
    /* Store */
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user);
    const user = userStore.user;
    const friendlistStore = useSelector((state) => state.friendlist);
    const friendlist = friendlistStore.friendlist;
    /* Props */
    const { state, TODO_TYPE, selectedDate } = props; // props
    const { setState: closeModal, stateHandlerSetAllTodos } = props; // func
    /* Initialize states */
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(dateRes(selectedDate));
    const [time, setTime] = useState(new Date().setMinutes(0, 0));
    const [todoType, setTodoType] = useState(TODO_TYPE === "all" ? "todo" : TODO_TYPE); // Preselect todo if type === all
    const [reminderOption, setReminderOption] = useState(15); /* 15 mins default value */
    const [isPrivate, setIsPrivate] = useState(false);
    const [isAutoComplete, setIsAutoComplete] = useState(true);
    const [isRepeatTodo, setIsRepeatTodo] = useState(false);
    const [users, setUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);
    const [subitems, setSubitems] = useState([]);
    const [subitemInput, setSubitemInput] = useState("");
    const [dates, setDates] = useState([]);

    useEffect(() => {
        if (!isEmpty(user)) {
            let userRes = cloneDeep(user);
            userRes.userId = user.id;
            setAddedUsers([...addedUsers, userRes]);
            setUsers([...addedUsers, userRes, ...friendlist]);
        }
    }, [user]);

    const onClickAddTodo = () => {
        dispatch(setIsLoading(true));
        /* Backend needs to recieve date as a list therefor date will be wrapped in a list here */
        const dateRes = isRepeatTodo ? convertDate(dates) : convertDate([date]);
        const dateNowRes = dateNowFormatted();
        const data = {
            'title': title,
            'description': description,
            'date': dateRes,
            'dateNow': dateNowRes,
            'type': todoType,
            'pushReminderDate': reminderOption,
            'isPrivate': 1, //isPrivate disabled until sharing Calendar gets made - every todo will be private until then for privacy
            'isAutoComplete': isAutoComplete,
            'users': addedUsers,
            'subitems': subitems,
        };
        if (addedUsers.length === 0) {
            NotificationManager.warning('Der skal mindst være én tilknyttet opgaven');
            dispatch(setIsLoading(false));
        } else if (dateRes.length === 0) {
            NotificationManager.warning('Du mangler at vælge en dato');
            document.querySelector('.calendar-create-todo').scrollIntoView({ behavior: 'smooth' });
            document.querySelector('.calendar-create-todo').style.boxShadow = "0px 0px 10px 0px var(--color--warning)";
            dispatch(setIsLoading(false));
        } else if (title.length === 0) {
            document.getElementById("todoadd-title").scrollIntoView({ behavior: 'smooth' });
            document.getElementById("title-input-error").classList.remove("hidden");
            dispatch(setIsLoading(false));
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/todoitem/create`, data, AuthService.config())
                .then((response) => {
                    const todoItems = response.data;
                    stateHandlerSetAllTodos(todoItems);
                    closeModal();
                    NotificationManager.success('Opgaven er oprettet', '');
                    dispatch(setIsLoading(false));
                })
                .catch((error) => {
                    NotificationManager.warning('Kunne ikke oprette opgaven', 'Fejl!');
                    console.error(error);
                    dispatch(setIsLoading(false));
                });
        }
    }

    /* Makes it possible to create an subitem by pressing enter */
    const handleOnEnter = (e) => {
        if (e.key === 'Enter') {
            createSubitem();
        }
    }

    const convertDate = (dateList) => {
        const res = [];
        dateList.forEach(date => {
            const timeHour = Moment(time).hours();
            const timeMins = Moment(time).minutes();
            const dateRaw = Moment(date);
            dateRaw.add(timeHour, 'hours');
            dateRaw.add(timeMins, 'minutes');
            const dateRes = dateRaw.format("YYYY-MM-DD HH:mm:00");
            res.push(dateRes);
        })
        return res;
    }

    const createSubitem = () => {
        if (subitemInput) {
            const subitemsList = [...subitems];
            const itemObject = {
                description: subitemInput,
                isComplete: 0,
            }
            subitemsList.push(itemObject);
            setSubitems(subitemsList);
            setSubitemInput("");
        } else {
            document.getElementById("subitem-input-error").classList.remove("hidden");
        }

    }

    const deleteSubitem = (index) => {
        const subitemsList = [...subitems];
        subitemsList.splice(index, 1);
        setSubitems(subitemsList);
    }

    const setTitleInputValue = (value) => {
        setTitle(value);
        document.getElementById("title-input-error").classList.add("hidden");
    }

    const setSubitemInputValue = (value) => {
        setSubitemInput(value);
        document.getElementById("subitem-input-error").classList.add("hidden");
    }

    const tileClassName = (newDate) => {
        if (isRepeatTodo) {
            const date = Moment(newDate).format("YYYY-MM-DD");
            const dateExist = dates.includes(date);
            let className = dateExist ? "repeat-selected" : "repeat-not-selected";
            return className;
        }
    }

    const onClickDay = (newDate) => {
        if (isRepeatTodo) {
            document.querySelector('.calendar-create-todo').style.boxShadow = "none";
            const dateList = cloneDeep(dates);
            const date = Moment(newDate).format("YYYY-MM-DD");
            if (!dateList.includes(date)) {
                dateList.push(date);
            } else {
                dateList.splice(dateList.indexOf(date), 1);
            }
            setDates(dateList);
        }
    }

    let subitemList = [];
    subitems.forEach((item, index) => {
        subitemList.push(<SubItemCreate
            key={`${index}-added-subitem`}
            className="color--text"
            subitem={item}
            deleteSubitem={() => deleteSubitem(index)}
        />)
    });

    //Get todoTypeIndex from OptionsList
    let todoTypeAddIndex = 0;
    todoTypeOptions.map((item, index) => {
        if (todoType === item.value) {
            todoTypeAddIndex = index;
        }
    });

    //Get reminderOptionsIndex from OptionsList
    let reminderOptionsIndex = 0;
    reminderOptions.map((item, index) => {
        if (reminderOption === item.value) {
            reminderOptionsIndex = index;
        }
    });

    /* Returns added users */
    let userListAddSelected = [];
    if (!isEmpty(users)) {
        users.forEach((user, index) => {
            const { firstname, lastname } = user;
            const added = addedUsers.some(addedUser => addedUser.userId === user.userId);
            if (added) {
                const updatedList = addedUsers.filter(addedUser => addedUser.userId !== user.userId);
                const removeUser = (list) => {
                    if (list.length) {
                        setAddedUsers(list)
                    } else {
                        NotificationManager.warning('Minimum 1 person skal være tilknyttet en todo');
                    }
                }
                const componenet = <UserItem
                    key={`${index}-added-user`}
                    name={`${firstname} ${lastname}`}
                    onClickIcon={<IconButton
                        className="color--delete-hover"
                        icon={<DeleteForever />}
                        onClick={() => removeUser(updatedList)}
                    />}
                />
                userListAddSelected.push(componenet);
            } else {
                const componenet = <UserItem
                    key={`${index}-add-user`}
                    inactive={true}
                    name={`${firstname} ${lastname}`}
                    onClickIcon={<IconButton
                        className="color--add-hover"
                        icon={<Add />}
                        onClick={() => setAddedUsers([...addedUsers, user])}
                    />}
                />
                userListAddSelected.push(componenet);
            }
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={'structio-modal-container'}
            open={state}
            onClose={closeModal}
            closeAfterTransition
        >
            <Fade in={state}>
                <div className="structio-modal-box">
                    <button className="icon-btn close-modal-btn" onClick={closeModal}><Clear /></button>
                    <h1 className="modal-title">Opret opgave</h1>
                    <div className="flex flex--column">
                        <span id="todoadd-title" className="title-text font-14">Opgave titel</span>
                        <InputField className="font-16 line" type="text" placeholder="Indtast opgavens titel" maxLength="50" value={title} onChange={(value) => setTitleInputValue(value.target.value)} />
                        <span id="title-input-error" className="hidden font-12 color--warning">&nbsp;(Opgavens titel må ikke være tom)</span>
                        <span className="font-13 color--graa4">Tegn: {title.length} / 50</span>
                    </div>
                    <div className="flex flex--column margin-l--t">
                        <span className="title-text font-14">Opgave beskrivelse</span>
                        <InputField className="font-16 line" type="text" placeholder="Indtast en opgave beskrivelse" maxLength="99" value={description} onChange={(value) => setDescription(value.target.value)} />
                        <span className="font-13 color--graa4">Tegn: {description.length} / 99</span>
                    </div>
                    {/* Disable todoTypes as sorting on todoTypes is now disabled as it has no value for user
                        <span className="title-text margin-l--t font-14">Opgave liste</span>
                        <Select
                            className="select-structio-dropdown line font-16"
                            classNamePrefix="select-structio-dropdown"
                            value={todoTypeOptions[todoTypeAddIndex]}
                            onChange={(target) => setTodoType(target.value)}
                            options={todoTypeOptions}
                            isSearchable={false}
                        />
                    */}
                    <span className="title-text margin-l--t font-14">Afslut opgave automatisk</span>
                    <div className="flex flex--column flex-justify--center line standard-structio-switch">
                        <div className="flex flex-align--center">
                            <span className="flex"><Clear className={isAutoComplete ? "color--graa5" : "color--structio"} /></span>
                            <Switch
                                checked={isAutoComplete}
                                onChange={() => setIsAutoComplete(!isAutoComplete)}
                                value={isAutoComplete}
                                size="switch-large"
                            />
                            <span className="flex"><Check className={isAutoComplete ? "color--structio" : "color--graa5"} /></span>
                        </div>
                    </div>
                    <span className="title-text margin-l--t font-14">Påmindelse</span>
                    <div className="flex flex--column flex-justify--center line standard-structio-switch">
                        <div className="flex flex-align--center">
                            <span className="flex"><NotificationsActive className="color--structio" /></span>
                            <Select
                                className="select-structio-dropdown font-16 full-width padding-s--l"
                                classNamePrefix="select-structio-dropdown"
                                value={reminderOptions[reminderOptionsIndex]}
                                onChange={(target) => setReminderOption(target.value)}
                                options={reminderOptions}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <span className="title-text margin-l--t font-14">Dato</span>
                    <div className="flex flex--column flex-justify--center standard-structio-switch">
                        <div className="repeat-task-calendar-container">
                            <span className="flex color--text font-14"><Repeat className="margin-s--r color--structio" />Gentag opgave andre dage</span>
                            <div style={{ marginRight: "-15px" }}>
                                <Switch
                                    checked={isRepeatTodo}
                                    onChange={() => setIsRepeatTodo(!isRepeatTodo)}
                                    value={isRepeatTodo}
                                    size="switch-large"
                                />
                            </div>
                        </div>
                        <span className={`text-center color--text font-14 padding-xs--b`}>{isRepeatTodo ? "Afkryds de ønskede datoer" : "Vælg dato"}</span>

                    </div>
                    <Calendar
                        className="calendar-create-todo full-width"
                        value={new Date(date)}
                        onChange={(value) => setDate(value)}
                        locale="DA"
                        tileClassName={({ date, view }) => tileClassName(date)}
                        onClickDay={onClickDay}

                    />
                    <span className="title-text margin-l--t font-14">Tidspunkt</span>
                    <Timepicker
                        className="line padding-m--b"
                        startTimeHours={Moment(time).format('HH')}
                        startTimeMinutes={Moment(time).format('mm')}
                        date={new Date(time)}
                        add={true}
                        onChangeDateAdd={(value) => setTime(value)}
                    />
                    {/* isPrivate disabled until sharing Calendar gets made - every todo made will be private until then for privacy
                    <span className="title-text margin-l--t font-14">Privat begivenhed</span>
                    <div className="flex flex--column flex-align--center flex-justify--center line standard-structio-switch">
                        <span className="flex flex-justify--center font-24 color--text">{isPrivate ? "Privat" : "Synlig"}</span>
                        <div className="flex flex-align--center">
                            <span className="flex"><LockOpenOutlined className={isPrivate ? "color--graa5" : "color--structio"} /></span>
                            <Switch
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                                value={isPrivate}
                                size="switch-large"
                            />
                            <span className="flex"><LockOutlined className={isPrivate ? "color--structio" : "color--graa5"} /></span>
                        </div>
                    </div>
                    */}
                    <span className="title-text margin-l--t font-14">Underopgaver</span>
                    <div className="flex flex--column line">
                        <div className="flex flex--column">
                            <ButtonField
                                className="font-14"
                                type="text"
                                placeholder="Indtast beskrivelse"
                                maxLength="50"
                                value={subitemInput}
                                onChange={(value) => setSubitemInputValue(value.target.value)}
                                onKeyPress={(e) => handleOnEnter(e)} icon={<Check />}
                                onClick={() => createSubitem()}
                            />
                            <span id="subitem-input-error" className="hidden font-12 color--warning">&nbsp;(Opgavens titel må ikke være tom)</span>
                            <span className="font-13 color--graa4">Tegn: {subitemInput.length} / 50</span>
                        </div>
                        <div className="flex flex--column margin-m--t">
                            {subitemList}
                        </div>
                    </div>
                    <span className="title-text margin-l--t font-14">Deltagere</span>
                    <div className="flex flex--column line margin-m--t">
                        {userListAddSelected}
                    </div>
                    <div className="flex flex--column flex-justify--center flex-align--center margin-m--t">
                        <Button
                            text={'Opret opgave'}
                            type="add"
                            onClick={() => onClickAddTodo()}
                        />
                        <Button
                            className="margin-m--t"
                            type="cancel"
                            text={'Annuller'}
                            onClick={() => closeModal()}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
export default TodoAdd;
